// --------------------------------------------
// --------------------------------------------
//
// Variables :: Space
//
// --------------------------------------------
// --------------------------------------------


// Uniform :: Space
// --------------------------------------------
$space-eighth:          0.125rem; // 2px
$space-quarter:         0.25rem;  // 4px
$space-half:            0.5rem;   // 8px
$space-one:             1rem;     // 16px
$space-one-and-half:    1.5rem;   // 24px
$space-two:             2rem;     // 32px
$space-three:           3rem;     // 48px
$space-four:            4rem;     // 64px



// Uniform :: Spacer List Maps
// --------------------------------------------
// $uni-space: (
//   eighth: $space-eighth,
//   quarter: $space-quarter,
//   half: $space-half,
//   one: $space-one,
//   one-half: $space-one-half,
//   two: $space-two,
//   three: $space-three,
//   four: $space-four
// )
