// --------------------------------------------
// --------------------------------------------
//
// Elements :: Theme
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */

// Theme Background
// --------------------------------------------
// These are used to control color switching for type (buttons, borders, form elments to come)
// In version 0.11.0 :: Color Refactor & Expansion. These will be deprecated.

// Dark
.t-de--black { background-color: $color-de-bg-level0 !important; }
.t-de--midnight { background-color: $color-de-bg-level1 !important; }
.t-de--ink { background-color: $color-de-bg-level2 !important; }

// Light
.t-le--white { background-color: $color-le-bg-level0 !important; }
.t-le--clean { background-color: $color-le-bg-level1 !important; }
.t-le--bone { background-color: $color-le-bg-level2 !important; }

/* stylelint-enable */






// New Environment Switchers
// --------------------------------------------
// Environment switchers controls the color of elements that reside within that given environment. This changes the behavior of our legacy theming where both the background color and the switching mechanism occurred with the same class.
// There's not style associated with these classes, they act solely as controllers
// These are here purely to note their existence.
// In var.theme.scss is where you'll find the variable used within the system itself.

// Dark
// .#{$css-prefix}env--dark {  }

// Light
// .#{$css-prefix}env--light {  }
