// --------------------------------------------
// --------------------------------------------
//
// Mixins :: Links
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */

// Link
// ---------------------------------------
@mixin link($style: normal) {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
  @if $style == reverse { color: $ui-white; }
}



// Underline Text
// ---------------------------------------
// To use this, pass it a couple different options
// $color: This is the color you want the underline to be
// $type: The type of underline you want, either Solid or Dotted
// $display: If this link is for a larger type, change display to True. This will reduce the size of the underline to make it look at little less bold.

@mixin underline($color: $ui-text-dark, $display: false ) {
  $hover: darken($color, 10%);

  // background-position: 0 0.75em;
  // text-shadow: 0.05em 0 $bg, -0.05em 0 $bg;
  background-image: linear-gradient(90deg, $color 66%, transparent 0);
  background-repeat: repeat-x;
  background-position: 0 1.05em;
  cursor: pointer;

  &:hover { background-image: linear-gradient($color, $color); }

  // @if $type == dotted {
  //   background-image: linear-gradient(90deg, $color 66%, transparent 0);
  //   background-repeat: repeat-x;
  //   // background-size: 0.25em 0.05em;
  // }
  // @else {
  //   background-image: linear-gradient($color, $color);
  //   background-repeat: no-repeat;
  // }

  @if $display == true {
    // background-size: 100% 0.045em;
    background-size: 0.25em 0.045em;
  }
  @else {
    // background-size: 100% 0.08em;
    background-size: 0.25em 0.08em;
  }
}

/* stylelint-enable */
