// --------------------------------------------
// --------------------------------------------
//
// Mixin :: Buttons
//
// --------------------------------------------
// --------------------------------------------


// TO-DO
// 1. Remove commented out size variations code



// Button Base Mixin
// --------------------------------------------
/* stylelint-disable */
@mixin button-base {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: $btn-radius;

  background-color: transparent;
  vertical-align: middle;
  font-weight: $btn-font-weight;
  font-family: inherit;
  text-align: center;
  cursor: pointer;

  text-decoration: none;
  -webkit-appearance: none;

  &:hover,
  &:active,
  &:focus,
  &:visited {
    text-decoration: none;
    outline: 0;
  }

  &:disabled,
  &.#{$css-prefix}btn--is-disabled,
  &.#{$css-prefix}btn--disabled {
    cursor: default;
  }
}
/* stylelint-enable */



// Button Spinner
// --------------------------------------------
/* stylelint-disable */
@mixin button-spinner(
  $size: $btn-spinner-size,
  $color: green,
  $on-bg: false,
  $spin-target: uni-btn__content-container ) {

  .#{$spin-target} {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      @include css-spinner(
        $size: $size,
        $bg-color: $color,
        $on-bg: $on-bg,
        $button-spinner: true
      );
    }
  }

  // Contextual sizing hacks based on the size modifier for a given button
  // This can be cleaned up and reconsidered
  // One option which is equally as unfortunate, is moving this to the button sizing mixin
  // The sizing affects the size of the spinner. I don't know.
  // Get someone who knows how to write CSS on this, pronto.

  .#{$spin-target} {
    // margin-left: buttonSize(medium, padding-left);
    margin-left: 1.438em;

    &::before {
      width: buttonSize(medium, icon-size);
      height: buttonSize(medium, icon-size);
      left: -(buttonSize(medium, icon-size));
      margin-top: -(buttonSize(medium, icon-size) / 2);
      margin-left: -(buttonSize(medium, icon-size) / 4);
    }
  }


  &.#{$css-prefix}btn--small {
    .#{$spin-target} {
      // margin-left: buttonSize(small, padding-left);
      margin-left: 1.14286em;

      &::before {
        width: buttonSize(small, icon-size);
        height: buttonSize(small, icon-size);
        left: -(buttonSize(small, icon-size));
        margin-top: -(buttonSize(small, icon-size) / 2);
        margin-left: -(buttonSize(small, icon-size) / 4);
      }
    }
  }

  &.#{$css-prefix}btn--medium {
    .#{$spin-target} {
      margin-left: 1.438em;

      &::before {
        width: buttonSize(medium, icon-size);
        height: buttonSize(medium, icon-size);
        left: -(buttonSize(medium, icon-size));
        margin-top: -(buttonSize(medium, icon-size) / 2);
        margin-left: -(buttonSize(medium, icon-size) / 4);
      }
    }
  }

  &.#{$css-prefix}btn--large {
    .#{$spin-target} {
      margin-left: 1.445em;

      &::before {
        width: buttonSize(large, icon-size);
        height: buttonSize(large, icon-size);
        left: -(buttonSize(large, icon-size));
        margin-top: -(buttonSize(large, icon-size) / 2);
        margin-left: -(buttonSize(large, icon-size) / 4);
      }
    }
  }

  // Icon Only spinner adjustment
  &.#{$css-prefix}btn--icon-only {
    .#{$spin-target} {
      left: 29%;
    }
  }

} // button-spinner
/* stylelint-enable */



// Button Mark
// --------------------------------------------
/* stylelint-disable */
@mixin button-mark($type: check, $size: $btn-spinner-size, $color: $color-base-white) {
  .#{$css-prefix}btn__content-container {
    position: relative;
    // margin-left: ($size + 0.15em);
    // margin-left: 1.25em;

    &::before,
    &::after {
      position: absolute;
      // top: 0;
      // bottom: 0;
      top: 1px;
      bottom: 1px;
      left: 0;
      content: '';
      // width: 0.275em;
      // width: 0.5em;
      // background-color: $color;
      // width: 0.225em;
      // height: 98%;
      // background-color: $color;
      // left: -($size);
      border: solid $color;
      border-width: 0 0 0 0;
    }

    @if $type == 'check' {
      &::before {
        width: 0.55em;
        // border: solid $color;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg);
        // background-color: transparent;
        // height: 45%;
        // bottom: 0.0525em;
        // left: -($size + 0.20em);
      }
      &:after { position: static; }
    } @elseif $type == '+' {
        &::before,
        &::after { background-color: $color; }
        &::before { transform: rotate(90deg); }
        &::after { }
      } @elseif $type == '-' {
          &::before,
          &::after { background-color: $color; }
          &::before { transform: rotate(90deg); }
          &::after { background-color: transparent; }
        } @elseif $type == 'x' {
            // &:before, &:after { background-color: $color; }
            &::before { transform: rotate(45deg); border-width: 0 0.275em 0 0; }
            &::after { transform: rotate(-45deg); border-width: 0 0.275em 0 0; }
          }
  }


  // Contextual sizing hacks based on the size modifier for a given button
  // These are copied from the same hacks utilized in the button-spinner mixin
  // Eventually, these should be untangled and made consistent, or at least reside in a consistent spot, such as within the button-size mixin.
  .#{$css-prefix}btn__content-container {
    margin-left: 1.438em;
    &::before, &::after { left: -(buttonSize(medium, icon-size) * 0.88); }
  }

  &.#{$css-prefix}btn--small {
    .#{$css-prefix}btn__content-container {
      &::before, &::after { left: -(buttonSize(small, icon-size) * 0.88); }
    }
  }

  &.#{$css-prefix}btn--medium {
    .#{$css-prefix}btn__content-container {
      &::before, &::after { left: -(buttonSize(medium, icon-size) * 0.88); }
    }
  }

  &.#{$css-prefix}btn--large {
    .#{$css-prefix}btn__content-container {
      &::before, &::after { left: -(buttonSize(large, icon-size) * 0.88); }
    }
  }

  // Icon Only spinner adjustment
  // Dang.
  &.#{$css-prefix}btn--icon-only {
    .#{$css-prefix}btn__content-container {
      left: 25%;
    }
  }

  &.#{$css-prefix}btn--icon-only.#{$css-prefix}btn--small {
    .#{$css-prefix}btn__content-container {
      left: 0;
    }
  }

   &.#{$css-prefix}btn--icon-only.#{$css-prefix}btn--large {
    .#{$css-prefix}btn__content-container {
      left: 33%;
    }
  }


}
/* stylelint-enable */



// Button Disabled
// --------------------------------------------
// Setting disabled state
/* stylelint-disable */
@mixin button-disabled( $style: regular ) {

  // Local variables
  $variant: disabled;
  $color: button($variant, bg);
  $color-de: button($variant, bg-de);
  $btn-type-color: button($variant, type-color);
  $btn-type-color-de: button($variant, type-color-de);
  $opacity: button($variant, opacity);

  $minimal: button($variant, minimal);
  $minimal-de: button($variant, minimal-de);
  $minimal-opacity: button($variant, minimal-opacity);

  // If it is a minimal button
  @if $style == 'minimal' {
    &:disabled,
    &.#{$css-prefix}btn--is-disabled,
    &.#{$css-prefix}btn--disabled {
      @include theme-color($minimal, $minimal-de, false);
      opacity: $minimal-opacity;

      &:hover {
        @include theme-color($minimal, $minimal-de, false);
        text-decoration: none;
      }
    }
  }

  // If it is a regular button
  @else {
    &:disabled,
    &.#{$css-prefix}btn--is-disabled,
    &.#{$css-prefix}btn--disabled {
      @include theme-bg($color, $color-de, false);
      @include theme-color($btn-type-color, $btn-type-color-de, false);
      opacity: 0.2;
      svg { fill: currentColor; }

      &:hover {
        @include theme-bg($color, $color-de, false);
        @include theme-color($btn-type-color, $btn-type-color-de, false);
        opacity: 0.2;
      }

      &:focus {
        @include theme-bg($color, $color-de, false);
      }

    }
  }

}
/* stylelint-enable */



// Button Size
// --------------------------------------------
// To define different sizes of buttons.
// Configuration of button sizes is controlled by $uni-btn-size map located in the var.button.scss file
/* stylelint-disable */
@mixin button-size( $size: medium ) {
  height:         buttonSize($size, line-height) + em;
  line-height:    buttonSize($size, line-height);
  padding-top:    buttonSize($size, padding-top);
  padding-right:  buttonSize($size, padding-right);
  padding-bottom: buttonSize($size, padding-bottom);
  padding-left:   buttonSize($size, padding-left);
  font-size:      buttonSize($size, font-size);
  font-weight:    buttonSize($size, font-weight);

  // Controlling sizing of icons inside buttons
  .#{$css-prefix}btn__icon {
    width: buttonSize($size, icon-size);
    height: buttonSize($size, icon-size);
    margin-right: buttonSize($size, icon-margin-right);
    margin-left: -(buttonSize($size, icon-margin-left));
  }
} // button-size
/* stylelint-enable */



// Button Rounded
// --------------------------------------------
// Removed




// Button Outline
// --------------------------------------------
// Removed




// Note about button-minimal and button mixins
// Overall, the duplication necessary between having separate mixins for regular Buttons and minimal Buttons seems to make sense. The reduction of duplicated logic doesn't seem worth the additional complexity that would need to be added into the master Button mixin. At some point, it might be worth considering. However, at that point, the button minimal mixin probably would need to remain, as an interface into the new master mixin, at least for some period of time.




// Button Minimal
// --------------------------------------------
/* stylelint-disable */
@mixin button-minimal ( $variant: subtle, $bespoke: null ) {

  // Settings
  // ----------------
  // Local Variables, set via maps
  $color: if(
    button($variant, minimal),
    button($variant, minimal),
    button($variant, bg)
  );

  $btn-hover: if(
    button($variant, minimal-hover),
    button($variant, minimal-hover),
    button($variant, bg-hover)
  );

  $btn-active: if(
    button($variant, minimal-active),
    button($variant, minimal-active),
    button($variant, bg-active)
  );




  // Additional Fallbacks - Light environment
  // ----------------

  // 1. If there's not bg-hover specified in map, programmatically set the hover state
  @if $btn-hover == null { // 1
    $btn-hover: buttonHover($color);
  }

   // 2. If there's not bg-active specified in map, programmatically set the active state
  @if $btn-active == null { // 2
    $btn-active: buttonActive($color);
  }



  // Settings Dark environment
  // ----------------
  $color-de: if(
    button($variant, minimal-de),
    button($variant, minimal-de),
    $color
  );

  $btn-hover-de: if(
    button($variant, minimal-hover-de),
    button($variant, minimal-hover-de),
    $btn-hover
  );

  $btn-active-de: if(
    button($variant, minimal-active-de),
    button($variant, minimal-active-de),
    $btn-active
  );



  // Style
  // ----------------
  @include button-base;

  // This conditional includes the button size if the button is not a local, bespoke button,
  // created outside the design system. 99.9% of buttons will be uniform buttons,
  // The check helps to ensure our users can create their own buttons. However, they'll additionally need to add a size modifier class within their markup
  @if $bespoke == null {
    @include button-size;
  }
  @include button-disabled(minimal);
  // color: $color;
  @include theme-color($color, $color-de, false);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: $trans-color;

  // Hacking size
  // This non-elegant hack gives a consistent padding for minimal buttons without resorting to !important to fight against size modifiers.
  &.#{$css-prefix}btn--small,
  &.#{$css-prefix}btn--medium,
  &.#{$css-prefix}btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em;
  }

  // Hover + Focus
  // ----------------
  &:hover,
  &:focus {
    @include theme-color($btn-hover, $btn-hover-de, false);
  }



  // Active
  // ----------------
  &:active {
    @include theme-color($btn-active, $btn-active-de, false);
  }



  // Spinning State
  // ----------------
  &.#{$css-prefix}btn--is-spinning {
    @include button-spinner($size: $btn-spinner-size, $color: $color, $on-bg: false);

    @include theme-color($btn-active, $btn-active-de, false);
    &:hover {
      @include theme-color($btn-active, $btn-active-de, false);
    }
  }


  // Disabled
  // ----------------
  // &:disabled,
  // &.#{$css-prefix}btn--is-disabled,
  // &.#{$css-prefix}btn--disabled {
  //   @include theme-color($btn-text-disabled, $btn-text-disabled-de, false);
  //   opacity: 0.1;

  //   &:hover { text-decoration: none; }
  // }



  // Success state
  // ----------------
  &.#{$css-prefix}btn--is-success {
    @include button-mark('check', $color: $btn-bg-confirm);
    background-color: transparent !important;
    @include theme-color($btn-bg-confirm, $btn-bg-confirm, false);
    &:hover {
      background-color: transparent !important;
      @include theme-color($btn-bg-confirm, $btn-bg-confirm, false);
    }
  }



  // Failure state
  // ----------------
  &.#{$css-prefix}btn--is-failure {
    @include button-mark('x', $color: $btn-bg-destroy);
    background-color: transparent !important;
    @include theme-color($btn-bg-destroy, $btn-bg-destroy, false);
    &:hover {
      @include theme-color($btn-bg-destroy, $btn-bg-destroy, false);
      background-color: transparent !important;
    }
  }


} // button-minimal
/* stylelint-enable */








// Button
// --------------------------------------------
/* stylelint-disable */
@mixin button( $variant: subtle, $bespoke: null ) {

  // Settings
  // ----------------
  // Variables, set via maps from var.buttons.scss file.
  // This first checks if the value exists in the map for a given variant.
  // If that value exists, the variable is set to that value.
  // If it does not exist, an alternate value is set
  // That alternate value will either be set with a button function, or set to a previously declared variable.
  $color: button($variant, bg);

  $btn-hover: if(
    button($variant, bg-hover),
    button($variant, bg-hover),
    buttonHover($color)
  );

  $btn-active: if(
    button($variant, bg-active),
    button($variant, bg-active),
    buttonActive($color)
  );

  $btn-text: if(
    button($variant, color),
    button($variant, color),
    contrast-color($color)
  );

  $color-de: if(
    button($variant, bg-de),
    button($variant, bg-de),
    $color
  );

  $btn-text-de: if(
    button($variant, color-de),
    button($variant, color-de),
    $btn-text
  );

  $btn-hover-de: if(
    button($variant, bg-hover-de),
    button($variant, bg-hover-de),
    $btn-hover
  );

  $btn-active-de: if(
    button($variant, bg-active-de),
    button($variant, bg-active-de),
    $btn-active
  );


  // Style
  // ----------------
  @include button-base;
  // This conditional includes the button size if the button is not a local, bespoke button,
  // created outside the design system. 99.9% of buttons will be uniform buttons,
  // The check helps to ensure our users can create their own buttons. However, they'll additionally need to add a size modifier class within their markup
  @if $bespoke == null {
    @include button-size;
  }
  @include button-disabled;
  @include theme-bg($color, $color-de, false);
  @include theme-color($btn-text, $btn-text-de, false);
  transition: $btn-trans-bg-color;

  // Hover + Focus
  // ----------------
  &:hover,
  &:focus {
    @include theme-bg($btn-hover, $btn-hover-de, false);
    // outline: none;
  }

  // Active
  // ----------------
  &:active {
    @include theme-bg($btn-active, $btn-active-de, false);
  }

  // Spinning State
  // ----------------
  &.#{$css-prefix}btn--is-spinning {
    @include button-spinner($size: $btn-spinner-size, $color: $color, $on-bg: true);
    @include theme-bg($btn-active, $btn-active-de, false);

    &:hover {
      @include theme-bg($btn-active, $btn-active-de, false);
    }
  }

  // This ensures a smooth transition as state is added to a button
  > .#{$css-prefix}btn__content-container {
    transition: margin 0.2s linear;
  }

} // button
/* stylelint-enable */
