// --------------------------------------------
// --------------------------------------------
//
// Variables :: Tables
//
// --------------------------------------------
// --------------------------------------------

// TO-DO
// 1. Remove hard coded values.


// Settings
// --------------------------------------------
$table-font-size:         0.875rem;
$table-font-size-th:      0.75rem;

$table-cell-padding:      0.625rem 1rem;

$table-bg-striped:        rgba(0, 0, 0, 0.017);
$table-bg-vert:           rgba(170, 170, 170, 0.12);

$table-bg-pinned:         $color-base-white;

$table-bg-hover:          $color-le-bg-level1;
$table-bg-hover-darker:   darken($color-le-bg-level1, 4%);

$table-bdr:               1px solid $color-le-bg-level1-accent;
$table-bdr-dark:          1px solid darken($color-le-bg-level1-accent, 5%);
$table-bdr-thick:         2px solid $color-brand-orange;

$table-trans-bg:          background-color 0.6s ease;

$bp-table-rwd:            960px;
