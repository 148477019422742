/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* stylelint-enable */
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@-o-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

figcaption,
figure,
main {
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b, strong {
  font-weight: inherit; }

b, strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  font-family: helvetica, arial, sans-serif;
  font-size: 100%;
  line-height: 1;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  display: inline-block;
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

html {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent; }

body {
  background-color: white;
  font-family: helvetica, arial, sans-serif !important;
  font-size: 1rem;
  line-height: 1;
  color: #13293f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

pre {
  overflow: auto; }

ul,
ol,
dl {
  margin: 0;
  padding: 0; }

li {
  list-style-position: inside; }

dd {
  margin: 0; }

p {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: helvetica, arial, sans-serif;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

img {
  max-width: 100%; }

figure {
  margin: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

#main {
  flex: 1; }

/* stylelint-disable */
.t-de--black {
  background-color: #101417 !important; }

.t-de--midnight {
  background-color: #191f24 !important; }

.t-de--ink {
  background-color: #232a31 !important; }

.t-le--white {
  background-color: white !important; }

.t-le--clean {
  background-color: #f9fafb !important; }

.t-le--bone {
  background-color: #f2f5f7 !important; }

/* stylelint-enable */
.uni-bg--level0 {
  background-color: white; }
  [class*='t-de'] .uni-bg--level0 {
    background-color: #101417; }
  [class*='t-le'] .uni-bg--level0 {
    background-color: white; }
  .uni-env--dark .uni-bg--level0 {
    background-color: #101417; }
  .uni-env--light .uni-bg--level0 {
    background-color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level0 {
    background-color: #101417; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level0 {
    background-color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level0 {
    background-color: #101417; }

.uni-bg--level0--accent {
  background-color: #edf0f2; }
  [class*='t-de'] .uni-bg--level0--accent {
    background-color: #232a31; }
  [class*='t-le'] .uni-bg--level0--accent {
    background-color: #edf0f2; }
  .uni-env--dark .uni-bg--level0--accent {
    background-color: #232a31; }
  .uni-env--light .uni-bg--level0--accent {
    background-color: #edf0f2; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level0--accent {
    background-color: #232a31; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level0--accent {
    background-color: #edf0f2; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level0--accent {
    background-color: #232a31; }

.uni-bg--level1 {
  background-color: #f9fafb; }
  [class*='t-de'] .uni-bg--level1 {
    background-color: #191f24; }
  [class*='t-le'] .uni-bg--level1 {
    background-color: #f9fafb; }
  .uni-env--dark .uni-bg--level1 {
    background-color: #191f24; }
  .uni-env--light .uni-bg--level1 {
    background-color: #f9fafb; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level1 {
    background-color: #191f24; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level1 {
    background-color: #f9fafb; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level1 {
    background-color: #191f24; }

.uni-bg--level1--accent {
  background-color: #e4e7eb; }
  [class*='t-de'] .uni-bg--level1--accent {
    background-color: #2c353e; }
  [class*='t-le'] .uni-bg--level1--accent {
    background-color: #e4e7eb; }
  .uni-env--dark .uni-bg--level1--accent {
    background-color: #2c353e; }
  .uni-env--light .uni-bg--level1--accent {
    background-color: #e4e7eb; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level1--accent {
    background-color: #2c353e; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level1--accent {
    background-color: #e4e7eb; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level1--accent {
    background-color: #2c353e; }

.uni-bg--level2 {
  background-color: #f2f5f7; }
  [class*='t-de'] .uni-bg--level2 {
    background-color: #232a31; }
  [class*='t-le'] .uni-bg--level2 {
    background-color: #f2f5f7; }
  .uni-env--dark .uni-bg--level2 {
    background-color: #232a31; }
  .uni-env--light .uni-bg--level2 {
    background-color: #f2f5f7; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level2 {
    background-color: #232a31; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level2 {
    background-color: #f2f5f7; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level2 {
    background-color: #232a31; }

.uni-bg--level2--accent {
  background-color: #dce1e6; }
  [class*='t-de'] .uni-bg--level2--accent {
    background-color: #313b45; }
  [class*='t-le'] .uni-bg--level2--accent {
    background-color: #dce1e6; }
  .uni-env--dark .uni-bg--level2--accent {
    background-color: #313b45; }
  .uni-env--light .uni-bg--level2--accent {
    background-color: #dce1e6; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level2--accent {
    background-color: #313b45; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level2--accent {
    background-color: #dce1e6; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level2--accent {
    background-color: #313b45; }

.uni-bg--level3 {
  background-color: #ebeff2; }
  [class*='t-de'] .uni-bg--level3 {
    background-color: #353f4a; }
  [class*='t-le'] .uni-bg--level3 {
    background-color: #ebeff2; }
  .uni-env--dark .uni-bg--level3 {
    background-color: #353f4a; }
  .uni-env--light .uni-bg--level3 {
    background-color: #ebeff2; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level3 {
    background-color: #353f4a; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level3 {
    background-color: #ebeff2; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level3 {
    background-color: #353f4a; }

.uni-bg--level3--accent {
  background-color: color-le-bg-level3-accent; }
  [class*='t-de'] .uni-bg--level3--accent {
    background-color: #45525e; }
  [class*='t-le'] .uni-bg--level3--accent {
    background-color: color-le-bg-level3-accent; }
  .uni-env--dark .uni-bg--level3--accent {
    background-color: #45525e; }
  .uni-env--light .uni-bg--level3--accent {
    background-color: color-le-bg-level3-accent; }
  [class*='uni-env--'] .uni-env--dark .uni-bg--level3--accent {
    background-color: #45525e; }
  [class*='uni-env--'] .uni-env--light .uni-bg--level3--accent {
    background-color: color-le-bg-level3-accent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-bg--level3--accent {
    background-color: #45525e; }

.uni-icon__container {
  display: inline-block;
  border-radius: 0.25rem;
  line-height: 0; }

.uni-icon {
  color: rgba(19, 41, 63, 0.8);
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  fill-rule: evenodd; }
  [class*='t-de'] .uni-icon {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-icon {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-icon {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-icon {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-icon {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-icon {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-icon {
    color: rgba(230, 242, 255, 0.8); }

.uni-icon--nonzero {
  fill-rule: nonzero; }

.uni-icon--reverse {
  fill: white; }

.uni-icon--active {
  fill: #ff6300; }

.uni-icon--lt {
  fill: rgba(19, 41, 63, 0.65); }

.uni-icon__container--primary {
  background-color: #009ce3; }

.uni-icon__container--danger {
  background-color: #f2b600; }

.uni-icon__container--success {
  background-color: #78a100; }

.uni-icon--small {
  width: 1rem;
  height: 1rem; }

.uni-icon--medium {
  width: 1.5rem;
  height: 1.5rem; }

.uni-icon--large {
  width: 2rem;
  height: 2rem; }

.uni-progress__meter, .uni-progress__meter--primary, .uni-progress__meter--secondary, .uni-progress__meter--tertiary, .uni-progress__meter--danger, .uni-progress__meter--confirm {
  display: block;
  float: left;
  height: 100%;
  background-color: #ff6300;
  transition: width 0.3s ease-in-out;
  position: relative;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

@keyframes stripes {
  0 {
    background-position: -40px 0; }
  100% {
    background-position: 40px 0; } }

.uni-progress__container {
  overflow: hidden;
  width: 100%;
  border: 1px solid #f0f2f5;
  border-radius: 0.25rem;
  background-color: #f9fafb;
  box-shadow: inset 0 0.05rem 0.05rem rgba(0, 0, 0, 0.1);
  height: 1.5rem; }
  .uni-progress__container:after {
    content: "";
    display: table;
    clear: both; }

.uni-progress__meter {
  background-color: #e4e7eb; }

.uni-progress__label {
  margin-bottom: 0.25rem;
  color: #13293f;
  font-size: 1rem; }

.uni-progress__percent {
  color: white;
  font-size: 0.75rem;
  line-height: 1.5rem; }

.uni-progress__meter--primary {
  background-color: #ff6300; }

.uni-progress__meter--secondary {
  background-color: #009ce3; }

.uni-progress__meter--tertiary {
  background-color: #ff6300; }

.uni-progress__meter--danger {
  background-color: #e81c00; }

.uni-progress__meter--confirm {
  background-color: #78a100; }

.uni-progress__meter--striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.1) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 75%, transparent 75%, transparent);
  background-size: 40px 40px; }

.uni-progress__meter--is-active {
  animation: stripes 2s linear infinite; }

.uni-modal__overlay {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  visibility: hidden;
  background: #232a31;
  opacity: 0;
  text-align: center;
  transition: visibility 0.3s linear, opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1); }
  .uni-modal--is-visible .uni-modal__overlay {
    visibility: visible;
    opacity: 0.98; }

.uni-modal__container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  visibility: hidden;
  background-color: transparent;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease; }
  .uni-modal--is-visible .uni-modal__container {
    overflow: scroll;
    visibility: visible;
    opacity: 1; }

.uni-modal {
  position: relative;
  z-index: 200;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 1px;
  background-color: white;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
  text-align: left;
  transform: translateY(50%);
  opacity: 1;
  transition: all 0.3s ease; }
  .uni-modal--is-visible .uni-modal {
    transform: translateY(0); }

.uni-modal__title-bar {
  background-color: #313b45;
  background-image: linear-gradient(#313b45 35%, rgba(7, 8, 9, 0.1));
  position: relative;
  padding: 0.75rem 1rem; }

.uni-modal__title {
  margin: 0;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase; }

.uni-modal__meta {
  padding: 1rem 1rem 0; }

.uni-modal__content {
  padding: 0 1rem; }

.uni-modal__action {
  padding: 0.75rem 1rem;
  background-color: #f9fafb; }
  .uni-modal__action:after {
    content: "";
    display: table;
    clear: both; }

.uni-modal__h {
  margin: 0;
  color: #13293f;
  font-size: 2rem; }

.uni-modal__h--sub {
  margin: 0;
  color: rgba(19, 41, 63, 0.8);
  font-size: 1rem; }

.uni-modal__text {
  margin: 1rem 0;
  color: rgba(19, 41, 63, 0.8);
  font-size: 1rem; }

.uni-headline--1 {
  font-family: helvetica, arial, sans-serif;
  font-weight: 300;
  letter-spacing: -0.025em;
  font-size: 2.5rem;
  transition: font-size 0.2s linear;
  color: rgba(19, 41, 63, 0.65);
  line-height: 1; }
  @media (max-width: 37.4375rem) {
    .uni-headline--1 {
      font-size: 1.875rem; } }
  [class*='t-de'] .uni-headline--1 {
    color: rgba(230, 242, 255, 0.65); }
  [class*='t-le'] .uni-headline--1 {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-headline--1 {
    color: rgba(230, 242, 255, 0.65); }
  .uni-env--light .uni-headline--1 {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-headline--1 {
    color: rgba(230, 242, 255, 0.65); }
  [class*='uni-env--'] .uni-env--light .uni-headline--1 {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-headline--1 {
    color: rgba(230, 242, 255, 0.65); }
  @media (max-width: 37.4375rem) {
    .uni-headline--1 {
      line-height: 1.06667; } }

.uni-headline--2 {
  font-family: helvetica, arial, sans-serif;
  font-weight: 300;
  letter-spacing: -0.025em;
  font-size: 1.875rem;
  transition: font-size 0.2s linear;
  color: rgba(19, 41, 63, 0.65);
  line-height: 1.06667; }
  @media (max-width: 37.4375rem) {
    .uni-headline--2 {
      font-size: 1.375rem; } }
  [class*='t-de'] .uni-headline--2 {
    color: rgba(230, 242, 255, 0.65); }
  [class*='t-le'] .uni-headline--2 {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-headline--2 {
    color: rgba(230, 242, 255, 0.65); }
  .uni-env--light .uni-headline--2 {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-headline--2 {
    color: rgba(230, 242, 255, 0.65); }
  [class*='uni-env--'] .uni-env--light .uni-headline--2 {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-headline--2 {
    color: rgba(230, 242, 255, 0.65); }
  @media (max-width: 37.4375rem) {
    .uni-headline--2 {
      line-height: 1.09091; } }

.uni-headline--3 {
  font-family: helvetica, arial, sans-serif;
  font-weight: 300;
  letter-spacing: -0.015em;
  font-size: 1.375rem;
  transition: font-size 0.2s linear;
  color: #13293f;
  line-height: 1.18182; }
  @media (max-width: 37.4375rem) {
    .uni-headline--3 {
      font-size: 1.125rem; } }
  [class*='t-de'] .uni-headline--3 {
    color: #e6f2ff; }
  [class*='t-le'] .uni-headline--3 {
    color: #13293f; }
  .uni-env--dark .uni-headline--3 {
    color: #e6f2ff; }
  .uni-env--light .uni-headline--3 {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-headline--3 {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-headline--3 {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-headline--3 {
    color: #e6f2ff; }
  @media (max-width: 37.4375rem) {
    .uni-headline--3 {
      line-height: 1.16667; } }

.uni-subhead {
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #13293f;
  line-height: 1; }
  [class*='t-de'] .uni-subhead {
    color: #e6f2ff; }
  [class*='t-le'] .uni-subhead {
    color: #13293f; }
  .uni-env--dark .uni-subhead {
    color: #e6f2ff; }
  .uni-env--light .uni-subhead {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-subhead {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-subhead {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-subhead {
    color: #e6f2ff; }

.uni-subhead--small {
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  color: #13293f;
  line-height: 1; }
  [class*='t-de'] .uni-subhead--small {
    color: #e6f2ff; }
  [class*='t-le'] .uni-subhead--small {
    color: #13293f; }
  .uni-env--dark .uni-subhead--small {
    color: #e6f2ff; }
  .uni-env--light .uni-subhead--small {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-subhead--small {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-subhead--small {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-subhead--small {
    color: #e6f2ff; }
  .uni-subhead--small.uni-subhead--subtle {
    color: rgba(19, 41, 63, 0.65); }
    [class*='t-de'] .uni-subhead--small.uni-subhead--subtle {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-subhead--small.uni-subhead--subtle {
      color: rgba(19, 41, 63, 0.65); }
    .uni-env--dark .uni-subhead--small.uni-subhead--subtle {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-subhead--small.uni-subhead--subtle {
      color: rgba(19, 41, 63, 0.65); }
    [class*='uni-env--'] .uni-env--dark .uni-subhead--small.uni-subhead--subtle {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-subhead--small.uni-subhead--subtle {
      color: rgba(19, 41, 63, 0.65); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-subhead--small.uni-subhead--subtle {
      color: rgba(230, 242, 255, 0.65); }

.uni-text--large {
  font-family: helvetica, arial, sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  color: rgba(19, 41, 63, 0.8);
  line-height: 1.44444; }
  [class*='t-de'] .uni-text--large {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-text--large {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-text--large {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-text--large {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-text--large {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-text--large {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-text--large {
    color: rgba(230, 242, 255, 0.8); }

.uni-text {
  font-family: helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(19, 41, 63, 0.8);
  line-height: 1.5; }
  [class*='t-de'] .uni-text {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-text {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-text {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-text {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-text {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-text {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-text {
    color: rgba(230, 242, 255, 0.8); }
  .uni-text.uni-text--set-solid {
    line-height: 1.125; }

.uni-text--small {
  font-family: helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(19, 41, 63, 0.8);
  line-height: 1.42857; }
  [class*='t-de'] .uni-text--small {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-text--small {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-text--small {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-text--small {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-text--small {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-text--small {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-text--small {
    color: rgba(230, 242, 255, 0.8); }
  .uni-text--small.uni-text--set-solid {
    line-height: 1.071428571; }

.uni-text--micro {
  font-family: helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: rgba(19, 41, 63, 0.8);
  line-height: 1.41667; }
  [class*='t-de'] .uni-text--micro {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-text--micro {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-text--micro {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-text--micro {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-text--micro {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-text--micro {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-text--micro {
    color: rgba(230, 242, 255, 0.8); }
  .uni-text--micro.uni-text--set-solid {
    line-height: 1.083333333; }

.uni-text--additional {
  font-family: helvetica, arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: rgba(19, 41, 63, 0.4);
  line-height: 1.5; }
  [class*='t-de'] .uni-text--additional {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-text--additional {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-text--additional {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-text--additional {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-text--additional {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-text--additional {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-text--additional {
    color: rgba(230, 242, 255, 0.4); }

.uni-item-title {
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  color: rgba(19, 41, 63, 0.4);
  line-height: 1; }
  [class*='t-de'] .uni-item-title {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-item-title {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-item-title {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-item-title {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-item-title {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-item-title {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-item-title {
    color: rgba(230, 242, 255, 0.4); }
  .uni-item-title.uni-item-title--caps {
    text-transform: uppercase; }

.uni-item-title--small {
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 0.625rem;
  color: rgba(19, 41, 63, 0.4);
  line-height: 1; }
  [class*='t-de'] .uni-item-title--small {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-item-title--small {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-item-title--small {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-item-title--small {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-item-title--small {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-item-title--small {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-item-title--small {
    color: rgba(230, 242, 255, 0.4); }
  .uni-item-title--small.uni-item-title--caps {
    text-transform: uppercase; }

.uni-article > p {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

small {
  font-size: 0.75rem; }

mark {
  background-color: #ff6300;
  color: white; }

strike {
  text-decoration: line-through;
  color: #e81c00; }

.u-text-alignment--left {
  text-align: left; }

.u-text-alignment--center {
  text-align: center; }

.u-text-alignment--right {
  text-align: right; }

.u-text--bold {
  font-weight: bold; }

.u-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.uni-btn {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(157, 166, 174, 0.2);
  color: rgba(19, 41, 63, 0.65);
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn:hover, .uni-btn:active, .uni-btn:focus, .uni-btn:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn:disabled, .uni-btn.uni-btn--is-disabled, .uni-btn.uni-btn--disabled {
    cursor: default; }
  .uni-btn .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn:disabled, .uni-btn.uni-btn--is-disabled, .uni-btn.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn:disabled, [class*='t-de'] .uni-btn.uni-btn--is-disabled, [class*='t-de'] .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn:disabled, [class*='t-le'] .uni-btn.uni-btn--is-disabled, [class*='t-le'] .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn:disabled, .uni-env--dark .uni-btn.uni-btn--is-disabled, .uni-env--dark .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn:disabled, .uni-env--light .uni-btn.uni-btn--is-disabled, .uni-env--light .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn:disabled, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn:disabled, [class*='t-de'] .uni-btn.uni-btn--is-disabled, [class*='t-de'] .uni-btn.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn:disabled, [class*='t-le'] .uni-btn.uni-btn--is-disabled, [class*='t-le'] .uni-btn.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn:disabled, .uni-env--dark .uni-btn.uni-btn--is-disabled, .uni-env--dark .uni-btn.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn:disabled, .uni-env--light .uni-btn.uni-btn--is-disabled, .uni-env--light .uni-btn.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn:disabled, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled {
      color: #232a31; }
    .uni-btn:disabled svg, .uni-btn.uni-btn--is-disabled svg, .uni-btn.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn:disabled:hover, .uni-btn.uni-btn--is-disabled:hover, .uni-btn.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn:disabled:hover, [class*='t-de'] .uni-btn.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn:disabled:hover, [class*='t-le'] .uni-btn.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn:disabled:hover, .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn:disabled:hover, .uni-env--light .uni-btn.uni-btn--is-disabled:hover, .uni-env--light .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn:disabled:hover, [class*='t-de'] .uni-btn.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn:disabled:hover, [class*='t-le'] .uni-btn.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn:disabled:hover, .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn:disabled:hover, .uni-env--light .uni-btn.uni-btn--is-disabled:hover, .uni-env--light .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn:disabled:focus, .uni-btn.uni-btn--is-disabled:focus, .uni-btn.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn:disabled:focus, [class*='t-de'] .uni-btn.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn:disabled:focus, [class*='t-le'] .uni-btn.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn:disabled:focus, .uni-env--dark .uni-btn.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn:disabled:focus, .uni-env--light .uni-btn.uni-btn--is-disabled:focus, .uni-env--light .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-le'] .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--dark .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--light .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--dark .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-de'] .uni-btn {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-btn {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-btn {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn {
    color: rgba(230, 242, 255, 0.8); }
  .uni-btn:hover, .uni-btn:focus {
    background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-de'] .uni-btn:hover, [class*='t-de'] .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-le'] .uni-btn:hover, [class*='t-le'] .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--dark .uni-btn:hover, .uni-env--dark .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--light .uni-btn:hover, .uni-env--light .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn:hover, [class*='uni-env--'] .uni-env--dark .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn:hover, [class*='uni-env--'] .uni-env--light .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:focus {
      background-color: rgba(193, 198, 203, 0.2); }
  .uni-btn:active {
    background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-de'] .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn:active {
      background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn.uni-btn--is-spinning {
    background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid black;
        border-right: 0.225em solid rgba(0, 0, 0, 0.3);
        border-bottom: 0.225em solid rgba(0, 0, 0, 0.3);
        border-left: 0.225em solid rgba(0, 0, 0, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn.uni-btn--is-spinning:hover {
      background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-de'] .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-le'] .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--dark .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--light .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--light .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--primary {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: #009ce3;
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--primary:hover, .uni-btn--primary:active, .uni-btn--primary:focus, .uni-btn--primary:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--primary:disabled, .uni-btn--primary.uni-btn--is-disabled, .uni-btn--primary.uni-btn--disabled {
    cursor: default; }
  .uni-btn--primary .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--primary:disabled, .uni-btn--primary.uni-btn--is-disabled, .uni-btn--primary.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--primary:disabled, [class*='t-de'] .uni-btn--primary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--primary:disabled, [class*='t-le'] .uni-btn--primary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--primary:disabled, .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--primary:disabled, .uni-env--light .uni-btn--primary.uni-btn--is-disabled, .uni-env--light .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--primary:disabled, [class*='t-de'] .uni-btn--primary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--primary:disabled, [class*='t-le'] .uni-btn--primary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--primary:disabled, .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--primary:disabled, .uni-env--light .uni-btn--primary.uni-btn--is-disabled, .uni-env--light .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--primary:disabled svg, .uni-btn--primary.uni-btn--is-disabled svg, .uni-btn--primary.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--primary:disabled:hover, .uni-btn--primary.uni-btn--is-disabled:hover, .uni-btn--primary.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--primary:disabled:hover, [class*='t-de'] .uni-btn--primary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--primary:disabled:hover, [class*='t-le'] .uni-btn--primary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--primary:disabled:hover, .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--primary:disabled:hover, .uni-env--light .uni-btn--primary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--primary:disabled:hover, [class*='t-de'] .uni-btn--primary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--primary:disabled:hover, [class*='t-le'] .uni-btn--primary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--primary:disabled:hover, .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--primary:disabled:hover, .uni-env--light .uni-btn--primary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--primary:disabled:focus, .uni-btn--primary.uni-btn--is-disabled:focus, .uni-btn--primary.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--primary:disabled:focus, [class*='t-de'] .uni-btn--primary.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--primary:disabled:focus, [class*='t-le'] .uni-btn--primary.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--primary:disabled:focus, .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--primary:disabled:focus, .uni-env--light .uni-btn--primary.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--primary {
    background-color: #009ce3; }
  [class*='t-le'] .uni-btn--primary {
    background-color: #009ce3; }
  .uni-env--dark .uni-btn--primary {
    background-color: #009ce3; }
  .uni-env--light .uni-btn--primary {
    background-color: #009ce3; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--primary {
    background-color: #009ce3; }
  [class*='uni-env--'] .uni-env--light .uni-btn--primary {
    background-color: #009ce3; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary {
    background-color: #009ce3; }
  [class*='t-de'] .uni-btn--primary {
    color: white; }
  [class*='t-le'] .uni-btn--primary {
    color: white; }
  .uni-env--dark .uni-btn--primary {
    color: white; }
  .uni-env--light .uni-btn--primary {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--primary {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--primary {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary {
    color: white; }
  .uni-btn--primary:hover, .uni-btn--primary:focus {
    background-color: #40b5ea; }
    [class*='t-de'] .uni-btn--primary:hover, [class*='t-de'] .uni-btn--primary:focus {
      background-color: #40b5ea; }
    [class*='t-le'] .uni-btn--primary:hover, [class*='t-le'] .uni-btn--primary:focus {
      background-color: #40b5ea; }
    .uni-env--dark .uni-btn--primary:hover, .uni-env--dark .uni-btn--primary:focus {
      background-color: #40b5ea; }
    .uni-env--light .uni-btn--primary:hover, .uni-env--light .uni-btn--primary:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:focus {
      background-color: #40b5ea; }
  .uni-btn--primary:active {
    background-color: #038fcf; }
    [class*='t-de'] .uni-btn--primary:active {
      background-color: #038fcf; }
    [class*='t-le'] .uni-btn--primary:active {
      background-color: #038fcf; }
    .uni-env--dark .uni-btn--primary:active {
      background-color: #038fcf; }
    .uni-env--light .uni-btn--primary:active {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary:active {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary:active {
      background-color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary:active {
      background-color: #038fcf; }
  .uni-btn--primary.uni-btn--is-spinning {
    background-color: #038fcf; }
    .uni-btn--primary.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--primary.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--primary.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--primary.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--primary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--primary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--primary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--primary.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='t-le'] .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-env--dark .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-env--light .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-btn--primary.uni-btn--is-spinning:hover {
      background-color: #038fcf; }
      [class*='t-de'] .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='t-le'] .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      .uni-env--dark .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      .uni-env--light .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
  .uni-btn--primary > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--secondary {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(104, 117, 129, 0.8);
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--secondary:hover, .uni-btn--secondary:active, .uni-btn--secondary:focus, .uni-btn--secondary:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--secondary:disabled, .uni-btn--secondary.uni-btn--is-disabled, .uni-btn--secondary.uni-btn--disabled {
    cursor: default; }
  .uni-btn--secondary .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--secondary:disabled, .uni-btn--secondary.uni-btn--is-disabled, .uni-btn--secondary.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--secondary:disabled, [class*='t-de'] .uni-btn--secondary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--secondary:disabled, [class*='t-le'] .uni-btn--secondary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--secondary:disabled, .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--secondary:disabled, .uni-env--light .uni-btn--secondary.uni-btn--is-disabled, .uni-env--light .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--secondary:disabled, [class*='t-de'] .uni-btn--secondary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--secondary:disabled, [class*='t-le'] .uni-btn--secondary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--secondary:disabled, .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--secondary:disabled, .uni-env--light .uni-btn--secondary.uni-btn--is-disabled, .uni-env--light .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--secondary:disabled svg, .uni-btn--secondary.uni-btn--is-disabled svg, .uni-btn--secondary.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--secondary:disabled:hover, .uni-btn--secondary.uni-btn--is-disabled:hover, .uni-btn--secondary.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--secondary:disabled:hover, [class*='t-de'] .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--secondary:disabled:hover, [class*='t-le'] .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--secondary:disabled:hover, .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--secondary:disabled:hover, .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--secondary:disabled:hover, [class*='t-de'] .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--secondary:disabled:hover, [class*='t-le'] .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--secondary:disabled:hover, .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--secondary:disabled:hover, .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--secondary:disabled:focus, .uni-btn--secondary.uni-btn--is-disabled:focus, .uni-btn--secondary.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--secondary:disabled:focus, [class*='t-de'] .uni-btn--secondary.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--secondary:disabled:focus, [class*='t-le'] .uni-btn--secondary.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--secondary:disabled:focus, .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--secondary:disabled:focus, .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-le'] .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--dark .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--light .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-de'] .uni-btn--secondary {
    color: white; }
  [class*='t-le'] .uni-btn--secondary {
    color: white; }
  .uni-env--dark .uni-btn--secondary {
    color: white; }
  .uni-env--light .uni-btn--secondary {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--secondary {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--secondary {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary {
    color: white; }
  .uni-btn--secondary:hover, .uni-btn--secondary:focus {
    background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-de'] .uni-btn--secondary:hover, [class*='t-de'] .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-le'] .uni-btn--secondary:hover, [class*='t-le'] .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--dark .uni-btn--secondary:hover, .uni-env--dark .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--light .uni-btn--secondary:hover, .uni-env--light .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:focus {
      background-color: rgba(154, 163, 171, 0.85); }
  .uni-btn--secondary:active {
    background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-de'] .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary:active {
      background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--secondary.uni-btn--is-spinning {
    background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--secondary.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--secondary.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--secondary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--secondary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--secondary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--secondary.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--secondary.uni-btn--is-spinning:hover {
      background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-de'] .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-le'] .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--light .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--secondary > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--destroy {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: #e81c00;
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--destroy:hover, .uni-btn--destroy:active, .uni-btn--destroy:focus, .uni-btn--destroy:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--destroy:disabled, .uni-btn--destroy.uni-btn--is-disabled, .uni-btn--destroy.uni-btn--disabled {
    cursor: default; }
  .uni-btn--destroy .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--destroy:disabled, .uni-btn--destroy.uni-btn--is-disabled, .uni-btn--destroy.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--destroy:disabled, [class*='t-de'] .uni-btn--destroy.uni-btn--is-disabled, [class*='t-de'] .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--destroy:disabled, [class*='t-le'] .uni-btn--destroy.uni-btn--is-disabled, [class*='t-le'] .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--destroy:disabled, .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--destroy:disabled, .uni-env--light .uni-btn--destroy.uni-btn--is-disabled, .uni-env--light .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--destroy:disabled, [class*='t-de'] .uni-btn--destroy.uni-btn--is-disabled, [class*='t-de'] .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--destroy:disabled, [class*='t-le'] .uni-btn--destroy.uni-btn--is-disabled, [class*='t-le'] .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--destroy:disabled, .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--destroy:disabled, .uni-env--light .uni-btn--destroy.uni-btn--is-disabled, .uni-env--light .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--destroy:disabled svg, .uni-btn--destroy.uni-btn--is-disabled svg, .uni-btn--destroy.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--destroy:disabled:hover, .uni-btn--destroy.uni-btn--is-disabled:hover, .uni-btn--destroy.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--destroy:disabled:hover, [class*='t-de'] .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--destroy:disabled:hover, [class*='t-le'] .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--destroy:disabled:hover, .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--destroy:disabled:hover, .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--destroy:disabled:hover, [class*='t-de'] .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--destroy:disabled:hover, [class*='t-le'] .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--destroy:disabled:hover, .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--destroy:disabled:hover, .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--destroy:disabled:focus, .uni-btn--destroy.uni-btn--is-disabled:focus, .uni-btn--destroy.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--destroy:disabled:focus, [class*='t-de'] .uni-btn--destroy.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--destroy:disabled:focus, [class*='t-le'] .uni-btn--destroy.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--destroy:disabled:focus, .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--destroy:disabled:focus, .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--destroy {
    background-color: #e81c00; }
  [class*='t-le'] .uni-btn--destroy {
    background-color: #e81c00; }
  .uni-env--dark .uni-btn--destroy {
    background-color: #e81c00; }
  .uni-env--light .uni-btn--destroy {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--destroy {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--light .uni-btn--destroy {
    background-color: #e81c00; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy {
    background-color: #e81c00; }
  [class*='t-de'] .uni-btn--destroy {
    color: white; }
  [class*='t-le'] .uni-btn--destroy {
    color: white; }
  .uni-env--dark .uni-btn--destroy {
    color: white; }
  .uni-env--light .uni-btn--destroy {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--destroy {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--destroy {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy {
    color: white; }
  .uni-btn--destroy:hover, .uni-btn--destroy:focus {
    background-color: #ff4a33; }
    [class*='t-de'] .uni-btn--destroy:hover, [class*='t-de'] .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    [class*='t-le'] .uni-btn--destroy:hover, [class*='t-le'] .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    .uni-env--dark .uni-btn--destroy:hover, .uni-env--dark .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    .uni-env--light .uni-btn--destroy:hover, .uni-env--light .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:focus {
      background-color: #ff4a33; }
  .uni-btn--destroy:active {
    background-color: #d31c03; }
    [class*='t-de'] .uni-btn--destroy:active {
      background-color: #d31c03; }
    [class*='t-le'] .uni-btn--destroy:active {
      background-color: #d31c03; }
    .uni-env--dark .uni-btn--destroy:active {
      background-color: #d31c03; }
    .uni-env--light .uni-btn--destroy:active {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy:active {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy:active {
      background-color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy:active {
      background-color: #d31c03; }
  .uni-btn--destroy.uni-btn--is-spinning {
    background-color: #d31c03; }
    .uni-btn--destroy.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--destroy.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--destroy.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--destroy.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--destroy.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--destroy.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--destroy.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--destroy.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--destroy.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--destroy.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='t-le'] .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-env--light .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-btn--destroy.uni-btn--is-spinning:hover {
      background-color: #d31c03; }
      [class*='t-de'] .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='t-le'] .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      .uni-env--light .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
  .uni-btn--destroy > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--confirm {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: #78a100;
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--confirm:hover, .uni-btn--confirm:active, .uni-btn--confirm:focus, .uni-btn--confirm:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--confirm:disabled, .uni-btn--confirm.uni-btn--is-disabled, .uni-btn--confirm.uni-btn--disabled {
    cursor: default; }
  .uni-btn--confirm .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--confirm:disabled, .uni-btn--confirm.uni-btn--is-disabled, .uni-btn--confirm.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--confirm:disabled, [class*='t-de'] .uni-btn--confirm.uni-btn--is-disabled, [class*='t-de'] .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--confirm:disabled, [class*='t-le'] .uni-btn--confirm.uni-btn--is-disabled, [class*='t-le'] .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--confirm:disabled, .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--confirm:disabled, .uni-env--light .uni-btn--confirm.uni-btn--is-disabled, .uni-env--light .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--confirm:disabled, [class*='t-de'] .uni-btn--confirm.uni-btn--is-disabled, [class*='t-de'] .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--confirm:disabled, [class*='t-le'] .uni-btn--confirm.uni-btn--is-disabled, [class*='t-le'] .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--confirm:disabled, .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--confirm:disabled, .uni-env--light .uni-btn--confirm.uni-btn--is-disabled, .uni-env--light .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--confirm:disabled svg, .uni-btn--confirm.uni-btn--is-disabled svg, .uni-btn--confirm.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--confirm:disabled:hover, .uni-btn--confirm.uni-btn--is-disabled:hover, .uni-btn--confirm.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--confirm:disabled:hover, [class*='t-de'] .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--confirm:disabled:hover, [class*='t-le'] .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--confirm:disabled:hover, .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--confirm:disabled:hover, .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--confirm:disabled:hover, [class*='t-de'] .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--confirm:disabled:hover, [class*='t-le'] .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--confirm:disabled:hover, .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--confirm:disabled:hover, .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--confirm:disabled:focus, .uni-btn--confirm.uni-btn--is-disabled:focus, .uni-btn--confirm.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--confirm:disabled:focus, [class*='t-de'] .uni-btn--confirm.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--confirm:disabled:focus, [class*='t-le'] .uni-btn--confirm.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--confirm:disabled:focus, .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--confirm:disabled:focus, .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--confirm {
    background-color: #78a100; }
  [class*='t-le'] .uni-btn--confirm {
    background-color: #78a100; }
  .uni-env--dark .uni-btn--confirm {
    background-color: #78a100; }
  .uni-env--light .uni-btn--confirm {
    background-color: #78a100; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--confirm {
    background-color: #78a100; }
  [class*='uni-env--'] .uni-env--light .uni-btn--confirm {
    background-color: #78a100; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm {
    background-color: #78a100; }
  [class*='t-de'] .uni-btn--confirm {
    color: white; }
  [class*='t-le'] .uni-btn--confirm {
    color: white; }
  .uni-env--dark .uni-btn--confirm {
    color: white; }
  .uni-env--light .uni-btn--confirm {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--confirm {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--confirm {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm {
    color: white; }
  .uni-btn--confirm:hover, .uni-btn--confirm:focus {
    background-color: #9ab940; }
    [class*='t-de'] .uni-btn--confirm:hover, [class*='t-de'] .uni-btn--confirm:focus {
      background-color: #9ab940; }
    [class*='t-le'] .uni-btn--confirm:hover, [class*='t-le'] .uni-btn--confirm:focus {
      background-color: #9ab940; }
    .uni-env--dark .uni-btn--confirm:hover, .uni-env--dark .uni-btn--confirm:focus {
      background-color: #9ab940; }
    .uni-env--light .uni-btn--confirm:hover, .uni-env--light .uni-btn--confirm:focus {
      background-color: #9ab940; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:focus {
      background-color: #9ab940; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm:focus {
      background-color: #9ab940; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:focus {
      background-color: #9ab940; }
  .uni-btn--confirm:active {
    background-color: #6f9303; }
    [class*='t-de'] .uni-btn--confirm:active {
      background-color: #6f9303; }
    [class*='t-le'] .uni-btn--confirm:active {
      background-color: #6f9303; }
    .uni-env--dark .uni-btn--confirm:active {
      background-color: #6f9303; }
    .uni-env--light .uni-btn--confirm:active {
      background-color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm:active {
      background-color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm:active {
      background-color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm:active {
      background-color: #6f9303; }
  .uni-btn--confirm.uni-btn--is-spinning {
    background-color: #6f9303; }
    .uni-btn--confirm.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--confirm.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--confirm.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--confirm.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--confirm.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--confirm.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--confirm.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--confirm.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    [class*='t-le'] .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    .uni-env--light .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning {
      background-color: #6f9303; }
    .uni-btn--confirm.uni-btn--is-spinning:hover {
      background-color: #6f9303; }
      [class*='t-de'] .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      [class*='t-le'] .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      .uni-env--light .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm.uni-btn--is-spinning:hover {
        background-color: #6f9303; }
  .uni-btn--confirm > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--subtle {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(157, 166, 174, 0.2);
  color: rgba(19, 41, 63, 0.65);
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--subtle:hover, .uni-btn--subtle:active, .uni-btn--subtle:focus, .uni-btn--subtle:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--subtle:disabled, .uni-btn--subtle.uni-btn--is-disabled, .uni-btn--subtle.uni-btn--disabled {
    cursor: default; }
  .uni-btn--subtle .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--subtle:disabled, .uni-btn--subtle.uni-btn--is-disabled, .uni-btn--subtle.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--subtle:disabled, [class*='t-de'] .uni-btn--subtle.uni-btn--is-disabled, [class*='t-de'] .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--subtle:disabled, [class*='t-le'] .uni-btn--subtle.uni-btn--is-disabled, [class*='t-le'] .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--subtle:disabled, .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--subtle:disabled, .uni-env--light .uni-btn--subtle.uni-btn--is-disabled, .uni-env--light .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--subtle:disabled, [class*='t-de'] .uni-btn--subtle.uni-btn--is-disabled, [class*='t-de'] .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--subtle:disabled, [class*='t-le'] .uni-btn--subtle.uni-btn--is-disabled, [class*='t-le'] .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--subtle:disabled, .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--subtle:disabled, .uni-env--light .uni-btn--subtle.uni-btn--is-disabled, .uni-env--light .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--subtle:disabled svg, .uni-btn--subtle.uni-btn--is-disabled svg, .uni-btn--subtle.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--subtle:disabled:hover, .uni-btn--subtle.uni-btn--is-disabled:hover, .uni-btn--subtle.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--subtle:disabled:hover, [class*='t-de'] .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--subtle:disabled:hover, [class*='t-le'] .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--subtle:disabled:hover, .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--subtle:disabled:hover, .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--subtle:disabled:hover, [class*='t-de'] .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--subtle:disabled:hover, [class*='t-le'] .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--subtle:disabled:hover, .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--subtle:disabled:hover, .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--subtle:disabled:focus, .uni-btn--subtle.uni-btn--is-disabled:focus, .uni-btn--subtle.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--subtle:disabled:focus, [class*='t-de'] .uni-btn--subtle.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--subtle:disabled:focus, [class*='t-le'] .uni-btn--subtle.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--subtle:disabled:focus, .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--subtle:disabled:focus, .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-le'] .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--dark .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--light .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-de'] .uni-btn--subtle {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-btn--subtle {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn--subtle {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-btn--subtle {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--subtle {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn--subtle {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle {
    color: rgba(230, 242, 255, 0.8); }
  .uni-btn--subtle:hover, .uni-btn--subtle:focus {
    background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-de'] .uni-btn--subtle:hover, [class*='t-de'] .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-le'] .uni-btn--subtle:hover, [class*='t-le'] .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--dark .uni-btn--subtle:hover, .uni-env--dark .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--light .uni-btn--subtle:hover, .uni-env--light .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:focus {
      background-color: rgba(193, 198, 203, 0.2); }
  .uni-btn--subtle:active {
    background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-de'] .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle:active {
      background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn--subtle.uni-btn--is-spinning {
    background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn--subtle.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--subtle.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid black;
        border-right: 0.225em solid rgba(0, 0, 0, 0.3);
        border-bottom: 0.225em solid rgba(0, 0, 0, 0.3);
        border-left: 0.225em solid rgba(0, 0, 0, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--subtle.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--subtle.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--subtle.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--subtle.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--subtle.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--subtle.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--subtle.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--subtle.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn--subtle.uni-btn--is-spinning:hover {
      background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-de'] .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-le'] .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--light .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn--subtle > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--combo {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(104, 117, 129, 0.8);
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--combo:hover, .uni-btn--combo:active, .uni-btn--combo:focus, .uni-btn--combo:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--combo:disabled, .uni-btn--combo.uni-btn--is-disabled, .uni-btn--combo.uni-btn--disabled {
    cursor: default; }
  .uni-btn--combo .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--combo:disabled, .uni-btn--combo.uni-btn--is-disabled, .uni-btn--combo.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--combo:disabled, [class*='t-de'] .uni-btn--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--combo:disabled, [class*='t-le'] .uni-btn--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--combo:disabled, .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--combo:disabled, .uni-env--light .uni-btn--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--combo:disabled, [class*='t-de'] .uni-btn--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--combo:disabled, [class*='t-le'] .uni-btn--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--combo:disabled, .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--combo:disabled, .uni-env--light .uni-btn--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--combo:disabled svg, .uni-btn--combo.uni-btn--is-disabled svg, .uni-btn--combo.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--combo:disabled:hover, .uni-btn--combo.uni-btn--is-disabled:hover, .uni-btn--combo.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--combo:disabled:hover, [class*='t-de'] .uni-btn--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--combo:disabled:hover, [class*='t-le'] .uni-btn--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--combo:disabled:hover, .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--combo:disabled:hover, .uni-env--light .uni-btn--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--combo:disabled:hover, [class*='t-de'] .uni-btn--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--combo:disabled:hover, [class*='t-le'] .uni-btn--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--combo:disabled:hover, .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--combo:disabled:hover, .uni-env--light .uni-btn--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--combo:disabled:focus, .uni-btn--combo.uni-btn--is-disabled:focus, .uni-btn--combo.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--combo:disabled:focus, [class*='t-de'] .uni-btn--combo.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--combo:disabled:focus, [class*='t-le'] .uni-btn--combo.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--combo:disabled:focus, .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--combo:disabled:focus, .uni-env--light .uni-btn--combo.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--combo:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-le'] .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--dark .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--light .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-de'] .uni-btn--combo {
    color: white; }
  [class*='t-le'] .uni-btn--combo {
    color: white; }
  .uni-env--dark .uni-btn--combo {
    color: white; }
  .uni-env--light .uni-btn--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--combo {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo {
    color: white; }
  .uni-btn--combo:hover, .uni-btn--combo:focus {
    background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-de'] .uni-btn--combo:hover, [class*='t-de'] .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-le'] .uni-btn--combo:hover, [class*='t-le'] .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--dark .uni-btn--combo:hover, .uni-env--dark .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--light .uni-btn--combo:hover, .uni-env--light .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--combo:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--light .uni-btn--combo:hover, [class*='uni-env--'] .uni-env--light .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
  .uni-btn--combo:active {
    background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-de'] .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--combo.uni-btn--is-spinning {
    background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--combo.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--combo.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--combo.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--combo.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--combo.uni-btn--is-spinning:hover {
      background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-de'] .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-le'] .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--dark .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--light .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--light .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--combo > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--primary--combo {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: #009ce3;
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--primary--combo:hover, .uni-btn--primary--combo:active, .uni-btn--primary--combo:focus, .uni-btn--primary--combo:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--primary--combo:disabled, .uni-btn--primary--combo.uni-btn--is-disabled, .uni-btn--primary--combo.uni-btn--disabled {
    cursor: default; }
  .uni-btn--primary--combo .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--primary--combo:disabled, .uni-btn--primary--combo.uni-btn--is-disabled, .uni-btn--primary--combo.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--primary--combo:disabled, [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--primary--combo:disabled, [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--primary--combo:disabled, .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--primary--combo:disabled, .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--primary--combo:disabled, [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--primary--combo:disabled, [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--primary--combo:disabled, .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--primary--combo:disabled, .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--primary--combo:disabled svg, .uni-btn--primary--combo.uni-btn--is-disabled svg, .uni-btn--primary--combo.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--primary--combo:disabled:hover, .uni-btn--primary--combo.uni-btn--is-disabled:hover, .uni-btn--primary--combo.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--primary--combo:disabled:hover, [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--primary--combo:disabled:hover, [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--primary--combo:disabled:hover, .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--primary--combo:disabled:hover, .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--primary--combo:disabled:hover, [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--primary--combo:disabled:hover, [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--primary--combo:disabled:hover, .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--primary--combo:disabled:hover, .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--primary--combo:disabled:focus, .uni-btn--primary--combo.uni-btn--is-disabled:focus, .uni-btn--primary--combo.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--primary--combo:disabled:focus, [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--primary--combo:disabled:focus, [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--primary--combo:disabled:focus, .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--primary--combo:disabled:focus, .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--primary--combo {
    background-color: #009ce3; }
  [class*='t-le'] .uni-btn--primary--combo {
    background-color: #009ce3; }
  .uni-env--dark .uni-btn--primary--combo {
    background-color: #009ce3; }
  .uni-env--light .uni-btn--primary--combo {
    background-color: #009ce3; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo {
    background-color: #009ce3; }
  [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo {
    background-color: #009ce3; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo {
    background-color: #009ce3; }
  [class*='t-de'] .uni-btn--primary--combo {
    color: white; }
  [class*='t-le'] .uni-btn--primary--combo {
    color: white; }
  .uni-env--dark .uni-btn--primary--combo {
    color: white; }
  .uni-env--light .uni-btn--primary--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo {
    color: white; }
  .uni-btn--primary--combo:hover, .uni-btn--primary--combo:focus {
    background-color: #40b5ea; }
    [class*='t-de'] .uni-btn--primary--combo:hover, [class*='t-de'] .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    [class*='t-le'] .uni-btn--primary--combo:hover, [class*='t-le'] .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    .uni-env--dark .uni-btn--primary--combo:hover, .uni-env--dark .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    .uni-env--light .uni-btn--primary--combo:hover, .uni-env--light .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:focus {
      background-color: #40b5ea; }
  .uni-btn--primary--combo:active {
    background-color: #038fcf; }
    [class*='t-de'] .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    [class*='t-le'] .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    .uni-env--dark .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    .uni-env--light .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo:active {
      background-color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo:active {
      background-color: #038fcf; }
  .uni-btn--primary--combo.uni-btn--is-spinning {
    background-color: #038fcf; }
    .uni-btn--primary--combo.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--primary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--primary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--primary--combo.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--primary--combo.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-env--light .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning {
      background-color: #038fcf; }
    .uni-btn--primary--combo.uni-btn--is-spinning:hover {
      background-color: #038fcf; }
      [class*='t-de'] .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='t-le'] .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      .uni-env--light .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--combo.uni-btn--is-spinning:hover {
        background-color: #038fcf; }
  .uni-btn--primary--combo > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--secondary--combo {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(104, 117, 129, 0.8);
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--secondary--combo:hover, .uni-btn--secondary--combo:active, .uni-btn--secondary--combo:focus, .uni-btn--secondary--combo:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--secondary--combo:disabled, .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-btn--secondary--combo.uni-btn--disabled {
    cursor: default; }
  .uni-btn--secondary--combo .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--secondary--combo:disabled, .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-btn--secondary--combo.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--secondary--combo:disabled, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--secondary--combo:disabled, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--secondary--combo:disabled, .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--secondary--combo:disabled, .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--secondary--combo:disabled, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--secondary--combo:disabled, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--secondary--combo:disabled, .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--secondary--combo:disabled, .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled, .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--secondary--combo:disabled svg, .uni-btn--secondary--combo.uni-btn--is-disabled svg, .uni-btn--secondary--combo.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--secondary--combo:disabled:hover, .uni-btn--secondary--combo.uni-btn--is-disabled:hover, .uni-btn--secondary--combo.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--secondary--combo:disabled:hover, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--secondary--combo:disabled:hover, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--secondary--combo:disabled:hover, .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--secondary--combo:disabled:hover, .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--secondary--combo:disabled:hover, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--secondary--combo:disabled:hover, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--secondary--combo:disabled:hover, .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--secondary--combo:disabled:hover, .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--secondary--combo:disabled:focus, .uni-btn--secondary--combo.uni-btn--is-disabled:focus, .uni-btn--secondary--combo.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--secondary--combo:disabled:focus, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--secondary--combo:disabled:focus, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--secondary--combo:disabled:focus, .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--secondary--combo:disabled:focus, .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-le'] .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--dark .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  .uni-env--light .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo {
    background-color: rgba(104, 117, 129, 0.8); }
  [class*='t-de'] .uni-btn--secondary--combo {
    color: white; }
  [class*='t-le'] .uni-btn--secondary--combo {
    color: white; }
  .uni-env--dark .uni-btn--secondary--combo {
    color: white; }
  .uni-env--light .uni-btn--secondary--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo {
    color: white; }
  .uni-btn--secondary--combo:hover, .uni-btn--secondary--combo:focus {
    background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-de'] .uni-btn--secondary--combo:hover, [class*='t-de'] .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='t-le'] .uni-btn--secondary--combo:hover, [class*='t-le'] .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--dark .uni-btn--secondary--combo:hover, .uni-env--dark .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    .uni-env--light .uni-btn--secondary--combo:hover, .uni-env--light .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:focus {
      background-color: rgba(154, 163, 171, 0.85); }
  .uni-btn--secondary--combo:active {
    background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-de'] .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo:active {
      background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--secondary--combo.uni-btn--is-spinning {
    background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--secondary--combo.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--secondary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--secondary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--secondary--combo.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--combo.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--secondary--combo.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-env--light .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning {
      background-color: rgba(93, 104, 114, 0.82); }
    .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
      background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-de'] .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='t-le'] .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      .uni-env--light .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--combo.uni-btn--is-spinning:hover {
        background-color: rgba(93, 104, 114, 0.82); }
  .uni-btn--secondary--combo > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-btn--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 14, 63, 0.5);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--minimal:hover, .uni-btn--minimal:active, .uni-btn--minimal:focus, .uni-btn--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--minimal:disabled, .uni-btn--minimal.uni-btn--is-disabled, .uni-btn--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--minimal:disabled, .uni-btn--minimal.uni-btn--is-disabled, .uni-btn--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--minimal:disabled, [class*='t-de'] .uni-btn--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--minimal:disabled, [class*='t-le'] .uni-btn--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--minimal:disabled, .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--minimal:disabled, .uni-env--light .uni-btn--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--minimal:disabled:hover, .uni-btn--minimal.uni-btn--is-disabled:hover, .uni-btn--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--minimal:disabled:hover, [class*='t-de'] .uni-btn--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--minimal:disabled:hover, [class*='t-le'] .uni-btn--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--minimal:disabled:hover, .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--minimal:disabled:hover, .uni-env--light .uni-btn--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--minimal {
    color: rgba(230, 242, 255, 0.5); }
  [class*='t-le'] .uni-btn--minimal {
    color: rgba(19, 14, 63, 0.5); }
  .uni-env--dark .uni-btn--minimal {
    color: rgba(230, 242, 255, 0.5); }
  .uni-env--light .uni-btn--minimal {
    color: rgba(19, 14, 63, 0.5); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--minimal {
    color: rgba(230, 242, 255, 0.5); }
  [class*='uni-env--'] .uni-env--light .uni-btn--minimal {
    color: rgba(19, 14, 63, 0.5); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal {
    color: rgba(230, 242, 255, 0.5); }
  .uni-btn--minimal.uni-btn--small, .uni-btn--minimal.uni-btn--medium, .uni-btn--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--minimal:hover, .uni-btn--minimal:focus {
    color: #a8afb7; }
    [class*='t-de'] .uni-btn--minimal:hover, [class*='t-de'] .uni-btn--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--minimal:hover, [class*='t-le'] .uni-btn--minimal:focus {
      color: #a8afb7; }
    .uni-env--dark .uni-btn--minimal:hover, .uni-env--dark .uni-btn--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--minimal:hover, .uni-env--light .uni-btn--minimal:focus {
      color: #a8afb7; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--minimal:focus {
      color: #a8afb7; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
  .uni-btn--minimal:active {
    color: #7c858e; }
    [class*='t-de'] .uni-btn--minimal:active {
      color: #7c858e; }
    [class*='t-le'] .uni-btn--minimal:active {
      color: #7c858e; }
    .uni-env--dark .uni-btn--minimal:active {
      color: #7c858e; }
    .uni-env--light .uni-btn--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal:active {
      color: #7c858e; }
  .uni-btn--minimal.uni-btn--is-spinning {
    color: #7c858e; }
    .uni-btn--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 14, 63, 0.5);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='t-le'] .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-env--light .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-btn--minimal.uni-btn--is-spinning:hover {
      color: #7c858e; }
      [class*='t-de'] .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='t-le'] .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      .uni-env--light .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
  .uni-btn--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--primary--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #009ce3;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--primary--minimal:hover, .uni-btn--primary--minimal:active, .uni-btn--primary--minimal:focus, .uni-btn--primary--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--primary--minimal:disabled, .uni-btn--primary--minimal.uni-btn--is-disabled, .uni-btn--primary--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--primary--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--primary--minimal:disabled, .uni-btn--primary--minimal.uni-btn--is-disabled, .uni-btn--primary--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--primary--minimal:disabled, [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--primary--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--primary--minimal:disabled, [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--primary--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--primary--minimal:disabled, .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--primary--minimal:disabled, .uni-env--light .uni-btn--primary--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--primary--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--primary--minimal:disabled:hover, .uni-btn--primary--minimal.uni-btn--is-disabled:hover, .uni-btn--primary--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--primary--minimal:disabled:hover, [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--primary--minimal:disabled:hover, [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--primary--minimal:disabled:hover, .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--primary--minimal:disabled:hover, .uni-env--light .uni-btn--primary--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--primary--minimal {
    color: #009ce3; }
  [class*='t-le'] .uni-btn--primary--minimal {
    color: #009ce3; }
  .uni-env--dark .uni-btn--primary--minimal {
    color: #009ce3; }
  .uni-env--light .uni-btn--primary--minimal {
    color: #009ce3; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal {
    color: #009ce3; }
  [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal {
    color: #009ce3; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal {
    color: #009ce3; }
  .uni-btn--primary--minimal.uni-btn--small, .uni-btn--primary--minimal.uni-btn--medium, .uni-btn--primary--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--primary--minimal:hover, .uni-btn--primary--minimal:focus {
    color: #40b5ea; }
    [class*='t-de'] .uni-btn--primary--minimal:hover, [class*='t-de'] .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    [class*='t-le'] .uni-btn--primary--minimal:hover, [class*='t-le'] .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    .uni-env--dark .uni-btn--primary--minimal:hover, .uni-env--dark .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    .uni-env--light .uni-btn--primary--minimal:hover, .uni-env--light .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:focus {
      color: #40b5ea; }
  .uni-btn--primary--minimal:active {
    color: #038fcf; }
    [class*='t-de'] .uni-btn--primary--minimal:active {
      color: #038fcf; }
    [class*='t-le'] .uni-btn--primary--minimal:active {
      color: #038fcf; }
    .uni-env--dark .uni-btn--primary--minimal:active {
      color: #038fcf; }
    .uni-env--light .uni-btn--primary--minimal:active {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:active {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal:active {
      color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal:active {
      color: #038fcf; }
  .uni-btn--primary--minimal.uni-btn--is-spinning {
    color: #038fcf; }
    .uni-btn--primary--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--primary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #009ce3;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--primary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--primary--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--primary--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-env--light .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
      color: #038fcf; }
      [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      .uni-env--light .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-spinning:hover {
        color: #038fcf; }
  .uni-btn--primary--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--primary--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--primary--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--primary--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--primary--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--primary--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--primary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--secondary--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 41, 63, 0.65);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--secondary--minimal:hover, .uni-btn--secondary--minimal:active, .uni-btn--secondary--minimal:focus, .uni-btn--secondary--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--secondary--minimal:disabled, .uni-btn--secondary--minimal.uni-btn--is-disabled, .uni-btn--secondary--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--secondary--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--secondary--minimal:disabled, .uni-btn--secondary--minimal.uni-btn--is-disabled, .uni-btn--secondary--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--secondary--minimal:disabled, [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--secondary--minimal:disabled, [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--secondary--minimal:disabled, .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--secondary--minimal:disabled, .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--secondary--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--secondary--minimal:disabled:hover, .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, .uni-btn--secondary--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--secondary--minimal:disabled:hover, [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--secondary--minimal:disabled:hover, [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--secondary--minimal:disabled:hover, .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--secondary--minimal:disabled:hover, .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--secondary--minimal {
    color: rgba(230, 242, 255, 0.9); }
  [class*='t-le'] .uni-btn--secondary--minimal {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn--secondary--minimal {
    color: rgba(230, 242, 255, 0.9); }
  .uni-env--light .uni-btn--secondary--minimal {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal {
    color: rgba(230, 242, 255, 0.9); }
  [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal {
    color: rgba(230, 242, 255, 0.9); }
  .uni-btn--secondary--minimal.uni-btn--small, .uni-btn--secondary--minimal.uni-btn--medium, .uni-btn--secondary--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--secondary--minimal:hover, .uni-btn--secondary--minimal:focus {
    color: rgba(116, 129, 142, 0.7375); }
    [class*='t-de'] .uni-btn--secondary--minimal:hover, [class*='t-de'] .uni-btn--secondary--minimal:focus {
      color: #e6f2ff; }
    [class*='t-le'] .uni-btn--secondary--minimal:hover, [class*='t-le'] .uni-btn--secondary--minimal:focus {
      color: rgba(116, 129, 142, 0.7375); }
    .uni-env--dark .uni-btn--secondary--minimal:hover, .uni-env--dark .uni-btn--secondary--minimal:focus {
      color: #e6f2ff; }
    .uni-env--light .uni-btn--secondary--minimal:hover, .uni-env--light .uni-btn--secondary--minimal:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:focus {
      color: #e6f2ff; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:focus {
      color: #e6f2ff; }
  .uni-btn--secondary--minimal:active {
    color: rgba(20, 38, 56, 0.685); }
    [class*='t-de'] .uni-btn--secondary--minimal:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--secondary--minimal:active {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--secondary--minimal:active {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--secondary--minimal:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal:active {
      color: rgba(230, 242, 255, 0.65); }
  .uni-btn--secondary--minimal.uni-btn--is-spinning {
    color: rgba(20, 38, 56, 0.685); }
    .uni-btn--secondary--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--secondary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 41, 63, 0.65);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--secondary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--secondary--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--secondary--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
      color: rgba(20, 38, 56, 0.685); }
      [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
  .uni-btn--secondary--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--secondary--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--secondary--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--secondary--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--secondary--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--secondary--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--destroy--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #e81c00;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--destroy--minimal:hover, .uni-btn--destroy--minimal:active, .uni-btn--destroy--minimal:focus, .uni-btn--destroy--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--destroy--minimal:disabled, .uni-btn--destroy--minimal.uni-btn--is-disabled, .uni-btn--destroy--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--destroy--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--destroy--minimal:disabled, .uni-btn--destroy--minimal.uni-btn--is-disabled, .uni-btn--destroy--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--destroy--minimal:disabled, [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--destroy--minimal:disabled, [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--destroy--minimal:disabled, .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--destroy--minimal:disabled, .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--destroy--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--destroy--minimal:disabled:hover, .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, .uni-btn--destroy--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--destroy--minimal:disabled:hover, [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--destroy--minimal:disabled:hover, [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--destroy--minimal:disabled:hover, .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--destroy--minimal:disabled:hover, .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--destroy--minimal {
    color: #e81c00; }
  [class*='t-le'] .uni-btn--destroy--minimal {
    color: #e81c00; }
  .uni-env--dark .uni-btn--destroy--minimal {
    color: #e81c00; }
  .uni-env--light .uni-btn--destroy--minimal {
    color: #e81c00; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal {
    color: #e81c00; }
  [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal {
    color: #e81c00; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal {
    color: #e81c00; }
  .uni-btn--destroy--minimal.uni-btn--small, .uni-btn--destroy--minimal.uni-btn--medium, .uni-btn--destroy--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--destroy--minimal:hover, .uni-btn--destroy--minimal:focus {
    color: #ff4a33; }
    [class*='t-de'] .uni-btn--destroy--minimal:hover, [class*='t-de'] .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    [class*='t-le'] .uni-btn--destroy--minimal:hover, [class*='t-le'] .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    .uni-env--dark .uni-btn--destroy--minimal:hover, .uni-env--dark .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    .uni-env--light .uni-btn--destroy--minimal:hover, .uni-env--light .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:focus {
      color: #ff4a33; }
  .uni-btn--destroy--minimal:active {
    color: #d31c03; }
    [class*='t-de'] .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    [class*='t-le'] .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    .uni-env--dark .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    .uni-env--light .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal:active {
      color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal:active {
      color: #d31c03; }
  .uni-btn--destroy--minimal.uni-btn--is-spinning {
    color: #d31c03; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--destroy--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #e81c00;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--destroy--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
      color: #d31c03; }
      [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-spinning:hover {
        color: #d31c03; }
  .uni-btn--destroy--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--destroy--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--destroy--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--destroy--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--destroy--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--destroy--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--confirm--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #78a100;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--confirm--minimal:hover, .uni-btn--confirm--minimal:active, .uni-btn--confirm--minimal:focus, .uni-btn--confirm--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--confirm--minimal:disabled, .uni-btn--confirm--minimal.uni-btn--is-disabled, .uni-btn--confirm--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--confirm--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--confirm--minimal:disabled, .uni-btn--confirm--minimal.uni-btn--is-disabled, .uni-btn--confirm--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--confirm--minimal:disabled, [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--confirm--minimal:disabled, [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--confirm--minimal:disabled, .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--confirm--minimal:disabled, .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--confirm--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--confirm--minimal:disabled:hover, .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, .uni-btn--confirm--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--confirm--minimal:disabled:hover, [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--confirm--minimal:disabled:hover, [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--confirm--minimal:disabled:hover, .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--confirm--minimal:disabled:hover, .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--confirm--minimal {
    color: #78a100; }
  [class*='t-le'] .uni-btn--confirm--minimal {
    color: #78a100; }
  .uni-env--dark .uni-btn--confirm--minimal {
    color: #78a100; }
  .uni-env--light .uni-btn--confirm--minimal {
    color: #78a100; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal {
    color: #78a100; }
  [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal {
    color: #78a100; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal {
    color: #78a100; }
  .uni-btn--confirm--minimal.uni-btn--small, .uni-btn--confirm--minimal.uni-btn--medium, .uni-btn--confirm--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--confirm--minimal:hover, .uni-btn--confirm--minimal:focus {
    color: #9ab940; }
    [class*='t-de'] .uni-btn--confirm--minimal:hover, [class*='t-de'] .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    [class*='t-le'] .uni-btn--confirm--minimal:hover, [class*='t-le'] .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    .uni-env--dark .uni-btn--confirm--minimal:hover, .uni-env--dark .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    .uni-env--light .uni-btn--confirm--minimal:hover, .uni-env--light .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:focus {
      color: #9ab940; }
  .uni-btn--confirm--minimal:active {
    color: #6f9303; }
    [class*='t-de'] .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    [class*='t-le'] .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    .uni-env--dark .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    .uni-env--light .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal:active {
      color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal:active {
      color: #6f9303; }
  .uni-btn--confirm--minimal.uni-btn--is-spinning {
    color: #6f9303; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--confirm--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #78a100;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--confirm--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
      color: #6f9303; }
      [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-spinning:hover {
        color: #6f9303; }
  .uni-btn--confirm--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--confirm--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--confirm--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--confirm--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--confirm--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--confirm--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--subtle--minimal {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 14, 63, 0.5);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--subtle--minimal:hover, .uni-btn--subtle--minimal:active, .uni-btn--subtle--minimal:focus, .uni-btn--subtle--minimal:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--subtle--minimal:disabled, .uni-btn--subtle--minimal.uni-btn--is-disabled, .uni-btn--subtle--minimal.uni-btn--disabled {
    cursor: default; }
  .uni-btn--subtle--minimal .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--subtle--minimal:disabled, .uni-btn--subtle--minimal.uni-btn--is-disabled, .uni-btn--subtle--minimal.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--subtle--minimal:disabled, [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-disabled, [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--subtle--minimal:disabled, [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-disabled, [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--subtle--minimal:disabled, .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled, .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--subtle--minimal:disabled, .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-disabled, .uni-env--light .uni-btn--subtle--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled {
      color: white; }
    .uni-btn--subtle--minimal:disabled:hover, .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, .uni-btn--subtle--minimal.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--subtle--minimal:disabled:hover, [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--subtle--minimal:disabled:hover, [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--subtle--minimal:disabled:hover, .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--subtle--minimal:disabled:hover, .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--subtle--minimal {
    color: rgba(230, 242, 255, 0.5); }
  [class*='t-le'] .uni-btn--subtle--minimal {
    color: rgba(19, 14, 63, 0.5); }
  .uni-env--dark .uni-btn--subtle--minimal {
    color: rgba(230, 242, 255, 0.5); }
  .uni-env--light .uni-btn--subtle--minimal {
    color: rgba(19, 14, 63, 0.5); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal {
    color: rgba(230, 242, 255, 0.5); }
  [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal {
    color: rgba(19, 14, 63, 0.5); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal {
    color: rgba(230, 242, 255, 0.5); }
  .uni-btn--subtle--minimal.uni-btn--small, .uni-btn--subtle--minimal.uni-btn--medium, .uni-btn--subtle--minimal.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--subtle--minimal:hover, .uni-btn--subtle--minimal:focus {
    color: #a8afb7; }
    [class*='t-de'] .uni-btn--subtle--minimal:hover, [class*='t-de'] .uni-btn--subtle--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--subtle--minimal:hover, [class*='t-le'] .uni-btn--subtle--minimal:focus {
      color: #a8afb7; }
    .uni-env--dark .uni-btn--subtle--minimal:hover, .uni-env--dark .uni-btn--subtle--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--subtle--minimal:hover, .uni-env--light .uni-btn--subtle--minimal:focus {
      color: #a8afb7; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal:hover, [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal:focus {
      color: #a8afb7; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:focus {
      color: rgba(230, 242, 255, 0.65); }
  .uni-btn--subtle--minimal:active {
    color: #7c858e; }
    [class*='t-de'] .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    [class*='t-le'] .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    .uni-env--dark .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    .uni-env--light .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal:active {
      color: #7c858e; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal:active {
      color: #7c858e; }
  .uni-btn--subtle--minimal.uni-btn--is-spinning {
    color: #7c858e; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--subtle--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 14, 63, 0.5);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--subtle--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle--minimal.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning {
      color: #7c858e; }
    .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
      color: #7c858e; }
      [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-spinning:hover {
        color: #7c858e; }
  .uni-btn--subtle--minimal.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--subtle--minimal.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--subtle--minimal.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--subtle--minimal.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--subtle--minimal.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--subtle--minimal.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--cancel {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 41, 63, 0.4);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear;
  font-weight: normal; }
  .uni-btn--cancel:hover, .uni-btn--cancel:active, .uni-btn--cancel:focus, .uni-btn--cancel:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--cancel:disabled, .uni-btn--cancel.uni-btn--is-disabled, .uni-btn--cancel.uni-btn--disabled {
    cursor: default; }
  .uni-btn--cancel .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--cancel:disabled, .uni-btn--cancel.uni-btn--is-disabled, .uni-btn--cancel.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--cancel:disabled, [class*='t-de'] .uni-btn--cancel.uni-btn--is-disabled, [class*='t-de'] .uni-btn--cancel.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--cancel:disabled, [class*='t-le'] .uni-btn--cancel.uni-btn--is-disabled, [class*='t-le'] .uni-btn--cancel.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--cancel:disabled, .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled, .uni-env--dark .uni-btn--cancel.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--cancel:disabled, .uni-env--light .uni-btn--cancel.uni-btn--is-disabled, .uni-env--light .uni-btn--cancel.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--disabled {
      color: white; }
    .uni-btn--cancel:disabled:hover, .uni-btn--cancel.uni-btn--is-disabled:hover, .uni-btn--cancel.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--cancel:disabled:hover, [class*='t-de'] .uni-btn--cancel.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--cancel.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--cancel:disabled:hover, [class*='t-le'] .uni-btn--cancel.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--cancel:disabled:hover, .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--cancel.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--cancel:disabled:hover, .uni-env--light .uni-btn--cancel.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--cancel:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--cancel:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--cancel {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-btn--cancel {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-btn--cancel {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-btn--cancel {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--cancel {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-btn--cancel {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel {
    color: rgba(230, 242, 255, 0.4); }
  .uni-btn--cancel.uni-btn--small, .uni-btn--cancel.uni-btn--medium, .uni-btn--cancel.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--cancel:hover, .uni-btn--cancel:focus {
    color: rgba(154, 163, 173, 0.55); }
    [class*='t-de'] .uni-btn--cancel:hover, [class*='t-de'] .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='t-le'] .uni-btn--cancel:hover, [class*='t-le'] .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    .uni-env--dark .uni-btn--cancel:hover, .uni-env--dark .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    .uni-env--light .uni-btn--cancel:hover, .uni-env--light .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel:hover, [class*='uni-env--'] .uni-env--light .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
  .uni-btn--cancel:active {
    color: rgba(21, 36, 51, 0.46); }
    [class*='t-de'] .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='t-le'] .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--dark .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--light .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
  .uni-btn--cancel.uni-btn--is-spinning {
    color: rgba(21, 36, 51, 0.46); }
    .uni-btn--cancel.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 41, 63, 0.4);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--cancel.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--cancel.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--cancel.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--cancel.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--cancel.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--cancel.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--cancel.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--cancel.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='t-le'] .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--light .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-btn--cancel.uni-btn--is-spinning:hover {
      color: rgba(21, 36, 51, 0.46); }
      [class*='t-de'] .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='t-le'] .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      .uni-env--light .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
  .uni-btn--cancel.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--cancel.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--cancel.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--cancel.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--cancel.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--cancel.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--cancel.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--small {
  height: 2.14286em;
  line-height: 2.14286;
  padding-top: 0;
  padding-right: 1.14286em;
  padding-bottom: 0;
  padding-left: 1.14286em;
  font-size: 0.875rem;
  font-weight: bold; }
  .uni-btn--small .uni-btn__icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    margin-left: -4px; }

.uni-btn--medium {
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold; }
  .uni-btn--medium .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }

.uni-btn--large {
  height: 3em;
  line-height: 3;
  padding-top: 0;
  padding-right: 2.22222em;
  padding-bottom: 0;
  padding-left: 2.22222em;
  font-size: 1.125rem;
  font-weight: 400; }
  .uni-btn--large .uni-btn__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    margin-left: -14px; }

.uni-btn--block {
  display: block;
  width: 100%;
  margin-left: 0 !important;
  text-align: center; }

.uni-btn__row {
  font-size: 0; }
  .uni-btn__row > [class^='uni-btn'] + [class^='uni-btn'] {
    margin-left: 0.5rem; }

.uni-btn__group--combo {
  font-size: 0; }
  .uni-btn__group--combo > [class^='uni-btn'] {
    overflow: hidden;
    border-radius: 0; }
    .uni-btn__group--combo > [class^='uni-btn']:first-of-type {
      border-top-left-radius: 0.245em;
      border-bottom-left-radius: 0.245em; }
      .uni-btn__group--combo > [class^='uni-btn']:first-of-type > [class^='uni-btn__label'] {
        border-top-left-radius: 0.245em;
        border-bottom-left-radius: 0.245em; }
    .uni-btn__group--combo > [class^='uni-btn']:last-of-type {
      border-top-right-radius: 0.245em;
      border-bottom-right-radius: 0.245em; }
      .uni-btn__group--combo > [class^='uni-btn']:last-of-type > [class^='uni-btn__label'] {
        border-top-right-radius: 0.245em;
        border-bottom-right-radius: 0.245em; }

.uni-btn__icon {
  position: relative;
  top: -0.04em;
  fill: currentColor;
  transition: 0.6s opacity ease-in-out; }
  .uni-btn--is-spinning .uni-btn__icon,
  .uni-btn--is-success .uni-btn__icon,
  .uni-btn--is-failure .uni-btn__icon {
    visibility: hidden;
    width: 0;
    opacity: 0;
    display: none; }

.uni-btn--icon-only {
  padding-left: 0.5em;
  padding-right: 0.5em;
  width: 2.5em; }
  .uni-btn--icon-only .uni-btn__icon {
    margin-left: 0;
    margin-right: 0; }
  .uni-btn--icon-only.uni-btn--small {
    padding-left: 0.5em;
    padding-right: 0.5em;
    width: 2.14286em; }
  .uni-btn--icon-only.uni-btn--large {
    width: 3em; }

.uni-btn--radio,
.uni-btn--check {
  display: inline-block; }

.uni-btn__label--custom,
.uni-btn__label--custom--alt {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: rgba(157, 166, 174, 0.2);
  color: rgba(19, 41, 63, 0.65);
  transition: background-color 0.3s linear, color 0.3s linear;
  border-radius: 0;
  background-image: none; }
  .uni-btn__label--custom:hover, .uni-btn__label--custom:active, .uni-btn__label--custom:focus, .uni-btn__label--custom:visited,
  .uni-btn__label--custom--alt:hover,
  .uni-btn__label--custom--alt:active,
  .uni-btn__label--custom--alt:focus,
  .uni-btn__label--custom--alt:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn__label--custom:disabled, .uni-btn__label--custom.uni-btn--is-disabled, .uni-btn__label--custom.uni-btn--disabled,
  .uni-btn__label--custom--alt:disabled,
  .uni-btn__label--custom--alt.uni-btn--is-disabled,
  .uni-btn__label--custom--alt.uni-btn--disabled {
    cursor: default; }
  .uni-btn__label--custom .uni-btn__icon,
  .uni-btn__label--custom--alt .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn__label--custom:disabled, .uni-btn__label--custom.uni-btn--is-disabled, .uni-btn__label--custom.uni-btn--disabled,
  .uni-btn__label--custom--alt:disabled,
  .uni-btn__label--custom--alt.uni-btn--is-disabled,
  .uni-btn__label--custom--alt.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn__label--custom:disabled, [class*='t-de'] .uni-btn__label--custom.uni-btn--is-disabled, [class*='t-de'] .uni-btn__label--custom.uni-btn--disabled, [class*='t-de']
    .uni-btn__label--custom--alt:disabled, [class*='t-de']
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='t-de']
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn__label--custom:disabled, [class*='t-le'] .uni-btn__label--custom.uni-btn--is-disabled, [class*='t-le'] .uni-btn__label--custom.uni-btn--disabled, [class*='t-le']
    .uni-btn__label--custom--alt:disabled, [class*='t-le']
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='t-le']
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn__label--custom:disabled, .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, .uni-env--dark
    .uni-btn__label--custom--alt:disabled, .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn__label--custom:disabled, .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled, .uni-env--light .uni-btn__label--custom.uni-btn--disabled, .uni-env--light
    .uni-btn__label--custom--alt:disabled, .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-disabled, .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:disabled, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn__label--custom:disabled, [class*='t-de'] .uni-btn__label--custom.uni-btn--is-disabled, [class*='t-de'] .uni-btn__label--custom.uni-btn--disabled, [class*='t-de']
    .uni-btn__label--custom--alt:disabled, [class*='t-de']
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='t-de']
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn__label--custom:disabled, [class*='t-le'] .uni-btn__label--custom.uni-btn--is-disabled, [class*='t-le'] .uni-btn__label--custom.uni-btn--disabled, [class*='t-le']
    .uni-btn__label--custom--alt:disabled, [class*='t-le']
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='t-le']
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn__label--custom:disabled, .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, .uni-env--dark
    .uni-btn__label--custom--alt:disabled, .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn__label--custom:disabled, .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled, .uni-env--light .uni-btn__label--custom.uni-btn--disabled, .uni-env--light
    .uni-btn__label--custom--alt:disabled, .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-disabled, .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:disabled, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--disabled {
      color: #232a31; }
    .uni-btn__label--custom:disabled svg, .uni-btn__label--custom.uni-btn--is-disabled svg, .uni-btn__label--custom.uni-btn--disabled svg,
    .uni-btn__label--custom--alt:disabled svg,
    .uni-btn__label--custom--alt.uni-btn--is-disabled svg,
    .uni-btn__label--custom--alt.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn__label--custom:disabled:hover, .uni-btn__label--custom.uni-btn--is-disabled:hover, .uni-btn__label--custom.uni-btn--disabled:hover,
    .uni-btn__label--custom--alt:disabled:hover,
    .uni-btn__label--custom--alt.uni-btn--is-disabled:hover,
    .uni-btn__label--custom--alt.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn__label--custom:disabled:hover, [class*='t-de'] .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn__label--custom.uni-btn--disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt:disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn__label--custom:disabled:hover, [class*='t-le'] .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn__label--custom.uni-btn--disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt:disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn__label--custom:disabled:hover, .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn__label--custom:disabled:hover, .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:hover, .uni-env--light .uni-btn__label--custom.uni-btn--disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt:disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn__label--custom:disabled:hover, [class*='t-de'] .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn__label--custom.uni-btn--disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt:disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn__label--custom:disabled:hover, [class*='t-le'] .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn__label--custom.uni-btn--disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt:disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn__label--custom:disabled:hover, .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn__label--custom:disabled:hover, .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:hover, .uni-env--light .uni-btn__label--custom.uni-btn--disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt:disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn__label--custom:disabled:focus, .uni-btn__label--custom.uni-btn--is-disabled:focus, .uni-btn__label--custom.uni-btn--disabled:focus,
    .uni-btn__label--custom--alt:disabled:focus,
    .uni-btn__label--custom--alt.uni-btn--is-disabled:focus,
    .uni-btn__label--custom--alt.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn__label--custom:disabled:focus, [class*='t-de'] .uni-btn__label--custom.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn__label--custom.uni-btn--disabled:focus, [class*='t-de']
      .uni-btn__label--custom--alt:disabled:focus, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn__label--custom:disabled:focus, [class*='t-le'] .uni-btn__label--custom.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn__label--custom.uni-btn--disabled:focus, [class*='t-le']
      .uni-btn__label--custom--alt:disabled:focus, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn__label--custom:disabled:focus, .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:focus, .uni-env--dark
      .uni-btn__label--custom--alt:disabled:focus, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn__label--custom:disabled:focus, .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:focus, .uni-env--light .uni-btn__label--custom.uni-btn--disabled:focus, .uni-env--light
      .uni-btn__label--custom--alt:disabled:focus, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:focus, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:focus, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--disabled:focus, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt:disabled:focus, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn__label--custom, [class*='t-de']
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-le'] .uni-btn__label--custom, [class*='t-le']
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--dark .uni-btn__label--custom, .uni-env--dark
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  .uni-env--light .uni-btn__label--custom, .uni-env--light
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom, [class*='uni-env--'] .uni-env--dark
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-btn__label--custom, [class*='uni-env--'] .uni-env--light
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-btn__label--custom--alt {
    background-color: rgba(157, 166, 174, 0.2); }
  [class*='t-de'] .uni-btn__label--custom, [class*='t-de']
  .uni-btn__label--custom--alt {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-btn__label--custom, [class*='t-le']
  .uni-btn__label--custom--alt {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn__label--custom, .uni-env--dark
  .uni-btn__label--custom--alt {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-btn__label--custom, .uni-env--light
  .uni-btn__label--custom--alt {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom, [class*='uni-env--'] .uni-env--dark
  .uni-btn__label--custom--alt {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-btn__label--custom, [class*='uni-env--'] .uni-env--light
  .uni-btn__label--custom--alt {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-btn__label--custom--alt {
    color: rgba(230, 242, 255, 0.8); }
  .uni-btn__label--custom:hover, .uni-btn__label--custom:focus,
  .uni-btn__label--custom--alt:hover,
  .uni-btn__label--custom--alt:focus {
    background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-de'] .uni-btn__label--custom:hover, [class*='t-de'] .uni-btn__label--custom:focus, [class*='t-de']
    .uni-btn__label--custom--alt:hover, [class*='t-de']
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='t-le'] .uni-btn__label--custom:hover, [class*='t-le'] .uni-btn__label--custom:focus, [class*='t-le']
    .uni-btn__label--custom--alt:hover, [class*='t-le']
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--dark .uni-btn__label--custom:hover, .uni-env--dark .uni-btn__label--custom:focus, .uni-env--dark
    .uni-btn__label--custom--alt:hover, .uni-env--dark
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    .uni-env--light .uni-btn__label--custom:hover, .uni-env--light .uni-btn__label--custom:focus, .uni-env--light
    .uni-btn__label--custom--alt:hover, .uni-env--light
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:hover, [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:focus, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:hover, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:hover, [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:focus, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt:hover, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:focus {
      background-color: rgba(193, 198, 203, 0.2); }
  .uni-btn__label--custom:active,
  .uni-btn__label--custom--alt:active {
    background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-de'] .uni-btn__label--custom:active, [class*='t-de']
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn__label--custom:active, [class*='t-le']
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn__label--custom:active, .uni-env--dark
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn__label--custom:active, .uni-env--light
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom:active, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn__label--custom:active, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom:active, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt:active {
      background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn__label--custom.uni-btn--is-spinning,
  .uni-btn__label--custom--alt.uni-btn--is-spinning {
    background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn__label--custom.uni-btn--is-spinning .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn__label--custom.uni-btn--is-spinning .uni-btn__content-container::before,
      .uni-btn__label--custom--alt.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid black;
        border-right: 0.225em solid rgba(0, 0, 0, 0.3);
        border-bottom: 0.225em solid rgba(0, 0, 0, 0.3);
        border-left: 0.225em solid rgba(0, 0, 0, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn__label--custom.uni-btn--is-spinning .uni-btn__content-container::before, .no-cssanimations
        .uni-btn__label--custom--alt.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn__label--custom.uni-btn--is-spinning .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn__label--custom.uni-btn--is-spinning .uni-btn__content-container::before,
      .uni-btn__label--custom--alt.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before,
      .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before,
      .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before,
      .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn__label--custom.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container,
    .uni-btn__label--custom--alt.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn__label--custom.uni-btn--is-spinning, [class*='t-de']
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='t-le'] .uni-btn__label--custom.uni-btn--is-spinning, [class*='t-le']
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning, .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-env--light .uni-btn__label--custom.uni-btn--is-spinning, .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning, [class*='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-spinning, [class*='uni-env--'] .uni-env--light
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-btn__label--custom--alt.uni-btn--is-spinning {
      background-color: rgba(122, 133, 144, 0.2); }
    .uni-btn__label--custom.uni-btn--is-spinning:hover,
    .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
      background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-de'] .uni-btn__label--custom.uni-btn--is-spinning:hover, [class*='t-de']
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='t-le'] .uni-btn__label--custom.uni-btn--is-spinning:hover, [class*='t-le']
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning:hover, .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      .uni-env--light .uni-btn__label--custom.uni-btn--is-spinning:hover, .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning:hover, [class*='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] .uni-env--light .uni-btn__label--custom.uni-btn--is-spinning:hover, [class*='uni-env--'] .uni-env--light
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn__label--custom.uni-btn--is-spinning:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-btn__label--custom--alt.uni-btn--is-spinning:hover {
        background-color: rgba(122, 133, 144, 0.2); }
  .uni-btn__label--custom > .uni-btn__content-container,
  .uni-btn__label--custom--alt > .uni-btn__content-container {
    transition: margin 0.2s linear; }
  .uni-btn__label--custom:hover,
  .uni-btn__label--custom--alt:hover {
    background-image: none; }

.uni-btn__input--radio,
.uni-btn__input--check {
  display: none; }
  .uni-btn__input--radio:checked + .uni-btn__label--custom,
  .uni-btn__input--check:checked + .uni-btn__label--custom {
    border-color: #0091d4;
    background-image: none;
    background-color: #009ce3;
    color: white; }
  .uni-btn__input--radio:checked + .uni-btn__label--custom--alt,
  .uni-btn__input--check:checked + .uni-btn__label--custom--alt {
    border-color: rgba(97, 109, 121, 0.8);
    background-image: none;
    background-color: rgba(104, 117, 129, 0.8);
    color: white; }

.uni-btn__drop-group {
  position: relative;
  display: inline-block; }

.uni-btn__drop--combo:after {
  content: "";
  display: table;
  clear: both; }

.uni-btn__drop--combo > [class*='uni-btn']:first-of-type {
  float: left;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.uni-btn__drop--combo > [class*='uni-btn']:last-of-type {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 1em;
  padding-right: 1em; }

.uni-dropdown__items {
  position: absolute;
  z-index: 100;
  display: none;
  min-width: 165px;
  padding: 1rem;
  margin-top: 2px;
  border: 1px solid #ff6300;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05); }
  .uni-dropdown--active .uni-dropdown__items {
    display: block; }

.uni-dropup {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

.icon--drop {
  display: inline-block;
  border-top: 0.375em solid currentColor;
  border-right: 0.25em solid transparent;
  border-left: 0.25em solid transparent;
  vertical-align: middle; }

.icon--drop-up {
  display: inline-block;
  border-right: 4px solid transparent;
  border-bottom: 6px solid currentColor;
  border-left: 4px solid transparent;
  vertical-align: middle; }

.uni-btn--is-success {
  background-color: #78a100;
  color: white; }
  .uni-btn--is-success .uni-btn__content-container {
    position: relative; }
    .uni-btn--is-success .uni-btn__content-container::before, .uni-btn--is-success .uni-btn__content-container::after {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 0;
      content: '';
      border: solid white;
      border-width: 0 0 0 0; }
    .uni-btn--is-success .uni-btn__content-container::before {
      width: 0.55em;
      border-width: 0 0.275em 0.275em 0;
      transform: rotate(35deg); }
    .uni-btn--is-success .uni-btn__content-container:after {
      position: static; }
  .uni-btn--is-success .uni-btn__content-container {
    margin-left: 1.438em; }
    .uni-btn--is-success .uni-btn__content-container::before, .uni-btn--is-success .uni-btn__content-container::after {
      left: -1.32rem; }
  .uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
    left: -0.88rem; }
  .uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
    left: -1.32rem; }
  .uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
    left: -1.76rem; }
  .uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
    left: 25%; }
  .uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
    left: 0; }
  .uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
    left: 33%; }
  [class*='t-de'] .uni-btn--is-success {
    background-color: #78a100; }
  [class*='t-le'] .uni-btn--is-success {
    background-color: #78a100; }
  .uni-env--dark .uni-btn--is-success {
    background-color: #78a100; }
  .uni-env--light .uni-btn--is-success {
    background-color: #78a100; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--is-success {
    background-color: #78a100; }
  [class*='uni-env--'] .uni-env--light .uni-btn--is-success {
    background-color: #78a100; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-success {
    background-color: #78a100; }
  [class*='t-de'] .uni-btn--is-success {
    color: white; }
  [class*='t-le'] .uni-btn--is-success {
    color: white; }
  .uni-env--dark .uni-btn--is-success {
    color: white; }
  .uni-env--light .uni-btn--is-success {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--is-success {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--is-success {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-success {
    color: white; }
  .uni-btn--is-success:hover {
    background-color: #78a100;
    color: white; }
    [class*='t-de'] .uni-btn--is-success:hover {
      background-color: #78a100; }
    [class*='t-le'] .uni-btn--is-success:hover {
      background-color: #78a100; }
    .uni-env--dark .uni-btn--is-success:hover {
      background-color: #78a100; }
    .uni-env--light .uni-btn--is-success:hover {
      background-color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--is-success:hover {
      background-color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--is-success:hover {
      background-color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-success:hover {
      background-color: #78a100; }
    [class*='t-de'] .uni-btn--is-success:hover {
      color: white; }
    [class*='t-le'] .uni-btn--is-success:hover {
      color: white; }
    .uni-env--dark .uni-btn--is-success:hover {
      color: white; }
    .uni-env--light .uni-btn--is-success:hover {
      color: white; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--is-success:hover {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--is-success:hover {
      color: white; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-success:hover {
      color: white; }

.uni-btn--is-failure {
  background-color: #e81c00;
  color: white; }
  .uni-btn--is-failure .uni-btn__content-container {
    position: relative; }
    .uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--is-failure .uni-btn__content-container::after {
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 0;
      content: '';
      border: solid white;
      border-width: 0 0 0 0; }
    .uni-btn--is-failure .uni-btn__content-container::before {
      transform: rotate(45deg);
      border-width: 0 0.275em 0 0; }
    .uni-btn--is-failure .uni-btn__content-container::after {
      transform: rotate(-45deg);
      border-width: 0 0.275em 0 0; }
  .uni-btn--is-failure .uni-btn__content-container {
    margin-left: 1.438em; }
    .uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--is-failure .uni-btn__content-container::after {
      left: -1.32rem; }
  .uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
    left: -0.88rem; }
  .uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
    left: -1.32rem; }
  .uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
    left: -1.76rem; }
  .uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
    left: 25%; }
  .uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
    left: 0; }
  .uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
    left: 33%; }
  [class*='t-de'] .uni-btn--is-failure {
    background-color: #e81c00; }
  [class*='t-le'] .uni-btn--is-failure {
    background-color: #e81c00; }
  .uni-env--dark .uni-btn--is-failure {
    background-color: #e81c00; }
  .uni-env--light .uni-btn--is-failure {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--is-failure {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--light .uni-btn--is-failure {
    background-color: #e81c00; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-failure {
    background-color: #e81c00; }
  [class*='t-de'] .uni-btn--is-failure {
    color: white; }
  [class*='t-le'] .uni-btn--is-failure {
    color: white; }
  .uni-env--dark .uni-btn--is-failure {
    color: white; }
  .uni-env--light .uni-btn--is-failure {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--is-failure {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--is-failure {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-failure {
    color: white; }
  .uni-btn--is-failure:hover {
    background-color: #e81c00;
    color: white; }
    [class*='t-de'] .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    [class*='t-le'] .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    .uni-env--dark .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    .uni-env--light .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-failure:hover {
      background-color: #e81c00; }
    [class*='t-de'] .uni-btn--is-failure:hover {
      color: white; }
    [class*='t-le'] .uni-btn--is-failure:hover {
      color: white; }
    .uni-env--dark .uni-btn--is-failure:hover {
      color: white; }
    .uni-env--light .uni-btn--is-failure:hover {
      color: white; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--is-failure:hover {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--is-failure:hover {
      color: white; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--is-failure:hover {
      color: white; }

.uni-form__section + .uni-form__section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: rgba(165, 172, 180, 0.6); }
  [class*='t-de'] .uni-form__section + .uni-form__section {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__section + .uni-form__section {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__section + .uni-form__section {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__section + .uni-form__section {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__section + .uni-form__section {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__section + .uni-form__section {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__section + .uni-form__section {
    border-color: rgba(104, 117, 129, 0.6); }

.uni-form__section-header {
  color: #13293f;
  display: block;
  margin-bottom: 1em;
  font-family: helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 1rem; }
  [class*='t-de'] .uni-form__section-header {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__section-header {
    color: #13293f; }
  .uni-env--dark .uni-form__section-header {
    color: #e6f2ff; }
  .uni-env--light .uni-form__section-header {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__section-header {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__section-header {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__section-header {
    color: #e6f2ff; }

.uni-form__item {
  position: relative; }
  .uni-form__item:disabled, .uni-form__item.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__item {
    cursor: default; }
    [class*='t-de'] .uni-form__item:disabled, [class*='t-de'] .uni-form__item.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__item:disabled, [class*='t-le'] .uni-form__item.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.2; }
    .uni-env--dark .uni-form__item:disabled, .uni-env--dark .uni-form__item.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.25; }
    .uni-env--light .uni-form__item:disabled, .uni-env--light .uni-form__item.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__item:disabled, [class*='uni-env--'] .uni-env--light .uni-form__item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__item {
      opacity: 0.25; }
  .uni-form__item + .uni-form__item,
  .uni-form__item + .uni-form__check-item,
  .uni-form__item + .uni-form__section {
    margin-top: 2rem; }
    .uni-form--compact .uni-form__item + .uni-form__item, .uni-form--compact
    .uni-form__item + .uni-form__check-item, .uni-form--compact
    .uni-form__item + .uni-form__section {
      margin-top: 1rem; }
  .uni-form__item + .uni-form__item.uni-form__item--has-divider {
    margin-top: 1.5rem; }
    .uni-form--compact .uni-form__item + .uni-form__item.uni-form__item--has-divider {
      margin-top: 1rem;
      padding-top: 1rem; }

.uni-form__item.uni-form__item--has-divider {
  border-color: rgba(165, 172, 180, 0.6);
  padding-top: 1.5rem;
  border-width: 1px 0 0 0;
  border-style: solid; }
  [class*='t-de'] .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item.uni-form__item--has-divider {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-form--compact .uni-form__item.uni-form__item--has-divider {
    padding-top: 1rem; }

.uni-form__label {
  color: rgba(19, 41, 63, 0.8);
  font-size: 1rem;
  border-width: 1px;
  display: block;
  margin-bottom: 0.25rem;
  letter-spacing: 0.2px; }
  [class*='t-de'] .uni-form__label {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-form__label {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-form__label {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-form__label {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-form__label {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-form__label {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__label {
    color: rgba(230, 242, 255, 0.8); }
  .uni-form--small .uni-form__label {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form--medium .uni-form__label {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form--large .uni-form__label {
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__label.uni-form__label--small {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__label.uni-form__label--medium {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__label.uni-form__label--large {
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__label:disabled, .uni-form__label.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__label {
    cursor: default; }
    [class*='t-de'] .uni-form__label:disabled, [class*='t-de'] .uni-form__label.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__label:disabled, [class*='t-le'] .uni-form__label.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.2; }
    .uni-env--dark .uni-form__label:disabled, .uni-env--dark .uni-form__label.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.25; }
    .uni-env--light .uni-form__label:disabled, .uni-env--light .uni-form__label.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__label:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__label.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__label:disabled, [class*='uni-env--'] .uni-env--light .uni-form__label.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__label:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__label.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__label {
      opacity: 0.25; }
  .uni-form--compact .uni-form__label {
    margin-bottom: 0.125rem; }

.uni-form__help-message {
  color: rgba(19, 41, 63, 0.4);
  font-size: 0.75rem;
  border-width: 1px;
  margin-top: 4px;
  line-height: 1.16667; }
  [class*='t-de'] .uni-form__help-message {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-form__help-message {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-form__help-message {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-form__help-message {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-form__help-message {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-form__help-message {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__help-message {
    color: rgba(230, 242, 255, 0.4); }
  .uni-form--small .uni-form__help-message {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form--medium .uni-form__help-message {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form--large .uni-form__help-message {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form__help-message.uni-form__help-message--small {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form__help-message.uni-form__help-message--medium {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form__help-message.uni-form__help-message--large {
    font-size: 0.75rem;
    border-width: 1px; }
  .uni-form__help-message:disabled, .uni-form__help-message.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__help-message {
    cursor: default; }
    [class*='t-de'] .uni-form__help-message:disabled, [class*='t-de'] .uni-form__help-message.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__help-message:disabled, [class*='t-le'] .uni-form__help-message.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.2; }
    .uni-env--dark .uni-form__help-message:disabled, .uni-env--dark .uni-form__help-message.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.25; }
    .uni-env--light .uni-form__help-message:disabled, .uni-env--light .uni-form__help-message.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__help-message:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__help-message.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__help-message:disabled, [class*='uni-env--'] .uni-env--light .uni-form__help-message.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__help-message:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__help-message.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__help-message {
      opacity: 0.25; }

.uni-form__required {
  color: rgba(19, 41, 63, 0.4);
  position: relative;
  top: -1px;
  margin-left: 2px;
  font-size: 0.75em; }
  [class*='t-de'] .uni-form__required {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-form__required {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-form__required {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-form__required {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-form__required {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-form__required {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__required {
    color: rgba(230, 242, 255, 0.4); }

.uni-form__control {
  display: flex; }
  .uni-form__control > .uni-form__input {
    flex: 1; }

.uni-form__input {
  color: #13293f;
  border-color: rgba(165, 172, 180, 0.6);
  background-color: transparent;
  padding: 0.625em 1em;
  font-size: 1rem;
  border-width: 1px;
  width: 100%;
  border-style: solid;
  box-shadow: none;
  border-radius: 0.3125em;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none; }
  [class*='t-de'] .uni-form__input {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__input {
    color: #13293f; }
  .uni-env--dark .uni-form__input {
    color: #e6f2ff; }
  .uni-env--light .uni-form__input {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__input {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__input {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input {
    color: #e6f2ff; }
  [class*='t-de'] .uni-form__input {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__input {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__input {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__input {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__input {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__input {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-de'] .uni-form__input {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='t-le'] .uni-form__input {
    background-color: transparent; }
  .uni-env--dark .uni-form__input {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-env--light .uni-form__input {
    background-color: transparent; }
  [class*='uni-env--'] .uni-env--dark .uni-form__input {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-form__input {
    background-color: transparent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-form__input:disabled, .uni-form__input.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__input {
    cursor: default; }
    [class*='t-de'] .uni-form__input:disabled, [class*='t-de'] .uni-form__input.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__input:disabled, [class*='t-le'] .uni-form__input.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.2; }
    .uni-env--dark .uni-form__input:disabled, .uni-env--dark .uni-form__input.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.25; }
    .uni-env--light .uni-form__input:disabled, .uni-env--light .uni-form__input.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__input.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__input:disabled, [class*='uni-env--'] .uni-env--light .uni-form__input.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input {
      opacity: 0.25; }
  .uni-form--small .uni-form__input {
    font-size: 0.875rem;
    padding: 0.429em 1em;
    border-width: 1px; }
  .uni-form--medium .uni-form__input {
    font-size: 1rem;
    padding: 0.625em 1em;
    border-width: 1px; }
  .uni-form--large .uni-form__input {
    font-size: 1.125rem;
    padding: 0.8615em 1em;
    border-width: 1px; }
  .uni-form__input.uni-form__input--small {
    padding: 0.429em 1em;
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__input.uni-form__input--medium {
    padding: 0.625em 1em;
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__input.uni-form__input--large {
    padding: 0.8615em 1em;
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__input:focus {
    outline: 0;
    border-color: #4e5d6c; }
    [class*='t-de'] .uni-form__input:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='t-le'] .uni-form__input:focus {
      border-color: #4e5d6c; }
    .uni-env--dark .uni-form__input:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    .uni-env--light .uni-form__input:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='uni-env--'] .uni-env--light .uni-form__input:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input:focus {
      border-color: rgba(230, 242, 255, 0.8); }
  .uni-form__input::-moz-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__input::-webkit-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__input::-ms-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__item--is-error .uni-form__input::-moz-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-moz-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-webkit-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input::-ms-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input {
    color: #e81c00;
    border-color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input {
      color: #ff523d; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input {
      border-color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input {
      border-color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input {
      border-color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__input {
      border-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input {
      border-color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input {
      border-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input {
      border-color: #ff4b33; }

.uni-form__input--file {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }
  .uni-form--compact .uni-form__input--file {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }

.uni-form__input--date,
.uni-form__input--time {
  color: #13293f;
  border-color: rgba(165, 172, 180, 0.6);
  background-color: transparent;
  padding: 0.625em 1em;
  font-size: 1rem;
  border-width: 1px;
  width: 100%;
  border-style: solid;
  box-shadow: none;
  border-radius: 0.3125em;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2.5rem;
  height: 2.5rem; }
  [class*='t-de'] .uni-form__input--date, [class*='t-de']
  .uni-form__input--time {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__input--date, [class*='t-le']
  .uni-form__input--time {
    color: #13293f; }
  .uni-env--dark .uni-form__input--date, .uni-env--dark
  .uni-form__input--time {
    color: #e6f2ff; }
  .uni-env--light .uni-form__input--date, .uni-env--light
  .uni-form__input--time {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__input--date, [class*='uni-env--'] .uni-env--light
  .uni-form__input--time {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    color: #e6f2ff; }
  [class*='t-de'] .uni-form__input--date, [class*='t-de']
  .uni-form__input--time {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__input--date, [class*='t-le']
  .uni-form__input--time {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__input--date, .uni-env--dark
  .uni-form__input--time {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__input--date, .uni-env--light
  .uni-form__input--time {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__input--date, [class*='uni-env--'] .uni-env--light
  .uni-form__input--time {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-de'] .uni-form__input--date, [class*='t-de']
  .uni-form__input--time {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='t-le'] .uni-form__input--date, [class*='t-le']
  .uni-form__input--time {
    background-color: transparent; }
  .uni-env--dark .uni-form__input--date, .uni-env--dark
  .uni-form__input--time {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-env--light .uni-form__input--date, .uni-env--light
  .uni-form__input--time {
    background-color: transparent; }
  [class*='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-form__input--date, [class*='uni-env--'] .uni-env--light
  .uni-form__input--time {
    background-color: transparent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-form__input--time {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-form__input--date:disabled, .uni-form__input--date.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__input--date,
  .uni-form__input--time:disabled,
  .uni-form__input--time.uni-form__input--is-disabled,
  .uni-form__item--is-disabled
  .uni-form__input--time {
    cursor: default; }
    [class*='t-de'] .uni-form__input--date:disabled, [class*='t-de'] .uni-form__input--date.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__input--date, [class*='t-de']
    .uni-form__input--time:disabled, [class*='t-de']
    .uni-form__input--time.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__input--date:disabled, [class*='t-le'] .uni-form__input--date.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__input--date, [class*='t-le']
    .uni-form__input--time:disabled, [class*='t-le']
    .uni-form__input--time.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.2; }
    .uni-env--dark .uni-form__input--date:disabled, .uni-env--dark .uni-form__input--date.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input--date, .uni-env--dark
    .uni-form__input--time:disabled, .uni-env--dark
    .uni-form__input--time.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.25; }
    .uni-env--light .uni-form__input--date:disabled, .uni-env--light .uni-form__input--date.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__input--date, .uni-env--light
    .uni-form__input--time:disabled, .uni-env--light
    .uni-form__input--time.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--date:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__input--date.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input--date, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time:disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__input--date:disabled, [class*='uni-env--'] .uni-env--light .uni-form__input--date.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__input--date, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time:disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__input--time {
      opacity: 0.25; }
  .uni-form--small .uni-form__input--date, .uni-form--small
  .uni-form__input--time {
    font-size: 0.875rem;
    padding: 0.429em 1em;
    border-width: 1px; }
  .uni-form--medium .uni-form__input--date, .uni-form--medium
  .uni-form__input--time {
    font-size: 1rem;
    padding: 0.625em 1em;
    border-width: 1px; }
  .uni-form--large .uni-form__input--date, .uni-form--large
  .uni-form__input--time {
    font-size: 1.125rem;
    padding: 0.8615em 1em;
    border-width: 1px; }
  .uni-form__input--date.uni-form__input--small,
  .uni-form__input--time.uni-form__input--small {
    padding: 0.429em 1em;
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__input--date.uni-form__input--medium,
  .uni-form__input--time.uni-form__input--medium {
    padding: 0.625em 1em;
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__input--date.uni-form__input--large,
  .uni-form__input--time.uni-form__input--large {
    padding: 0.8615em 1em;
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__input--date:focus,
  .uni-form__input--time:focus {
    outline: 0;
    border-color: #4e5d6c; }
    [class*='t-de'] .uni-form__input--date:focus, [class*='t-de']
    .uni-form__input--time:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='t-le'] .uni-form__input--date:focus, [class*='t-le']
    .uni-form__input--time:focus {
      border-color: #4e5d6c; }
    .uni-env--dark .uni-form__input--date:focus, .uni-env--dark
    .uni-form__input--time:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    .uni-env--light .uni-form__input--date:focus, .uni-env--light
    .uni-form__input--time:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--date:focus, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='uni-env--'] .uni-env--light .uni-form__input--date:focus, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time:focus {
      border-color: rgba(230, 242, 255, 0.8); }
  .uni-form__input--date::-moz-placeholder,
  .uni-form__input--time::-moz-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input--date::-moz-placeholder, [class*='t-de']
    .uni-form__input--time::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input--date::-moz-placeholder, [class*='t-le']
    .uni-form__input--time::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input--date::-moz-placeholder, .uni-env--dark
    .uni-form__input--time::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input--date::-moz-placeholder, .uni-env--light
    .uni-form__input--time::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__input--date::-webkit-input-placeholder,
  .uni-form__input--time::-webkit-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input--date::-webkit-input-placeholder, [class*='t-de']
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input--date::-webkit-input-placeholder, [class*='t-le']
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input--date::-webkit-input-placeholder, .uni-env--dark
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input--date::-webkit-input-placeholder, .uni-env--light
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__input--date::-ms-input-placeholder,
  .uni-form__input--time::-ms-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__input--date::-ms-input-placeholder, [class*='t-de']
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__input--date::-ms-input-placeholder, [class*='t-le']
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__input--date::-ms-input-placeholder, .uni-env--dark
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__input--date::-ms-input-placeholder, .uni-env--light
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] .uni-env--light
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--time::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, .uni-form__item--is-error
  .uni-form__input--time::-moz-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, [class*='t-de'] .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, [class*='t-le'] .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-moz-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-moz-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, .uni-form__item--is-error
  .uni-form__input--time::-webkit-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, [class*='t-de'] .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, [class*='t-le'] .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-webkit-input-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-webkit-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, .uni-form__item--is-error
  .uni-form__input--time::-ms-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, [class*='t-de'] .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, [class*='t-le'] .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date::-ms-input-placeholder, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time::-ms-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__input--date, .uni-form__item--is-error
  .uni-form__input--time {
    color: #e81c00;
    border-color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input--date, [class*='t-de'] .uni-form__item--is-error
    .uni-form__input--time {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input--date, [class*='t-le'] .uni-form__item--is-error
    .uni-form__input--time {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input--date, .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__input--date, .uni-env--light .uni-form__item--is-error
    .uni-form__input--time {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__input--time {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      color: #ff523d; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__input--date, [class*='t-de'] .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__input--date, [class*='t-le'] .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__input--date, .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__input--date, .uni-env--light .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--date, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__input--time {
      border-color: #ff4b33; }
  .uni-form--small .uni-form__input--date, .uni-form--small
  .uni-form__input--time {
    padding-top: 0;
    padding-bottom: 0;
    height: 1.875rem;
    line-height: 1.875rem; }
  .uni-form--medium .uni-form__input--date, .uni-form--medium
  .uni-form__input--time {
    padding-top: 0;
    padding-bottom: 0;
    height: 2.5rem;
    line-height: 2.5rem; }
  .uni-form--large .uni-form__input--date, .uni-form--large
  .uni-form__input--time {
    padding-top: 0;
    padding-bottom: 0;
    height: 3.375rem;
    line-height: 3.375rem; }

.uni-form__textarea {
  color: #13293f;
  border-color: rgba(165, 172, 180, 0.6);
  background-color: transparent;
  padding: 0.625em 1em;
  font-size: 1rem;
  border-width: 1px;
  width: 100%;
  border-style: solid;
  box-shadow: none;
  border-radius: 0.3125em;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  min-height: 200px; }
  [class*='t-de'] .uni-form__textarea {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__textarea {
    color: #13293f; }
  .uni-env--dark .uni-form__textarea {
    color: #e6f2ff; }
  .uni-env--light .uni-form__textarea {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__textarea {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__textarea {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea {
    color: #e6f2ff; }
  [class*='t-de'] .uni-form__textarea {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__textarea {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__textarea {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__textarea {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__textarea {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__textarea {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-de'] .uni-form__textarea {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='t-le'] .uni-form__textarea {
    background-color: transparent; }
  .uni-env--dark .uni-form__textarea {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-env--light .uni-form__textarea {
    background-color: transparent; }
  [class*='uni-env--'] .uni-env--dark .uni-form__textarea {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-form__textarea {
    background-color: transparent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-form__textarea:disabled, .uni-form__textarea.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__textarea {
    cursor: default; }
    [class*='t-de'] .uni-form__textarea:disabled, [class*='t-de'] .uni-form__textarea.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__textarea:disabled, [class*='t-le'] .uni-form__textarea.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.2; }
    .uni-env--dark .uni-form__textarea:disabled, .uni-env--dark .uni-form__textarea.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.25; }
    .uni-env--light .uni-form__textarea:disabled, .uni-env--light .uni-form__textarea.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__textarea:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__textarea.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__textarea:disabled, [class*='uni-env--'] .uni-env--light .uni-form__textarea.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__textarea {
      opacity: 0.25; }
  .uni-form--small .uni-form__textarea {
    font-size: 0.875rem;
    padding: 0.429em 1em;
    border-width: 1px; }
  .uni-form--medium .uni-form__textarea {
    font-size: 1rem;
    padding: 0.625em 1em;
    border-width: 1px; }
  .uni-form--large .uni-form__textarea {
    font-size: 1.125rem;
    padding: 0.8615em 1em;
    border-width: 1px; }
  .uni-form__textarea.uni-form__input--small {
    padding: 0.429em 1em;
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__textarea.uni-form__input--medium {
    padding: 0.625em 1em;
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__textarea.uni-form__input--large {
    padding: 0.8615em 1em;
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__textarea:focus {
    outline: 0;
    border-color: #4e5d6c; }
    [class*='t-de'] .uni-form__textarea:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='t-le'] .uni-form__textarea:focus {
      border-color: #4e5d6c; }
    .uni-env--dark .uni-form__textarea:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    .uni-env--light .uni-form__textarea:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] .uni-env--dark .uni-form__textarea:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='uni-env--'] .uni-env--light .uni-form__textarea:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea:focus {
      border-color: rgba(230, 242, 255, 0.8); }
  .uni-form__textarea::-moz-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__textarea::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__textarea::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__textarea::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__textarea::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__textarea::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__textarea::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__textarea::-webkit-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__textarea::-ms-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__textarea::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__textarea::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__textarea::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__textarea::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__textarea::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__textarea::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__textarea::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-moz-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-webkit-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea::-ms-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__textarea {
    color: #e81c00;
    border-color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__textarea {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__textarea {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__textarea {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__textarea {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      color: #ff523d; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__textarea {
      border-color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__textarea {
      border-color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      border-color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__textarea {
      border-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      border-color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__textarea {
      border-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__textarea {
      border-color: #ff4b33; }

.uni-form__control--select {
  background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(165, 172, 180, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  position: relative;
  background-repeat: no-repeat;
  background-size: 1.25em;
  background-position: 98% 50%; }
  [class*='t-de'] .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(104, 117, 129, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  [class*='t-le'] .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(165, 172, 180, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  .uni-env--dark .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(104, 117, 129, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  .uni-env--light .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(165, 172, 180, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  [class*='uni-env--'] .uni-env--dark .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(104, 117, 129, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  [class*='uni-env--'] .uni-env--light .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(165, 172, 180, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__control--select {
    background-image: url("data:image/svg+xml,%3Csvg%20class%3D%22svg-test-svg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3Edropdown%3C%2Ftitle%3E%3Cg%20fill%3D%22rgba(104, 117, 129, 0.6)%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M4.625%208L2%2010.625l14%2014%2014-14L27.375%208%2016%2019.375%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

.uni-form__select {
  color: #13293f;
  border-color: rgba(165, 172, 180, 0.6);
  background-color: transparent;
  padding: 0.625em 1em;
  font-size: 1rem;
  border-width: 1px;
  width: 100%;
  border-style: solid;
  box-shadow: none;
  border-radius: 0.3125em;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 2.5rem;
  height: 2.5rem;
  cursor: pointer; }
  [class*='t-de'] .uni-form__select {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__select {
    color: #13293f; }
  .uni-env--dark .uni-form__select {
    color: #e6f2ff; }
  .uni-env--light .uni-form__select {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__select {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__select {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select {
    color: #e6f2ff; }
  [class*='t-de'] .uni-form__select {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__select {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__select {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__select {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__select {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__select {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-de'] .uni-form__select {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='t-le'] .uni-form__select {
    background-color: transparent; }
  .uni-env--dark .uni-form__select {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-env--light .uni-form__select {
    background-color: transparent; }
  [class*='uni-env--'] .uni-env--dark .uni-form__select {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-form__select {
    background-color: transparent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-form__select:disabled, .uni-form__select.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__select {
    cursor: default; }
    [class*='t-de'] .uni-form__select:disabled, [class*='t-de'] .uni-form__select.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__select:disabled, [class*='t-le'] .uni-form__select.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.2; }
    .uni-env--dark .uni-form__select:disabled, .uni-env--dark .uni-form__select.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.25; }
    .uni-env--light .uni-form__select:disabled, .uni-env--light .uni-form__select.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__select:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__select.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__select:disabled, [class*='uni-env--'] .uni-env--light .uni-form__select.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__select {
      opacity: 0.25; }
  .uni-form--small .uni-form__select {
    font-size: 0.875rem;
    padding: 0.429em 1em;
    border-width: 1px; }
  .uni-form--medium .uni-form__select {
    font-size: 1rem;
    padding: 0.625em 1em;
    border-width: 1px; }
  .uni-form--large .uni-form__select {
    font-size: 1.125rem;
    padding: 0.8615em 1em;
    border-width: 1px; }
  .uni-form__select.uni-form__input--small {
    padding: 0.429em 1em;
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__select.uni-form__input--medium {
    padding: 0.625em 1em;
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__select.uni-form__input--large {
    padding: 0.8615em 1em;
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__select:focus {
    outline: 0;
    border-color: #4e5d6c; }
    [class*='t-de'] .uni-form__select:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='t-le'] .uni-form__select:focus {
      border-color: #4e5d6c; }
    .uni-env--dark .uni-form__select:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    .uni-env--light .uni-form__select:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] .uni-env--dark .uni-form__select:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='uni-env--'] .uni-env--light .uni-form__select:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select:focus {
      border-color: rgba(230, 242, 255, 0.8); }
  .uni-form__select::-moz-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__select::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__select::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__select::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__select::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__select::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__select::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__select::-webkit-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__select::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__select::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__select::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__select::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__select::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__select::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__select::-ms-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__select::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__select::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__select::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__select::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__select::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__select::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__select::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__item--is-error .uni-form__select::-moz-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-moz-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-webkit-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select::-ms-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__select {
    color: #e81c00;
    border-color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__select {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__select {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__select {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__select {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__select {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select {
      color: #ff523d; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__select {
      border-color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__select {
      border-color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__select {
      border-color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__select {
      border-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select {
      border-color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__select {
      border-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__select {
      border-color: #ff4b33; }
  .uni-form--small .uni-form__select {
    padding-top: 0;
    padding-bottom: 0;
    height: 1.875rem;
    line-height: 1.875rem; }
  .uni-form--medium .uni-form__select {
    padding-top: 0;
    padding-bottom: 0;
    height: 2.5rem;
    line-height: 2.5rem; }
  .uni-form--large .uni-form__select {
    padding-top: 0;
    padding-bottom: 0;
    height: 3.375rem;
    line-height: 3.375rem; }
  .uni-form__select::-ms-expand {
    display: none; }

.uni-form__multiselect {
  color: #13293f;
  border-color: rgba(165, 172, 180, 0.6);
  background-color: transparent;
  padding: 0.625em 1em;
  font-size: 1rem;
  border-width: 1px;
  width: 100%;
  border-style: solid;
  box-shadow: none;
  border-radius: 0.3125em;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: scroll; }
  [class*='t-de'] .uni-form__multiselect {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__multiselect {
    color: #13293f; }
  .uni-env--dark .uni-form__multiselect {
    color: #e6f2ff; }
  .uni-env--light .uni-form__multiselect {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__multiselect {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__multiselect {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect {
    color: #e6f2ff; }
  [class*='t-de'] .uni-form__multiselect {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__multiselect {
    border-color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__multiselect {
    border-color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__multiselect {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__multiselect {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__multiselect {
    border-color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect {
    border-color: rgba(104, 117, 129, 0.6); }
  [class*='t-de'] .uni-form__multiselect {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='t-le'] .uni-form__multiselect {
    background-color: transparent; }
  .uni-env--dark .uni-form__multiselect {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-env--light .uni-form__multiselect {
    background-color: transparent; }
  [class*='uni-env--'] .uni-env--dark .uni-form__multiselect {
    background-color: rgba(0, 0, 0, 0.2); }
  [class*='uni-env--'] .uni-env--light .uni-form__multiselect {
    background-color: transparent; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect {
    background-color: rgba(0, 0, 0, 0.2); }
  .uni-form__multiselect:disabled, .uni-form__multiselect.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__multiselect {
    cursor: default; }
    [class*='t-de'] .uni-form__multiselect:disabled, [class*='t-de'] .uni-form__multiselect.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__multiselect:disabled, [class*='t-le'] .uni-form__multiselect.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.2; }
    .uni-env--dark .uni-form__multiselect:disabled, .uni-env--dark .uni-form__multiselect.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.25; }
    .uni-env--light .uni-form__multiselect:disabled, .uni-env--light .uni-form__multiselect.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__multiselect:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__multiselect.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__multiselect:disabled, [class*='uni-env--'] .uni-env--light .uni-form__multiselect.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__multiselect {
      opacity: 0.25; }
  .uni-form--small .uni-form__multiselect {
    font-size: 0.875rem;
    padding: 0.429em 1em;
    border-width: 1px; }
  .uni-form--medium .uni-form__multiselect {
    font-size: 1rem;
    padding: 0.625em 1em;
    border-width: 1px; }
  .uni-form--large .uni-form__multiselect {
    font-size: 1.125rem;
    padding: 0.8615em 1em;
    border-width: 1px; }
  .uni-form__multiselect.uni-form__input--small {
    padding: 0.429em 1em;
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__multiselect.uni-form__input--medium {
    padding: 0.625em 1em;
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__multiselect.uni-form__input--large {
    padding: 0.8615em 1em;
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__multiselect:focus {
    outline: 0;
    border-color: #4e5d6c; }
    [class*='t-de'] .uni-form__multiselect:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='t-le'] .uni-form__multiselect:focus {
      border-color: #4e5d6c; }
    .uni-env--dark .uni-form__multiselect:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    .uni-env--light .uni-form__multiselect:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] .uni-env--dark .uni-form__multiselect:focus {
      border-color: rgba(230, 242, 255, 0.8); }
    [class*='uni-env--'] .uni-env--light .uni-form__multiselect:focus {
      border-color: #4e5d6c; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect:focus {
      border-color: rgba(230, 242, 255, 0.8); }
  .uni-form__multiselect::-moz-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__multiselect::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__multiselect::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__multiselect::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__multiselect::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__multiselect::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__multiselect::-moz-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect::-moz-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__multiselect::-webkit-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect::-webkit-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__multiselect::-ms-input-placeholder {
    color: rgba(19, 41, 63, 0.4); }
    [class*='t-de'] .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='t-le'] .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    .uni-env--dark .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    .uni-env--light .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] .uni-env--dark .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
    [class*='uni-env--'] .uni-env--light .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(19, 41, 63, 0.4); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__multiselect::-ms-input-placeholder {
      color: rgba(230, 242, 255, 0.4); }
  .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-moz-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-webkit-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect::-ms-input-placeholder {
      color: #ff523d; }
  .uni-form__item--is-error .uni-form__multiselect {
    color: #e81c00;
    border-color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__multiselect {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__multiselect {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__multiselect {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__multiselect {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      color: #ff523d; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__multiselect {
      border-color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__multiselect {
      border-color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      border-color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__multiselect {
      border-color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      border-color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__multiselect {
      border-color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__multiselect {
      border-color: #ff4b33; }

.uni-form__option {
  width: 100%; }

.uni-form__option--multiselect {
  padding: 0.25em 5px; }
  .uni-form__option--multiselect:hover {
    background-color: #f2f5f7; }
    [class*='t-de'] .uni-form__option--multiselect:hover {
      background-color: #101417; }
    [class*='t-le'] .uni-form__option--multiselect:hover {
      background-color: #f2f5f7; }
    .uni-env--dark .uni-form__option--multiselect:hover {
      background-color: #101417; }
    .uni-env--light .uni-form__option--multiselect:hover {
      background-color: #f2f5f7; }
    [class*='uni-env--'] .uni-env--dark .uni-form__option--multiselect:hover {
      background-color: #101417; }
    [class*='uni-env--'] .uni-env--light .uni-form__option--multiselect:hover {
      background-color: #f2f5f7; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__option--multiselect:hover {
      background-color: #101417; }

.uni-form__check-header,
.uni-form__radio-header {
  color: #13293f;
  font-size: 1rem;
  border-width: 1px;
  display: block;
  margin-bottom: 1em; }
  [class*='t-de'] .uni-form__check-header, [class*='t-de']
  .uni-form__radio-header {
    color: #e6f2ff; }
  [class*='t-le'] .uni-form__check-header, [class*='t-le']
  .uni-form__radio-header {
    color: #13293f; }
  .uni-env--dark .uni-form__check-header, .uni-env--dark
  .uni-form__radio-header {
    color: #e6f2ff; }
  .uni-env--light .uni-form__check-header, .uni-env--light
  .uni-form__radio-header {
    color: #13293f; }
  [class*='uni-env--'] .uni-env--dark .uni-form__check-header, [class*='uni-env--'] .uni-env--dark
  .uni-form__radio-header {
    color: #e6f2ff; }
  [class*='uni-env--'] .uni-env--light .uni-form__check-header, [class*='uni-env--'] .uni-env--light
  .uni-form__radio-header {
    color: #13293f; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__check-header, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-form__radio-header {
    color: #e6f2ff; }
  .uni-form--small .uni-form__check-header, .uni-form--small
  .uni-form__radio-header {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form--medium .uni-form__check-header, .uni-form--medium
  .uni-form__radio-header {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form--large .uni-form__check-header, .uni-form--large
  .uni-form__radio-header {
    font-size: 1.125rem;
    border-width: 1px; }
  .uni-form__check-header.uni-form__check-header--small,
  .uni-form__radio-header.uni-form__check-header--small {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__check-header.uni-form__check-header--medium,
  .uni-form__radio-header.uni-form__check-header--medium {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__check-header.uni-form__check-header--large,
  .uni-form__radio-header.uni-form__check-header--large {
    font-size: 1.125rem;
    border-width: 1px; }

.uni-form__check-item,
.uni-form__radio-item {
  position: relative;
  line-height: 1.15;
  padding-left: 1.5em; }
  .uni-form__check-item:after,
  .uni-form__radio-item:after {
    content: "";
    display: table;
    clear: both; }
  .uni-form__check-item:disabled, .uni-form__check-item.uni-form__input--is-disabled,
  .uni-form__item--is-disabled .uni-form__check-item,
  .uni-form__radio-item:disabled,
  .uni-form__radio-item.uni-form__input--is-disabled,
  .uni-form__item--is-disabled
  .uni-form__radio-item {
    cursor: default; }
    [class*='t-de'] .uni-form__check-item:disabled, [class*='t-de'] .uni-form__check-item.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled .uni-form__check-item, [class*='t-de']
    .uni-form__radio-item:disabled, [class*='t-de']
    .uni-form__radio-item.uni-form__input--is-disabled, [class*='t-de']
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.25; }
    [class*='t-le'] .uni-form__check-item:disabled, [class*='t-le'] .uni-form__check-item.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled .uni-form__check-item, [class*='t-le']
    .uni-form__radio-item:disabled, [class*='t-le']
    .uni-form__radio-item.uni-form__input--is-disabled, [class*='t-le']
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.2; }
    .uni-env--dark .uni-form__check-item:disabled, .uni-env--dark .uni-form__check-item.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled .uni-form__check-item, .uni-env--dark
    .uni-form__radio-item:disabled, .uni-env--dark
    .uni-form__radio-item.uni-form__input--is-disabled, .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.25; }
    .uni-env--light .uni-form__check-item:disabled, .uni-env--light .uni-form__check-item.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled .uni-form__check-item, .uni-env--light
    .uni-form__radio-item:disabled, .uni-env--light
    .uni-form__radio-item.uni-form__input--is-disabled, .uni-env--light
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.2; }
    [class*='uni-env--'] .uni-env--dark .uni-form__check-item:disabled, [class*='uni-env--'] .uni-env--dark .uni-form__check-item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__check-item, [class*='uni-env--'] .uni-env--dark
    .uni-form__radio-item:disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__radio-item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.25; }
    [class*='uni-env--'] .uni-env--light .uni-form__check-item:disabled, [class*='uni-env--'] .uni-env--light .uni-form__check-item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled .uni-form__check-item, [class*='uni-env--'] .uni-env--light
    .uni-form__radio-item:disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__radio-item.uni-form__input--is-disabled, [class*='uni-env--'] .uni-env--light
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.2; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__check-item:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__check-item.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled .uni-form__check-item, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__radio-item:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__radio-item.uni-form__input--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__item--is-disabled
    .uni-form__radio-item {
      opacity: 0.25; }
  .uni-form__check-item + .uni-form__check-item,
  .uni-form__radio-item + .uni-form__check-item, .uni-form__check-item +
  .uni-form__radio-item,
  .uni-form__radio-item +
  .uni-form__radio-item {
    margin-top: 1rem; }
    .uni-form--compact .uni-form__check-item + .uni-form__check-item, .uni-form--compact
    .uni-form__radio-item + .uni-form__check-item, .uni-form--compact .uni-form__check-item +
    .uni-form__radio-item, .uni-form--compact
    .uni-form__radio-item +
    .uni-form__radio-item {
      margin-top: 0.5rem; }
  .uni-form__check-item + .uni-form__item,
  .uni-form__check-item + .uni-form__section,
  .uni-form__radio-item + .uni-form__item,
  .uni-form__radio-item + .uni-form__section {
    margin-top: 2rem; }
    .uni-form--compact .uni-form__check-item + .uni-form__item, .uni-form--compact
    .uni-form__check-item + .uni-form__section, .uni-form--compact
    .uni-form__radio-item + .uni-form__item, .uni-form--compact
    .uni-form__radio-item + .uni-form__section {
      margin-top: 1rem; }

.uni-form__indicator--check {
  position: absolute;
  top: 1px;
  left: 1px;
  display: inline-block;
  background-color: transparent;
  color: rgba(165, 172, 180, 0.6);
  font-size: 0.875rem;
  width: 1em;
  height: 1em;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0 2px currentColor;
  transition: color 0.2s linear; }
  [class*='t-de'] .uni-form__indicator--check {
    color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__indicator--check {
    color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__indicator--check {
    color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__indicator--check {
    color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__indicator--check {
    color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__indicator--check {
    color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__indicator--check {
    color: rgba(104, 117, 129, 0.6); }
  .uni-form__indicator--check::after {
    position: absolute;
    content: '';
    width: 10px;
    height: 5px;
    top: 4px;
    left: 2px;
    border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
    border-radius: 0.3125em;
    transform: rotate(-50deg);
    opacity: 0;
    transition: opacity 0.2s linear; }
  .uni-form__input--check:checked + .uni-form__indicator--check,
  .uni-form__input--check:checked:hover + .uni-form__indicator--check {
    color: #13293f;
    background-color: currentColor; }
    [class*='t-de'] .uni-form__input--check:checked + .uni-form__indicator--check, [class*='t-de']
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #fff; }
    [class*='t-le'] .uni-form__input--check:checked + .uni-form__indicator--check, [class*='t-le']
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #13293f; }
    .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check, .uni-env--dark
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #fff; }
    .uni-env--light .uni-form__input--check:checked + .uni-form__indicator--check, .uni-env--light
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #13293f; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #fff; }
    [class*='uni-env--'] .uni-env--light .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] .uni-env--light
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #13293f; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #fff; }
    .uni-form__input--check:checked + .uni-form__indicator--check::after,
    .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
      color: #fff;
      opacity: 1; }
      [class*='t-de'] .uni-form__input--check:checked + .uni-form__indicator--check::after, [class*='t-de']
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #13293f; }
      [class*='t-le'] .uni-form__input--check:checked + .uni-form__indicator--check::after, [class*='t-le']
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #fff; }
      .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check::after, .uni-env--dark
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #13293f; }
      .uni-env--light .uni-form__input--check:checked + .uni-form__indicator--check::after, .uni-env--light
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #fff; }
      [class*='uni-env--'] .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check::after, [class*='uni-env--'] .uni-env--dark
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #13293f; }
      [class*='uni-env--'] .uni-env--light .uni-form__input--check:checked + .uni-form__indicator--check::after, [class*='uni-env--'] .uni-env--light
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #fff; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--check:checked + .uni-form__indicator--check::after, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-form__input--check:checked:hover + .uni-form__indicator--check::after {
        color: #13293f; }
    .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, .uni-form__item--is-error
    .uni-form__input--check:checked:hover + .uni-form__indicator--check {
      color: #e81c00; }
      [class*='t-de'] .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, [class*='t-de'] .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #ff523d; }
      [class*='t-le'] .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, [class*='t-le'] .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #e81c00; }
      .uni-env--dark .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, .uni-env--dark .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #ff523d; }
      .uni-env--light .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, .uni-env--light .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #ff523d; }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--check:checked + .uni-form__indicator--check, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
      .uni-form__input--check:checked:hover + .uni-form__indicator--check {
        color: #ff523d; }
  .uni-form__input--check:hover + .uni-form__indicator--check {
    color: #13293f; }
    [class*='t-de'] .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #fff; }
    [class*='t-le'] .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #13293f; }
    .uni-env--dark .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #fff; }
    .uni-env--light .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #13293f; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #fff; }
    [class*='uni-env--'] .uni-env--light .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #13293f; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #fff; }
    .uni-form__input--check:hover + .uni-form__indicator--check::after {
      opacity: 0.4; }
    .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
      color: #e81c00; }
      [class*='t-de'] .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #ff523d; }
      [class*='t-le'] .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #e81c00; }
      .uni-env--dark .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #ff523d; }
      .uni-env--light .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #ff523d; }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--check:hover + .uni-form__indicator--check {
        color: #ff523d; }
    .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
      color: rgba(165, 172, 180, 0.6); }
      [class*='t-de'] .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(104, 117, 129, 0.6); }
      [class*='t-le'] .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(165, 172, 180, 0.6); }
      .uni-env--dark .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(104, 117, 129, 0.6); }
      .uni-env--light .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(165, 172, 180, 0.6); }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(104, 117, 129, 0.6); }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(165, 172, 180, 0.6); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check {
        color: rgba(104, 117, 129, 0.6); }
      .uni-form__item--is-disabled .uni-form__input--check:hover + .uni-form__indicator--check::after {
        opacity: 0; }
  .uni-form__item--is-error .uni-form__indicator--check {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__indicator--check {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__indicator--check {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__indicator--check {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__indicator--check {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__indicator--check {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__indicator--check {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__indicator--check {
      color: #ff523d; }

.uni-form__indicator--radio {
  position: absolute;
  top: 1px;
  left: 1px;
  display: inline-block;
  background-color: transparent;
  color: rgba(165, 172, 180, 0.6);
  font-size: 1rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border-color: currentColor;
  border-width: 2px;
  border-style: solid;
  transition: color 0.2s linear; }
  [class*='t-de'] .uni-form__indicator--radio {
    color: rgba(104, 117, 129, 0.6); }
  [class*='t-le'] .uni-form__indicator--radio {
    color: rgba(165, 172, 180, 0.6); }
  .uni-env--dark .uni-form__indicator--radio {
    color: rgba(104, 117, 129, 0.6); }
  .uni-env--light .uni-form__indicator--radio {
    color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] .uni-env--dark .uni-form__indicator--radio {
    color: rgba(104, 117, 129, 0.6); }
  [class*='uni-env--'] .uni-env--light .uni-form__indicator--radio {
    color: rgba(165, 172, 180, 0.6); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__indicator--radio {
    color: rgba(104, 117, 129, 0.6); }
  .uni-form__indicator--radio:after {
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
    content: '';
    background-color: currentColor;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.2s linear; }
  .uni-form__input--radio:checked + .uni-form__indicator--radio,
  .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
    color: #13293f; }
    [class*='t-de'] .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='t-de']
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #fff; }
    [class*='t-le'] .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='t-le']
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #13293f; }
    .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio, .uni-env--dark
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #fff; }
    .uni-env--light .uni-form__input--radio:checked + .uni-form__indicator--radio, .uni-env--light
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #13293f; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] .uni-env--dark
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #fff; }
    [class*='uni-env--'] .uni-env--light .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] .uni-env--light
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #13293f; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #fff; }
    .uni-form__input--radio:checked + .uni-form__indicator--radio::after,
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
      background-color: #13293f;
      opacity: 1; }
      [class*='t-de'] .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='t-de']
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      [class*='t-le'] .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='t-le']
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio::after, .uni-env--dark
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      .uni-env--light .uni-form__input--radio:checked + .uni-form__indicator--radio::after, .uni-env--light
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      [class*='uni-env--'] .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] .uni-env--dark
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      [class*='uni-env--'] .uni-env--light .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] .uni-env--light
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
    .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, .uni-form__item--is-error
    .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
      color: #e81c00; }
      [class*='t-de'] .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='t-de'] .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      [class*='t-le'] .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='t-le'] .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, .uni-env--dark .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      .uni-env--light .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, .uni-env--light .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, .uni-form__item--is-error
      .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
        background-color: #e81c00; }
        [class*='t-de'] .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='t-de'] .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        [class*='t-le'] .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='t-le'] .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, .uni-env--dark .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        .uni-env--light .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, .uni-env--light .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:checked + .uni-form__indicator--radio::after, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
        .uni-form__input--radio:checked:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
  .uni-form__input--radio:hover + .uni-form__indicator--radio {
    color: #13293f; }
    [class*='t-de'] .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #fff; }
    [class*='t-le'] .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #13293f; }
    .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #fff; }
    .uni-env--light .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #13293f; }
    [class*='uni-env--'] .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #fff; }
    [class*='uni-env--'] .uni-env--light .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #13293f; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #fff; }
    .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
      background-color: #13293f;
      opacity: 0.15; }
      [class*='t-de'] .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      [class*='t-le'] .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      .uni-env--light .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      [class*='uni-env--'] .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
      [class*='uni-env--'] .uni-env--light .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #13293f; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #fff; }
    .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: #e81c00; }
      [class*='t-de'] .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      [class*='t-le'] .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      .uni-env--light .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: #ff523d; }
      .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        background-color: #e81c00; }
        [class*='t-de'] .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        [class*='t-le'] .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        .uni-env--light .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
        [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #e81c00; }
        [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
          background-color: #ff523d; }
    .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
      color: rgba(165, 172, 180, 0.6); }
      [class*='t-de'] .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(104, 117, 129, 0.6); }
      [class*='t-le'] .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(165, 172, 180, 0.6); }
      .uni-env--dark .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(104, 117, 129, 0.6); }
      .uni-env--light .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(165, 172, 180, 0.6); }
      [class*='uni-env--'] .uni-env--dark .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(104, 117, 129, 0.6); }
      [class*='uni-env--'] .uni-env--light .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(165, 172, 180, 0.6); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio {
        color: rgba(104, 117, 129, 0.6); }
      .uni-form__item--is-disabled .uni-form__input--radio:hover + .uni-form__indicator--radio::after {
        opacity: 0; }
  .uni-form__item--is-error .uni-form__indicator--radio {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__indicator--radio {
      color: #ff523d; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__indicator--radio {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__indicator--radio {
      color: #ff523d; }
    .uni-env--light .uni-form__item--is-error .uni-form__indicator--radio {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__indicator--radio {
      color: #ff523d; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__indicator--radio {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__indicator--radio {
      color: #ff523d; }

.uni-form__input--check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  cursor: pointer; }

.uni-form__input--radio {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  cursor: pointer; }

.uni-form__label--check,
.uni-form__label--radio {
  display: block;
  cursor: pointer;
  color: rgba(19, 41, 63, 0.8); }
  [class*='t-de'] .uni-form__label--check, [class*='t-de']
  .uni-form__label--radio {
    color: rgba(230, 242, 255, 0.8); }
  [class*='t-le'] .uni-form__label--check, [class*='t-le']
  .uni-form__label--radio {
    color: rgba(19, 41, 63, 0.8); }
  .uni-env--dark .uni-form__label--check, .uni-env--dark
  .uni-form__label--radio {
    color: rgba(230, 242, 255, 0.8); }
  .uni-env--light .uni-form__label--check, .uni-env--light
  .uni-form__label--radio {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] .uni-env--dark .uni-form__label--check, [class*='uni-env--'] .uni-env--dark
  .uni-form__label--radio {
    color: rgba(230, 242, 255, 0.8); }
  [class*='uni-env--'] .uni-env--light .uni-form__label--check, [class*='uni-env--'] .uni-env--light
  .uni-form__label--radio {
    color: rgba(19, 41, 63, 0.8); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__label--check, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark
  .uni-form__label--radio {
    color: rgba(230, 242, 255, 0.8); }
  .uni-form__item--is-error .uni-form__label--check, .uni-form__item--is-error
  .uni-form__label--radio {
    color: #e81c00; }
    [class*='t-de'] .uni-form__item--is-error .uni-form__label--check, [class*='t-de'] .uni-form__item--is-error
    .uni-form__label--radio {
      color: #ff4b33; }
    [class*='t-le'] .uni-form__item--is-error .uni-form__label--check, [class*='t-le'] .uni-form__item--is-error
    .uni-form__label--radio {
      color: #e81c00; }
    .uni-env--dark .uni-form__item--is-error .uni-form__label--check, .uni-env--dark .uni-form__item--is-error
    .uni-form__label--radio {
      color: #ff4b33; }
    .uni-env--light .uni-form__item--is-error .uni-form__label--check, .uni-env--light .uni-form__item--is-error
    .uni-form__label--radio {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__label--check, [class*='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__label--radio {
      color: #ff4b33; }
    [class*='uni-env--'] .uni-env--light .uni-form__item--is-error .uni-form__label--check, [class*='uni-env--'] .uni-env--light .uni-form__item--is-error
    .uni-form__label--radio {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error .uni-form__label--check, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-form__item--is-error
    .uni-form__label--radio {
      color: #ff4b33; }

.uni-form__label--check {
  font-size: 1rem;
  border-width: 1px; }
  .uni-form--small .uni-form__label--check {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form--medium .uni-form__label--check {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form--large .uni-form__label--check {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__label--check.uni-form__label--check--small {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__label--check.uni-form__label--check--medium {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__label--check.uni-form__label--check--large {
    font-size: 1rem;
    border-width: 1px; }

.uni-form__label--radio {
  font-size: 1rem;
  border-width: 1px; }
  .uni-form--small .uni-form__label--radio {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form--medium .uni-form__label--radio {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form--large .uni-form__label--radio {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__label--radio.uni-form__label--radio--small {
    font-size: 0.875rem;
    border-width: 1px; }
  .uni-form__label--radio.uni-form__label--radio--medium {
    font-size: 1rem;
    border-width: 1px; }
  .uni-form__label--radio.uni-form__label--radio--large {
    font-size: 1rem;
    border-width: 1px; }

.uni-form__item--is-disabled .uni-form__label--check,
.uni-form__item--is-disabled .uni-form__label--radio {
  cursor: default; }

.uni-form__check-item.uni-form__item--is-disabled .uni-form__label--check {
  opacity: 0.2; }

.uni-form__icon--search {
  top: 1.25em !important; }
  .uni-form__icon--search::before, .uni-form__icon--search::after {
    position: absolute;
    border: 2px solid currentColor;
    content: ''; }
  .uni-form__icon--search::before {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%; }
  .uni-form__icon--search::after {
    top: 9px;
    left: 10px;
    height: 0.5rem;
    transform: rotate(-45deg); }

.uni-form__input--range {
  width: 100%; }
  .uni-form__input--range:focus {
    outline: 0; }

a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
  color: #009ce3;
  font-family: inherit; }

.uni-link--reg {
  text-decoration: none;
  font-size: inherit;
  color: inherit; }

.uni-link--action {
  color: #009ce3;
  background-image: linear-gradient(90deg, transparent 66%, transparent 0);
  background-repeat: repeat-x;
  background-position: 0 1.05em;
  cursor: pointer;
  background-size: 0.25em 0.08em; }
  .uni-link--action:hover {
    background-image: linear-gradient(transparent, transparent); }
  .uni-link--action:hover {
    text-decoration: none;
    background-image: linear-gradient(90deg, #009ce3 66%, transparent 0);
    background-repeat: repeat-x;
    background-position: 0 1.05em;
    cursor: pointer;
    background-size: 0.25em 0.08em; }
    .uni-link--action:hover:hover {
      background-image: linear-gradient(#009ce3, #009ce3); }

.uni-link--action--display {
  color: #009ce3;
  background-image: linear-gradient(90deg, transparent 66%, transparent 0);
  background-repeat: repeat-x;
  background-position: 0 1.05em;
  cursor: pointer;
  background-size: 0.25em 0.045em; }
  .uni-link--action--display:hover {
    background-image: linear-gradient(transparent, transparent); }
  .uni-link--action--display:hover {
    text-decoration: none;
    background-image: linear-gradient(90deg, #009ce3 66%, transparent 0);
    background-repeat: repeat-x;
    background-position: 0 1.05em;
    cursor: pointer;
    background-size: 0.25em 0.045em; }
    .uni-link--action--display:hover:hover {
      background-image: linear-gradient(#009ce3, #009ce3); }

.uni-link--secondary {
  color: #009ce3;
  background-image: linear-gradient(90deg, transparent 66%, transparent 0);
  background-repeat: repeat-x;
  background-position: 0 1.05em;
  cursor: pointer;
  background-size: 0.25em 0.08em; }
  .uni-link--secondary:hover {
    background-image: linear-gradient(transparent, transparent); }
  .uni-link--secondary:hover {
    background-image: linear-gradient(90deg, #009ce3 66%, transparent 0);
    background-repeat: repeat-x;
    background-position: 0 1.05em;
    cursor: pointer;
    background-size: 0.25em 0.08em;
    text-decoration: none; }
    .uni-link--secondary:hover:hover {
      background-image: linear-gradient(#009ce3, #009ce3); }

.uni-link--secondary--display {
  color: #009ce3;
  background-image: linear-gradient(90deg, transparent 66%, transparent 0);
  background-repeat: repeat-x;
  background-position: 0 1.05em;
  cursor: pointer;
  background-size: 0.25em 0.045em; }
  .uni-link--secondary--display:hover {
    background-image: linear-gradient(transparent, transparent); }
  .uni-link--secondary--display:hover {
    background-image: linear-gradient(90deg, #009ce3 66%, transparent 0);
    background-repeat: repeat-x;
    background-position: 0 1.05em;
    cursor: pointer;
    background-size: 0.25em 0.045em;
    text-decoration: none; }
    .uni-link--secondary--display:hover:hover {
      background-image: linear-gradient(#009ce3, #009ce3); }

.uni-avatar {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 100%;
  font-size: 1.5rem; }
  .uni-avatar.uni-avatar--with-text {
    line-height: 3rem; }

.uni-avatar--sm {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 100%;
  font-size: 0.875rem; }
  .uni-avatar--sm.uni-avatar--with-text {
    line-height: 2rem; }

.uni-avatar--med {
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 100%;
  font-size: 1.5rem; }
  .uni-avatar--med.uni-avatar--with-text {
    line-height: 3rem; }

.uni-avatar--lg {
  width: 4.5rem;
  height: 4.5rem;
  overflow: hidden;
  border-radius: 100%;
  font-size: 2rem; }
  .uni-avatar--lg.uni-avatar--with-text {
    line-height: 4.5rem; }

.uni-avatar--blank {
  background-color: #e4e7eb; }

.uni-avatar--with-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #313b45;
  text-align: center; }

.uni-avatar__initials {
  color: white;
  font-weight: bold;
  font-size: 1em; }

.css-spinner,
.uni-css-spinner {
  display: inline-block;
  clear: both;
  width: 2em;
  height: 2em;
  margin: 0 auto;
  border-radius: 100%;
  animation: spinner-rotation 0.7s infinite linear;
  border-top: 0.45em solid #ff6300;
  border-right: 0.45em solid rgba(124, 124, 124, 0.2);
  border-bottom: 0.45em solid rgba(124, 124, 124, 0.2);
  border-left: 0.45em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
  .no-cssanimations .css-spinner, .no-cssanimations
  .uni-css-spinner {
    display: none; }

.css-spinner--bg,
.uni-css-spinner--bg {
  display: inline-block;
  clear: both;
  width: #ff6300;
  height: #ff6300;
  margin: 0 auto;
  border-radius: 100%;
  animation: spinner-rotation 0.7s infinite linear;
  border-top: #391600 solid white;
  border-right: #391600 solid rgba(255, 255, 255, 0.3);
  border-bottom: #391600 solid rgba(255, 255, 255, 0.3);
  border-left: #391600 solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
  .no-cssanimations .css-spinner--bg, .no-cssanimations
  .uni-css-spinner--bg {
    display: none; }

.uni-page-header {
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: #e4e7eb;
  line-height: 67px; }
  .uni-page-header:after {
    content: "";
    display: table;
    clear: both; }

.uni-page-header__h {
  float: left;
  color: white;
  text-transform: uppercase; }

.uni-page-header__nav {
  display: none;
  clear: left;
  margin-bottom: 1rem; }
  @media (min-width: 40rem) {
    .uni-page-header__nav {
      display: block;
      float: left;
      clear: none;
      margin-bottom: 0;
      margin-left: 1rem; } }
  .page-header__filter--is-active .uni-page-header__nav {
    display: block; }

.uni-page-header__items:after {
  content: "";
  display: table;
  clear: both; }

.uni-page-header__item {
  line-height: 67px; }
  @media (min-width: 40rem) {
    .uni-page-header__item {
      float: left; } }

.uni-page-header__link {
  display: block;
  padding-right: 12px;
  padding-left: 12px;
  color: #bfbfbf; }
  .uni-page-header__link:hover {
    color: white; }
  .uni-page-header__link--is-active {
    background-color: white; }

.uni-page-header__trigger {
  display: block;
  float: right; }
  @media (min-width: 40rem) {
    .uni-page-header__trigger {
      display: none; } }

.uni-table__container + .uni-table__container {
  margin-top: 3rem; }

.uni-table {
  width: 100%;
  max-width: 100%;
  font-size: 0.875rem; }

.uni-table > thead > tr > th,
.uni-table .uni-table__thead > tr > th {
  padding: 0.625rem 1rem;
  vertical-align: bottom;
  border-bottom: 2px solid #ff6300;
  background-color: #f9fafb;
  text-align: left; }

.uni-table > tfoot > tr > td,
.uni-table .uni-table__tfoot > tr > td {
  padding: 0.625rem 1rem;
  border-top: 1px solid #e4e7eb;
  vertical-align: top; }

.uni-table > tbody > tr > td,
.uni-table .uni-table__tbody > tr > td {
  padding: 0.625rem 1rem; }

.uni-table > tbody + tbody {
  border-top: 2px solid #ff6300; }

.uni-table--bordered {
  border: 1px solid #e4e7eb; }
  .uni-table--bordered > thead > tr > th {
    border: 1px solid #e4e7eb;
    border-top: 1px solid #d5dae0;
    border-bottom: 2px solid #ff6300; }
  .uni-table--bordered > tbody > tr > td {
    border: 1px solid #e4e7eb; }

.uni-table--striped > tbody > tr:nth-child(2n + 2) {
  background-color: rgba(0, 0, 0, 0.017); }

.uni-table--striped > tbody > tr:hover {
  background-color: #edf0f3; }

.uni-table--hoverable > tbody > tr:hover {
  background-color: #f9fafb; }

.uni-table__explain {
  padding: 15px 20px;
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.017); }

.uni-table__col--bdr-right {
  border-right: 1px solid #d5dae0; }

.uni-table__col--bdr-left {
  border-left: 1px solid #d5dae0; }

.uni-table__col--bg {
  background-color: rgba(170, 170, 170, 0.12); }

@media only screen and (max-width: 960px) {
  .uni-table--rwd {
    margin-bottom: 0; }
  .uni-table--pinned {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    overflow-x: scroll;
    background: white; }
  .uni-table--pinned table {
    width: 100%;
    border-right: none;
    border-left: none; }
  .uni-table--pinned > th,
  .uni-table--pinned > td {
    white-space: nowrap; }
  .uni-table__wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px; }
    .uni-table__wrapper::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 15px;
      content: '';
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.001) 75%, rgba(0, 0, 0, 0.25)); }
  .uni-table__wrapper .uni-table--scrollable {
    overflow: scroll;
    overflow-y: hidden; }
  .uni-table--rwd > td,
  .uni-table--rwd > th {
    position: relative;
    overflow: hidden;
    white-space: nowrap; }
  .uni-table--rwd th:first-child,
  .uni-table--rwd td:first-child,
  .uni-table--rwd .uni-table--pinned td {
    display: none; } }

.uni-grid {
  display: block;
  clear: both;
  width: 100%;
  padding: 0;
  margin: 0; }
  .uni-grid:after {
    content: "";
    display: table;
    clear: both; }

.uni-grid--outsidegutters {
  display: block;
  clear: both;
  width: 100%;
  padding: 0 1.25rem;
  margin: 0; }
  .uni-grid--outsidegutters:after {
    content: "";
    display: table;
    clear: both; }

.uni-grid--fixedwidth {
  width: 85.375rem;
  margin: 0 auto; }
  @media (max-width: 63.9375rem) {
    .uni-grid--fixedwidth {
      width: 100%; } }

.uni-grid--maxwidth {
  width: 100%;
  max-width: 85.375rem;
  margin: 0 auto; }
  @media (max-width: 63.9375rem) {
    .uni-grid--maxwidth {
      max-width: 100%; } }

.uni-grid__row {
  width: 100%;
  padding: 0;
  margin-bottom: 1.25rem; }
  .uni-grid__row:after {
    content: "";
    display: table;
    clear: both; }

.uni-grid__row--no-gutter-cols > [class*='uni-grid__col--'] {
  padding-right: 0; }

[class*='uni-grid__col--'] {
  float: left;
  padding-right: 1.25rem;
  text-align: left; }
  [class*='uni-grid__col--']:last-of-type {
    padding-right: 0; }

.uni-grid__col--onewhole {
  width: calc((100% / 1)); }

.uni-grid__col--threequarters {
  width: calc(((100% / 4) * 3)); }

.uni-grid__col--onehalf {
  width: calc((100% / 2)); }

.uni-grid__col--twothirds {
  width: calc(((100% / 3) * 2)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--twothirds {
      width: calc((100% / 1)); } }

.uni-grid__col--onethird {
  width: calc((100% / 3)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--onethird {
      width: calc((100% / 2)); } }

.uni-grid__col--onequarter {
  width: calc((100% / 4)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--onequarter {
      width: calc((100% / 2)); } }

.uni-grid__col--indent-twothirds {
  margin-left: calc(((100% / 3) * 2)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--indent-twothirds {
      margin-left: 0; } }

.uni-grid__col--indent-onehalf {
  margin-left: calc((100% / 2)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--indent-onehalf {
      margin-left: 0; } }

.uni-grid__col--indent-onethird {
  margin-left: calc((100% / 3)); }
  @media (max-width: 39.9375rem) {
    .uni-grid__col--indent-onethird {
      margin-left: 0; } }

.uni-grid__col--indent-onequarter {
  margin-left: calc((100% / 4)); }

.uni-grid__col--centered {
  float: none;
  margin: 0 auto; }

@media (max-width: 39.9375rem) {
  .l-grid--5 {
    overflow: hidden; }
    .l-grid--5:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--5 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-grid--5 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-grid--5 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-grid--5 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) and (max-width: 43.6875rem) {
  .l-grid--5 {
    overflow: hidden; }
    .l-grid--5:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--5 > .l-grid__item {
      float: left;
      width: calc( (100% - 1.5rem) / 2);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-grid--5 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-grid--5 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-grid--5 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

@media (min-width: 43.75rem) and (max-width: 63.9375rem) {
  .l-grid--5 {
    overflow: hidden; }
    .l-grid--5:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--5 > .l-grid__item {
      float: left;
      width: calc( (100% - 3rem) / 3);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 4) { margin-top: $margin; } */ }
      .l-grid--5 > .l-grid__item:nth-of-type(3n) {
        margin-right: 0; }
      .l-grid--5 > .l-grid__item:nth-of-type(3n + 4) {
        clear: left; }
      .l-grid--5 > .l-grid__item:nth-last-child(-1n + 3) {
        margin-bottom: 0; } }

@media (min-width: 64rem) {
  .l-grid--5 {
    overflow: hidden; }
    .l-grid--5:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--5 > .l-grid__item {
      float: left;
      width: calc( (100% - 6rem) / 5);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 6) { margin-top: $margin; } */ }
      .l-grid--5 > .l-grid__item:nth-of-type(5n) {
        margin-right: 0; }
      .l-grid--5 > .l-grid__item:nth-of-type(5n + 6) {
        clear: left; }
      .l-grid--5 > .l-grid__item:nth-last-child(-1n + 5) {
        margin-bottom: 0; } }

@media (max-width: 39.9375rem) {
  .l-grid--3 {
    overflow: hidden; }
    .l-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-grid--3 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-grid--3 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-grid--3 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) and (max-width: 43.6875rem) {
  .l-grid--3 {
    overflow: hidden; }
    .l-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 1.5rem) / 2);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-grid--3 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-grid--3 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-grid--3 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

@media (min-width: 43.75rem) {
  .l-grid--3 {
    overflow: hidden; }
    .l-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 3rem) / 3);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 4) { margin-top: $margin; } */ }
      .l-grid--3 > .l-grid__item:nth-of-type(3n) {
        margin-right: 0; }
      .l-grid--3 > .l-grid__item:nth-of-type(3n + 4) {
        clear: left; }
      .l-grid--3 > .l-grid__item:nth-last-child(-1n + 3) {
        margin-bottom: 0; } }

@media (max-width: 39.9375rem) {
  .l-grid--2 {
    overflow: hidden; }
    .l-grid--2:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--2 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-grid--2 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-grid--2 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-grid--2 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) {
  .l-grid--2 {
    overflow: hidden; }
    .l-grid--2:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--2 > .l-grid__item {
      float: left;
      width: calc( (100% - 1.5rem) / 2);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-grid--2 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-grid--2 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-grid--2 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

@media (max-width: 39.9375rem) {
  .l-grid--2--space-lg {
    overflow: hidden; }
    .l-grid--2--space-lg:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--2--space-lg > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-grid--2--space-lg > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-grid--2--space-lg > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-grid--2--space-lg > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) {
  .l-grid--2--space-lg {
    overflow: hidden; }
    .l-grid--2--space-lg:after {
      content: "";
      display: table;
      clear: both; }
    .l-grid--2--space-lg > .l-grid__item {
      float: left;
      width: calc( (100% - 3rem) / 2);
      margin-right: 3rem;
      margin-bottom: 3rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-grid--2--space-lg > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-grid--2--space-lg > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-grid--2--space-lg > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

[class*='l-form-grid'] > .uni-form__item {
  margin-top: 0; }

@media (max-width: 39.9375rem) {
  .l-form-grid--4 {
    overflow: hidden; }
    .l-form-grid--4:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--4 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-form-grid--4 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-form-grid--4 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-form-grid--4 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) and (max-width: 89.9375rem) {
  .l-form-grid--4 {
    overflow: hidden; }
    .l-form-grid--4:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--4 > .l-grid__item {
      float: left;
      width: calc( (100% - 2rem) / 2);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-form-grid--4 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-form-grid--4 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-form-grid--4 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

@media (min-width: 90rem) {
  .l-form-grid--4 {
    overflow: hidden; }
    .l-form-grid--4:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--4 > .l-grid__item {
      float: left;
      width: calc( (100% - 6rem) / 4);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 5) { margin-top: $margin; } */ }
      .l-form-grid--4 > .l-grid__item:nth-of-type(4n) {
        margin-right: 0; }
      .l-form-grid--4 > .l-grid__item:nth-of-type(4n + 5) {
        clear: left; }
      .l-form-grid--4 > .l-grid__item:nth-last-child(-1n + 4) {
        margin-bottom: 0; } }

@media (max-width: 39.9375rem) {
  .l-form-grid--3 {
    overflow: hidden; }
    .l-form-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-form-grid--3 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-form-grid--3 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-form-grid--3 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) and (max-width: 43.6875rem) {
  .l-form-grid--3 {
    overflow: hidden; }
    .l-form-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 2rem) / 2);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-form-grid--3 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-form-grid--3 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-form-grid--3 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

@media (min-width: 43.75rem) {
  .l-form-grid--3 {
    overflow: hidden; }
    .l-form-grid--3:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--3 > .l-grid__item {
      float: left;
      width: calc( (100% - 4rem) / 3);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 4) { margin-top: $margin; } */ }
      .l-form-grid--3 > .l-grid__item:nth-of-type(3n) {
        margin-right: 0; }
      .l-form-grid--3 > .l-grid__item:nth-of-type(3n + 4) {
        clear: left; }
      .l-form-grid--3 > .l-grid__item:nth-last-child(-1n + 3) {
        margin-bottom: 0; } }

@media (max-width: 39.9375rem) {
  .l-form-grid--2 {
    overflow: hidden; }
    .l-form-grid--2:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--2 > .l-grid__item {
      float: left;
      width: calc( (100% - 0rem) / 1);
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      /* &:nth-of-type(1n + 2) { margin-top: $margin; } */ }
      .l-form-grid--2 > .l-grid__item:nth-of-type(1n) {
        margin-right: 0; }
      .l-form-grid--2 > .l-grid__item:nth-of-type(1n + 2) {
        clear: left; }
      .l-form-grid--2 > .l-grid__item:nth-last-child(-1n + 1) {
        margin-bottom: 0; } }

@media (min-width: 40rem) {
  .l-form-grid--2 {
    overflow: hidden; }
    .l-form-grid--2:after {
      content: "";
      display: table;
      clear: both; }
    .l-form-grid--2 > .l-grid__item {
      float: left;
      width: calc( (100% - 2rem) / 2);
      margin-right: 2rem;
      margin-bottom: 2rem;
      /* &:nth-of-type(1n + 3) { margin-top: $margin; } */ }
      .l-form-grid--2 > .l-grid__item:nth-of-type(2n) {
        margin-right: 0; }
      .l-form-grid--2 > .l-grid__item:nth-of-type(2n + 3) {
        clear: left; }
      .l-form-grid--2 > .l-grid__item:nth-last-child(-1n + 2) {
        margin-bottom: 0; } }

.l-pair {
  display: flex;
  align-items: flex-start; }
  .l-pair:after {
    content: "";
    display: table;
    clear: both; }

.l-pair--centered {
  align-items: center; }

.l-pair--reversed {
  flex-direction: row-reverse; }

.l-pair__figure {
  margin-right: 0.5rem; }
  .l-pair--reversed .l-pair__figure {
    margin-right: 0;
    margin-left: 1rem; }
  .l-pair--score .l-pair__figure {
    margin-right: 0;
    text-align: right; }
    .no-flexbox .l-pair--score .l-pair__figure {
      float: right; }
  .no-flexbox .l-pair__figure {
    float: left; }

.l-pair__body > .l-pair {
  margin-top: 1rem; }

.l-pair--score .l-pair__body {
  margin-right: auto; }

.no-flexbox .l-pair__body {
  float: left; }

.uni-margin--eighth {
  margin-top: 0.125rem;
  margin-right: 0.125rem;
  margin-bottom: 0.125rem;
  margin-left: 0.125rem; }

.uni-margin--quarter {
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0.25rem; }

.uni-margin--half {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem; }

.uni-margin--one {
  margin-top: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem; }

.uni-margin--one-and-half {
  margin-top: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 1.5rem; }

.uni-margin--two {
  margin-top: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem; }

.uni-margin--three {
  margin-top: 3rem;
  margin-right: 3rem;
  margin-bottom: 3rem;
  margin-left: 3rem; }

.uni-margin--four {
  margin-top: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem; }

.uni-margin--eighth--vert {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem; }

.uni-margin--quarter--vert {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.uni-margin--half--vert {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.uni-margin--one--vert {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.uni-margin--one-and-half--vert {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.uni-margin--two--vert {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.uni-margin--three--vert {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.uni-margin--four--vert {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.uni-margin--eighth--top {
  margin-top: 0.125rem; }

.uni-margin--quarter--top {
  margin-top: 0.25rem; }

.uni-margin--half--top {
  margin-top: 0.5rem; }

.uni-margin--one--top {
  margin-top: 1rem; }

.uni-margin--one-and-half--top {
  margin-top: 1.5rem; }

.uni-margin--two--top {
  margin-top: 2rem; }

.uni-margin--three--top {
  margin-top: 3rem; }

.uni-margin--four--top {
  margin-top: 4rem; }

.uni-margin--eighth--btm {
  margin-bottom: 0.125rem; }

.uni-margin--quarter--btm {
  margin-bottom: 0.25rem; }

.uni-margin--half--btm {
  margin-bottom: 0.5rem; }

.uni-margin--one--btm {
  margin-bottom: 1rem; }

.uni-margin--one-and-half--btm {
  margin-bottom: 1.5rem; }

.uni-margin--two--btm {
  margin-bottom: 2rem; }

.uni-margin--three--btm {
  margin-bottom: 3rem; }

.uni-margin--four--btm {
  margin-bottom: 4rem; }

.uni-margin--eighth--horiz {
  margin-right: 0.125rem;
  margin-left: 0.125rem; }

.uni-margin--quarter--horiz {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }

.uni-margin--half--horiz {
  margin-right: 0.5rem;
  margin-left: 0.5rem; }

.uni-margin--one--horiz {
  margin-right: 1rem;
  margin-left: 1rem; }

.uni-margin--one-and-half--horiz {
  margin-right: 1.5rem;
  margin-left: 1.5rem; }

.uni-margin--two--horiz {
  margin-right: 2rem;
  margin-left: 2rem; }

.uni-margin--three--horiz {
  margin-right: 3rem;
  margin-left: 3rem; }

.uni-margin--four--horiz {
  margin-right: 4rem;
  margin-left: 4rem; }

.uni-margin--eighth--right {
  margin-right: 0.125rem; }

.uni-margin--quarter--right {
  margin-right: 0.25rem; }

.uni-margin--half--right {
  margin-right: 0.5rem; }

.uni-margin--one--right {
  margin-right: 1rem; }

.uni-margin--one-and-half--right {
  margin-right: 1.5rem; }

.uni-margin--two--right {
  margin-right: 2rem; }

.uni-margin--three--right {
  margin-right: 3rem; }

.uni-margin--four--right {
  margin-right: 4rem; }

.uni-margin--eighth--left {
  margin-left: 0.125rem; }

.uni-margin--quarter--left {
  margin-left: 0.25rem; }

.uni-margin--half--left {
  margin-left: 0.5rem; }

.uni-margin--one--left {
  margin-left: 1rem; }

.uni-margin--one-and-half--left {
  margin-left: 1.5rem; }

.uni-margin--two--left {
  margin-left: 2rem; }

.uni-margin--three--left {
  margin-left: 3rem; }

.uni-margin--four--left {
  margin-left: 4rem; }

.uni-pad--eighth {
  padding-top: 0.125rem;
  padding-right: 0.125rem;
  padding-bottom: 0.125rem;
  padding-left: 0.125rem; }

.uni-pad--quarter {
  padding-top: 0.25rem;
  padding-right: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem; }

.uni-pad--half {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem; }

.uni-pad--one {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem; }

.uni-pad--one-and-half {
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem; }

.uni-pad--two {
  padding-top: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem; }

.uni-pad--three {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem; }

.uni-pad--four {
  padding-top: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
  padding-left: 4rem; }

.uni-pad--eighth--vert {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.uni-pad--quarter--vert {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.uni-pad--half--vert {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.uni-pad--one--vert {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.uni-pad--one-and-half--vert {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.uni-pad--two--vert {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.uni-pad--three--vert {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.uni-pad--four--vert {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.uni-pad--eighth--top {
  padding-top: 0.125rem; }

.uni-pad--quarter--top {
  padding-top: 0.25rem; }

.uni-pad--half--top {
  padding-top: 0.5rem; }

.uni-pad--one--top {
  padding-top: 1rem; }

.uni-pad--one-and-half--top {
  padding-top: 1.5rem; }

.uni-pad--two--top {
  padding-top: 2rem; }

.uni-pad--three--top {
  padding-top: 3rem; }

.uni-pad--four--top {
  padding-top: 4rem; }

.uni-pad--eighth--btm {
  padding-bottom: 0.125rem; }

.uni-pad--quarter--btm {
  padding-bottom: 0.25rem; }

.uni-pad--half--btm {
  padding-bottom: 0.5rem; }

.uni-pad--one--btm {
  padding-bottom: 1rem; }

.uni-pad--one-and-half--btm {
  padding-bottom: 1.5rem; }

.uni-pad--two--btm {
  padding-bottom: 2rem; }

.uni-pad--three--btm {
  padding-bottom: 3rem; }

.uni-pad--four--btm {
  padding-bottom: 4rem; }

.uni-pad--eighth--horiz {
  padding-right: 0.125rem;
  padding-left: 0.125rem; }

.uni-pad--quarter--horiz {
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

.uni-pad--half--horiz {
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.uni-pad--one--horiz {
  padding-right: 1rem;
  padding-left: 1rem; }

.uni-pad--one-and-half--horiz {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.uni-pad--two--horiz {
  padding-right: 2rem;
  padding-left: 2rem; }

.uni-pad--three--horiz {
  padding-right: 3rem;
  padding-left: 3rem; }

.uni-pad--four--horiz {
  padding-right: 4rem;
  padding-left: 4rem; }

.uni-pad--eighth--right {
  padding-right: 0.125rem; }

.uni-pad--quarter--right {
  padding-right: 0.25rem; }

.uni-pad--half--right {
  padding-right: 0.5rem; }

.uni-pad--one--right {
  padding-right: 1rem; }

.uni-pad--one-and-half--right {
  padding-right: 1.5rem; }

.uni-pad--two--right {
  padding-right: 2rem; }

.uni-pad--three--right {
  padding-right: 3rem; }

.uni-pad--four--right {
  padding-right: 4rem; }

.uni-pad--eighth--left {
  padding-left: 0.125rem; }

.uni-pad--quarter--left {
  padding-left: 0.25rem; }

.uni-pad--half--left {
  padding-left: 0.5rem; }

.uni-pad--one--left {
  padding-left: 1rem; }

.uni-pad--one-and-half--left {
  padding-left: 1.5rem; }

.uni-pad--two--left {
  padding-left: 2rem; }

.uni-pad--three--left {
  padding-left: 3rem; }

.uni-pad--four--left {
  padding-left: 4rem; }

.u-float--left {
  float: left; }

.u-float--right {
  float: right; }

.u-align--right {
  text-align: right; }

.u-align--center {
  text-align: center; }

/* stylelint-disable */
.u-visually-hidden,
.u-sr-only {
  position: absolute !important;
  padding: 0 !important;
  width: 1px !important;
  overflow: hidden !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  clip: rect(0 0 0 0) !important; }

/* stylelint-enable */
.uni-display--normal {
  font-weight: 400; }

.uni-btn--text {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 41, 63, 0.65);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--text:hover, .uni-btn--text:active, .uni-btn--text:focus, .uni-btn--text:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text:disabled, .uni-btn--text.uni-btn--is-disabled, .uni-btn--text.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text:disabled, .uni-btn--text.uni-btn--is-disabled, .uni-btn--text.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text:disabled, [class*='t-de'] .uni-btn--text.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text:disabled, [class*='t-le'] .uni-btn--text.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text:disabled, .uni-env--dark .uni-btn--text.uni-btn--is-disabled, .uni-env--dark .uni-btn--text.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text:disabled, .uni-env--light .uni-btn--text.uni-btn--is-disabled, .uni-env--light .uni-btn--text.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--disabled {
      color: white; }
    .uni-btn--text:disabled:hover, .uni-btn--text.uni-btn--is-disabled:hover, .uni-btn--text.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text:disabled:hover, [class*='t-de'] .uni-btn--text.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text:disabled:hover, [class*='t-le'] .uni-btn--text.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text:disabled:hover, .uni-env--dark .uni-btn--text.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text:disabled:hover, .uni-env--light .uni-btn--text.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text {
    color: rgba(230, 242, 255, 0.9); }
  [class*='t-le'] .uni-btn--text {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn--text {
    color: rgba(230, 242, 255, 0.9); }
  .uni-env--light .uni-btn--text {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text {
    color: rgba(230, 242, 255, 0.9); }
  [class*='uni-env--'] .uni-env--light .uni-btn--text {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text {
    color: rgba(230, 242, 255, 0.9); }
  .uni-btn--text.uni-btn--small, .uni-btn--text.uni-btn--medium, .uni-btn--text.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text:hover, .uni-btn--text:focus {
    color: rgba(116, 129, 142, 0.7375); }
    [class*='t-de'] .uni-btn--text:hover, [class*='t-de'] .uni-btn--text:focus {
      color: #e6f2ff; }
    [class*='t-le'] .uni-btn--text:hover, [class*='t-le'] .uni-btn--text:focus {
      color: rgba(116, 129, 142, 0.7375); }
    .uni-env--dark .uni-btn--text:hover, .uni-env--dark .uni-btn--text:focus {
      color: #e6f2ff; }
    .uni-env--light .uni-btn--text:hover, .uni-env--light .uni-btn--text:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text:focus {
      color: #e6f2ff; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text:focus {
      color: #e6f2ff; }
  .uni-btn--text:active {
    color: rgba(20, 38, 56, 0.685); }
    [class*='t-de'] .uni-btn--text:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--text:active {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--text:active {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--text:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text:active {
      color: rgba(230, 242, 255, 0.65); }
  .uni-btn--text.uni-btn--is-spinning {
    color: rgba(20, 38, 56, 0.685); }
    .uni-btn--text.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 41, 63, 0.65);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--text.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--text.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--text.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-btn--text.uni-btn--is-spinning:hover {
      color: rgba(20, 38, 56, 0.685); }
      [class*='t-de'] .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='t-le'] .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      .uni-env--dark .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      .uni-env--light .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
  .uni-btn--text.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--text--primary {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #009ce3;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--text--primary:hover, .uni-btn--text--primary:active, .uni-btn--text--primary:focus, .uni-btn--text--primary:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text--primary:disabled, .uni-btn--text--primary.uni-btn--is-disabled, .uni-btn--text--primary.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text--primary .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text--primary:disabled, .uni-btn--text--primary.uni-btn--is-disabled, .uni-btn--text--primary.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text--primary:disabled, [class*='t-de'] .uni-btn--text--primary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text--primary.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text--primary:disabled, [class*='t-le'] .uni-btn--text--primary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text--primary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text--primary:disabled, .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled, .uni-env--dark .uni-btn--text--primary.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text--primary:disabled, .uni-env--light .uni-btn--text--primary.uni-btn--is-disabled, .uni-env--light .uni-btn--text--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--disabled {
      color: white; }
    .uni-btn--text--primary:disabled:hover, .uni-btn--text--primary.uni-btn--is-disabled:hover, .uni-btn--text--primary.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text--primary:disabled:hover, [class*='t-de'] .uni-btn--text--primary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text--primary.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text--primary:disabled:hover, [class*='t-le'] .uni-btn--text--primary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text--primary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text--primary:disabled:hover, .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text--primary.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text--primary:disabled:hover, .uni-env--light .uni-btn--text--primary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--primary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text--primary {
    color: #009ce3; }
  [class*='t-le'] .uni-btn--text--primary {
    color: #009ce3; }
  .uni-env--dark .uni-btn--text--primary {
    color: #009ce3; }
  .uni-env--light .uni-btn--text--primary {
    color: #009ce3; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary {
    color: #009ce3; }
  [class*='uni-env--'] .uni-env--light .uni-btn--text--primary {
    color: #009ce3; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary {
    color: #009ce3; }
  .uni-btn--text--primary.uni-btn--small, .uni-btn--text--primary.uni-btn--medium, .uni-btn--text--primary.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text--primary:hover, .uni-btn--text--primary:focus {
    color: #40b5ea; }
    [class*='t-de'] .uni-btn--text--primary:hover, [class*='t-de'] .uni-btn--text--primary:focus {
      color: #40b5ea; }
    [class*='t-le'] .uni-btn--text--primary:hover, [class*='t-le'] .uni-btn--text--primary:focus {
      color: #40b5ea; }
    .uni-env--dark .uni-btn--text--primary:hover, .uni-env--dark .uni-btn--text--primary:focus {
      color: #40b5ea; }
    .uni-env--light .uni-btn--text--primary:hover, .uni-env--light .uni-btn--text--primary:focus {
      color: #40b5ea; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary:focus {
      color: #40b5ea; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--primary:focus {
      color: #40b5ea; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary:focus {
      color: #40b5ea; }
  .uni-btn--text--primary:active {
    color: #038fcf; }
    [class*='t-de'] .uni-btn--text--primary:active {
      color: #038fcf; }
    [class*='t-le'] .uni-btn--text--primary:active {
      color: #038fcf; }
    .uni-env--dark .uni-btn--text--primary:active {
      color: #038fcf; }
    .uni-env--light .uni-btn--text--primary:active {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary:active {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary:active {
      color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary:active {
      color: #038fcf; }
  .uni-btn--text--primary.uni-btn--is-spinning {
    color: #038fcf; }
    .uni-btn--text--primary.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--primary.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #009ce3;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text--primary.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text--primary.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--primary.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text--primary.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='t-le'] .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-env--light .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning {
      color: #038fcf; }
    .uni-btn--text--primary.uni-btn--is-spinning:hover {
      color: #038fcf; }
      [class*='t-de'] .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='t-le'] .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      .uni-env--light .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-spinning:hover {
        color: #038fcf; }
  .uni-btn--text--primary.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--primary.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text--primary.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text--primary.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--primary.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--primary.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text--primary.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--primary.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--text--secondary {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 41, 63, 0.65);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--text--secondary:hover, .uni-btn--text--secondary:active, .uni-btn--text--secondary:focus, .uni-btn--text--secondary:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text--secondary:disabled, .uni-btn--text--secondary.uni-btn--is-disabled, .uni-btn--text--secondary.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text--secondary .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text--secondary:disabled, .uni-btn--text--secondary.uni-btn--is-disabled, .uni-btn--text--secondary.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text--secondary:disabled, [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text--secondary.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text--secondary:disabled, [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text--secondary.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text--secondary:disabled, .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled, .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text--secondary:disabled, .uni-env--light .uni-btn--text--secondary.uni-btn--is-disabled, .uni-env--light .uni-btn--text--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled {
      color: white; }
    .uni-btn--text--secondary:disabled:hover, .uni-btn--text--secondary.uni-btn--is-disabled:hover, .uni-btn--text--secondary.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text--secondary:disabled:hover, [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text--secondary:disabled:hover, [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text--secondary:disabled:hover, .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text--secondary:disabled:hover, .uni-env--light .uni-btn--text--secondary.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text--secondary {
    color: rgba(230, 242, 255, 0.9); }
  [class*='t-le'] .uni-btn--text--secondary {
    color: rgba(19, 41, 63, 0.65); }
  .uni-env--dark .uni-btn--text--secondary {
    color: rgba(230, 242, 255, 0.9); }
  .uni-env--light .uni-btn--text--secondary {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary {
    color: rgba(230, 242, 255, 0.9); }
  [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary {
    color: rgba(19, 41, 63, 0.65); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary {
    color: rgba(230, 242, 255, 0.9); }
  .uni-btn--text--secondary.uni-btn--small, .uni-btn--text--secondary.uni-btn--medium, .uni-btn--text--secondary.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text--secondary:hover, .uni-btn--text--secondary:focus {
    color: rgba(116, 129, 142, 0.7375); }
    [class*='t-de'] .uni-btn--text--secondary:hover, [class*='t-de'] .uni-btn--text--secondary:focus {
      color: #e6f2ff; }
    [class*='t-le'] .uni-btn--text--secondary:hover, [class*='t-le'] .uni-btn--text--secondary:focus {
      color: rgba(116, 129, 142, 0.7375); }
    .uni-env--dark .uni-btn--text--secondary:hover, .uni-env--dark .uni-btn--text--secondary:focus {
      color: #e6f2ff; }
    .uni-env--light .uni-btn--text--secondary:hover, .uni-env--light .uni-btn--text--secondary:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary:focus {
      color: #e6f2ff; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary:focus {
      color: rgba(116, 129, 142, 0.7375); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary:focus {
      color: #e6f2ff; }
  .uni-btn--text--secondary:active {
    color: rgba(20, 38, 56, 0.685); }
    [class*='t-de'] .uni-btn--text--secondary:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--text--secondary:active {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--text--secondary:active {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--text--secondary:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary:active {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary:active {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary:active {
      color: rgba(230, 242, 255, 0.65); }
  .uni-btn--text--secondary.uni-btn--is-spinning {
    color: rgba(20, 38, 56, 0.685); }
    .uni-btn--text--secondary.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 41, 63, 0.65);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text--secondary.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--secondary.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text--secondary.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-env--light .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(20, 38, 56, 0.685); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning {
      color: rgba(230, 242, 255, 0.65); }
    .uni-btn--text--secondary.uni-btn--is-spinning:hover {
      color: rgba(20, 38, 56, 0.685); }
      [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      .uni-env--light .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(20, 38, 56, 0.685); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-spinning:hover {
        color: rgba(230, 242, 255, 0.65); }
  .uni-btn--text--secondary.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--secondary.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text--secondary.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text--secondary.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--secondary.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text--secondary.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--secondary.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--text--danger {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #e81c00;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--text--danger:hover, .uni-btn--text--danger:active, .uni-btn--text--danger:focus, .uni-btn--text--danger:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text--danger:disabled, .uni-btn--text--danger.uni-btn--is-disabled, .uni-btn--text--danger.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text--danger .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text--danger:disabled, .uni-btn--text--danger.uni-btn--is-disabled, .uni-btn--text--danger.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text--danger:disabled, [class*='t-de'] .uni-btn--text--danger.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text--danger.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text--danger:disabled, [class*='t-le'] .uni-btn--text--danger.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text--danger.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text--danger:disabled, .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled, .uni-env--dark .uni-btn--text--danger.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text--danger:disabled, .uni-env--light .uni-btn--text--danger.uni-btn--is-disabled, .uni-env--light .uni-btn--text--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--disabled {
      color: white; }
    .uni-btn--text--danger:disabled:hover, .uni-btn--text--danger.uni-btn--is-disabled:hover, .uni-btn--text--danger.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text--danger:disabled:hover, [class*='t-de'] .uni-btn--text--danger.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text--danger.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text--danger:disabled:hover, [class*='t-le'] .uni-btn--text--danger.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text--danger.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text--danger:disabled:hover, .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text--danger.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text--danger:disabled:hover, .uni-env--light .uni-btn--text--danger.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--danger:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text--danger {
    color: #e81c00; }
  [class*='t-le'] .uni-btn--text--danger {
    color: #e81c00; }
  .uni-env--dark .uni-btn--text--danger {
    color: #e81c00; }
  .uni-env--light .uni-btn--text--danger {
    color: #e81c00; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger {
    color: #e81c00; }
  [class*='uni-env--'] .uni-env--light .uni-btn--text--danger {
    color: #e81c00; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger {
    color: #e81c00; }
  .uni-btn--text--danger.uni-btn--small, .uni-btn--text--danger.uni-btn--medium, .uni-btn--text--danger.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text--danger:hover, .uni-btn--text--danger:focus {
    color: #ff4a33; }
    [class*='t-de'] .uni-btn--text--danger:hover, [class*='t-de'] .uni-btn--text--danger:focus {
      color: #ff4a33; }
    [class*='t-le'] .uni-btn--text--danger:hover, [class*='t-le'] .uni-btn--text--danger:focus {
      color: #ff4a33; }
    .uni-env--dark .uni-btn--text--danger:hover, .uni-env--dark .uni-btn--text--danger:focus {
      color: #ff4a33; }
    .uni-env--light .uni-btn--text--danger:hover, .uni-env--light .uni-btn--text--danger:focus {
      color: #ff4a33; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger:focus {
      color: #ff4a33; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--danger:focus {
      color: #ff4a33; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger:focus {
      color: #ff4a33; }
  .uni-btn--text--danger:active {
    color: #d31c03; }
    [class*='t-de'] .uni-btn--text--danger:active {
      color: #d31c03; }
    [class*='t-le'] .uni-btn--text--danger:active {
      color: #d31c03; }
    .uni-env--dark .uni-btn--text--danger:active {
      color: #d31c03; }
    .uni-env--light .uni-btn--text--danger:active {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger:active {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger:active {
      color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger:active {
      color: #d31c03; }
  .uni-btn--text--danger.uni-btn--is-spinning {
    color: #d31c03; }
    .uni-btn--text--danger.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--danger.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #e81c00;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text--danger.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text--danger.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--danger.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text--danger.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='t-le'] .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-env--light .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning {
      color: #d31c03; }
    .uni-btn--text--danger.uni-btn--is-spinning:hover {
      color: #d31c03; }
      [class*='t-de'] .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='t-le'] .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      .uni-env--light .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-spinning:hover {
        color: #d31c03; }
  .uni-btn--text--danger.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--danger.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text--danger.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text--danger.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--danger.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--danger.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text--danger.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--danger.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--text--confirm {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: #78a100;
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear; }
  .uni-btn--text--confirm:hover, .uni-btn--text--confirm:active, .uni-btn--text--confirm:focus, .uni-btn--text--confirm:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text--confirm:disabled, .uni-btn--text--confirm.uni-btn--is-disabled, .uni-btn--text--confirm.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text--confirm .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text--confirm:disabled, .uni-btn--text--confirm.uni-btn--is-disabled, .uni-btn--text--confirm.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text--confirm:disabled, [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text--confirm.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text--confirm:disabled, [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text--confirm.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text--confirm:disabled, .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled, .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text--confirm:disabled, .uni-env--light .uni-btn--text--confirm.uni-btn--is-disabled, .uni-env--light .uni-btn--text--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled {
      color: white; }
    .uni-btn--text--confirm:disabled:hover, .uni-btn--text--confirm.uni-btn--is-disabled:hover, .uni-btn--text--confirm.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text--confirm:disabled:hover, [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text--confirm:disabled:hover, [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text--confirm:disabled:hover, .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text--confirm:disabled:hover, .uni-env--light .uni-btn--text--confirm.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text--confirm {
    color: #78a100; }
  [class*='t-le'] .uni-btn--text--confirm {
    color: #78a100; }
  .uni-env--dark .uni-btn--text--confirm {
    color: #78a100; }
  .uni-env--light .uni-btn--text--confirm {
    color: #78a100; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm {
    color: #78a100; }
  [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm {
    color: #78a100; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm {
    color: #78a100; }
  .uni-btn--text--confirm.uni-btn--small, .uni-btn--text--confirm.uni-btn--medium, .uni-btn--text--confirm.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text--confirm:hover, .uni-btn--text--confirm:focus {
    color: #9ab940; }
    [class*='t-de'] .uni-btn--text--confirm:hover, [class*='t-de'] .uni-btn--text--confirm:focus {
      color: #9ab940; }
    [class*='t-le'] .uni-btn--text--confirm:hover, [class*='t-le'] .uni-btn--text--confirm:focus {
      color: #9ab940; }
    .uni-env--dark .uni-btn--text--confirm:hover, .uni-env--dark .uni-btn--text--confirm:focus {
      color: #9ab940; }
    .uni-env--light .uni-btn--text--confirm:hover, .uni-env--light .uni-btn--text--confirm:focus {
      color: #9ab940; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm:focus {
      color: #9ab940; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm:focus {
      color: #9ab940; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm:focus {
      color: #9ab940; }
  .uni-btn--text--confirm:active {
    color: #6f9303; }
    [class*='t-de'] .uni-btn--text--confirm:active {
      color: #6f9303; }
    [class*='t-le'] .uni-btn--text--confirm:active {
      color: #6f9303; }
    .uni-env--dark .uni-btn--text--confirm:active {
      color: #6f9303; }
    .uni-env--light .uni-btn--text--confirm:active {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm:active {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm:active {
      color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm:active {
      color: #6f9303; }
  .uni-btn--text--confirm.uni-btn--is-spinning {
    color: #6f9303; }
    .uni-btn--text--confirm.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid #78a100;
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text--confirm.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--confirm.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text--confirm.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-env--light .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning {
      color: #6f9303; }
    .uni-btn--text--confirm.uni-btn--is-spinning:hover {
      color: #6f9303; }
      [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      .uni-env--light .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-spinning:hover {
        color: #6f9303; }
  .uni-btn--text--confirm.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--confirm.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text--confirm.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text--confirm.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--confirm.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text--confirm.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--confirm.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--text--cancel {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  color: rgba(19, 41, 63, 0.4);
  padding-right: 0.75em;
  padding-left: 0.75em;
  transition: color 0.3s linear;
  font-weight: normal; }
  .uni-btn--text--cancel:hover, .uni-btn--text--cancel:active, .uni-btn--text--cancel:focus, .uni-btn--text--cancel:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--text--cancel:disabled, .uni-btn--text--cancel.uni-btn--is-disabled, .uni-btn--text--cancel.uni-btn--disabled {
    cursor: default; }
  .uni-btn--text--cancel .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--text--cancel:disabled, .uni-btn--text--cancel.uni-btn--is-disabled, .uni-btn--text--cancel.uni-btn--disabled {
    color: #232a31;
    opacity: 0.13; }
    [class*='t-de'] .uni-btn--text--cancel:disabled, [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-disabled, [class*='t-de'] .uni-btn--text--cancel.uni-btn--disabled {
      color: white; }
    [class*='t-le'] .uni-btn--text--cancel:disabled, [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-disabled, [class*='t-le'] .uni-btn--text--cancel.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--text--cancel:disabled, .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled, .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled {
      color: white; }
    .uni-env--light .uni-btn--text--cancel:disabled, .uni-env--light .uni-btn--text--cancel.uni-btn--is-disabled, .uni-env--light .uni-btn--text--cancel.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled {
      color: white; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled {
      color: white; }
    .uni-btn--text--cancel:disabled:hover, .uni-btn--text--cancel.uni-btn--is-disabled:hover, .uni-btn--text--cancel.uni-btn--disabled:hover {
      color: #232a31;
      text-decoration: none; }
      [class*='t-de'] .uni-btn--text--cancel:disabled:hover, [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: white; }
      [class*='t-le'] .uni-btn--text--cancel:disabled:hover, [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--text--cancel:disabled:hover, .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: white; }
      .uni-env--light .uni-btn--text--cancel:disabled:hover, .uni-env--light .uni-btn--text--cancel.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: white; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--disabled:hover {
        color: white; }
  [class*='t-de'] .uni-btn--text--cancel {
    color: rgba(230, 242, 255, 0.4); }
  [class*='t-le'] .uni-btn--text--cancel {
    color: rgba(19, 41, 63, 0.4); }
  .uni-env--dark .uni-btn--text--cancel {
    color: rgba(230, 242, 255, 0.4); }
  .uni-env--light .uni-btn--text--cancel {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel {
    color: rgba(230, 242, 255, 0.4); }
  [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel {
    color: rgba(19, 41, 63, 0.4); }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel {
    color: rgba(230, 242, 255, 0.4); }
  .uni-btn--text--cancel.uni-btn--small, .uni-btn--text--cancel.uni-btn--medium, .uni-btn--text--cancel.uni-btn--large {
    padding-right: 0.75em;
    padding-left: 0.75em; }
  .uni-btn--text--cancel:hover, .uni-btn--text--cancel:focus {
    color: rgba(154, 163, 173, 0.55); }
    [class*='t-de'] .uni-btn--text--cancel:hover, [class*='t-de'] .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='t-le'] .uni-btn--text--cancel:hover, [class*='t-le'] .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    .uni-env--dark .uni-btn--text--cancel:hover, .uni-env--dark .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    .uni-env--light .uni-btn--text--cancel:hover, .uni-env--light .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel:hover, [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel:focus {
      color: rgba(154, 163, 173, 0.55); }
  .uni-btn--text--cancel:active {
    color: rgba(21, 36, 51, 0.46); }
    [class*='t-de'] .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='t-le'] .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--dark .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--light .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel:active {
      color: rgba(21, 36, 51, 0.46); }
  .uni-btn--text--cancel.uni-btn--is-spinning {
    color: rgba(21, 36, 51, 0.46); }
    .uni-btn--text--cancel.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid rgba(19, 41, 63, 0.4);
        border-right: 0.225em solid rgba(124, 124, 124, 0.2);
        border-bottom: 0.225em solid rgba(124, 124, 124, 0.2);
        border-left: 0.225em solid rgba(124, 124, 124, 0.2); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--text--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--text--cancel.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--cancel.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--text--cancel.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-env--light .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning {
      color: rgba(21, 36, 51, 0.46); }
    .uni-btn--text--cancel.uni-btn--is-spinning:hover {
      color: rgba(21, 36, 51, 0.46); }
      [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      .uni-env--light .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-spinning:hover {
        color: rgba(21, 36, 51, 0.46); }
  .uni-btn--text--cancel.uni-btn--is-success {
    background-color: transparent !important;
    color: #78a100; }
    .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #78a100;
        border-width: 0 0 0 0; }
      .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container::before {
        width: 0.55em;
        border-width: 0 0.275em 0.275em 0;
        transform: rotate(35deg); }
      .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container:after {
        position: static; }
    .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-success .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-success.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-success.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-success.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--cancel.uni-btn--is-success.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-env--light .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success {
      color: #78a100; }
    .uni-btn--text--cancel.uni-btn--is-success:hover {
      background-color: transparent !important;
      color: #78a100; }
      [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      .uni-env--light .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-success:hover {
        color: #78a100; }
  .uni-btn--text--cancel.uni-btn--is-failure {
    background-color: transparent !important;
    color: #e81c00; }
    .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container {
      position: relative; }
      .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 0;
        content: '';
        border: solid #e81c00;
        border-width: 0 0 0 0; }
      .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::before {
        transform: rotate(45deg);
        border-width: 0 0.275em 0 0; }
      .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        transform: rotate(-45deg);
        border-width: 0 0.275em 0 0; }
    .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-failure .uni-btn__content-container::after {
        left: -1.32rem; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--small .uni-btn__content-container::after {
      left: -0.88rem; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--medium .uni-btn__content-container::after {
      left: -1.32rem; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::before, .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--large .uni-btn__content-container::after {
      left: -1.76rem; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--icon-only .uni-btn__content-container {
      left: 25%; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--icon-only.uni-btn--small .uni-btn__content-container {
      left: 0; }
    .uni-btn--text--cancel.uni-btn--is-failure.uni-btn--icon-only.uni-btn--large .uni-btn__content-container {
      left: 33%; }
    [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-env--light .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure {
      color: #e81c00; }
    .uni-btn--text--cancel.uni-btn--is-failure:hover {
      color: #e81c00;
      background-color: transparent !important; }
      [class*='t-de'] .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='t-le'] .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      .uni-env--light .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] .uni-env--light .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--text--cancel.uni-btn--is-failure:hover {
        color: #e81c00; }

.uni-btn--danger {
  display: inline-block;
  margin: 0;
  overflow: visible;
  border: 0;
  border-radius: 0.245em;
  background-color: transparent;
  vertical-align: middle;
  font-weight: bold;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  -webkit-appearance: none;
  height: 2.5em;
  line-height: 2.5;
  padding-top: 0;
  padding-right: 1.25em;
  padding-bottom: 0;
  padding-left: 1.25em;
  font-size: 1rem;
  font-weight: bold;
  background-color: #e81c00;
  color: white;
  transition: background-color 0.3s linear, color 0.3s linear; }
  .uni-btn--danger:hover, .uni-btn--danger:active, .uni-btn--danger:focus, .uni-btn--danger:visited {
    text-decoration: none;
    outline: 0; }
  .uni-btn--danger:disabled, .uni-btn--danger.uni-btn--is-disabled, .uni-btn--danger.uni-btn--disabled {
    cursor: default; }
  .uni-btn--danger .uni-btn__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: -5px; }
  .uni-btn--danger:disabled, .uni-btn--danger.uni-btn--is-disabled, .uni-btn--danger.uni-btn--disabled {
    background-color: #a5acb4;
    color: #232a31;
    opacity: 0.2; }
    [class*='t-de'] .uni-btn--danger:disabled, [class*='t-de'] .uni-btn--danger.uni-btn--is-disabled, [class*='t-de'] .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-le'] .uni-btn--danger:disabled, [class*='t-le'] .uni-btn--danger.uni-btn--is-disabled, [class*='t-le'] .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--dark .uni-btn--danger:disabled, .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    .uni-env--light .uni-btn--danger:disabled, .uni-env--light .uni-btn--danger.uni-btn--is-disabled, .uni-env--light .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--danger:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] .uni-env--light .uni-btn--danger:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      background-color: #a5acb4; }
    [class*='t-de'] .uni-btn--danger:disabled, [class*='t-de'] .uni-btn--danger.uni-btn--is-disabled, [class*='t-de'] .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='t-le'] .uni-btn--danger:disabled, [class*='t-le'] .uni-btn--danger.uni-btn--is-disabled, [class*='t-le'] .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    .uni-env--dark .uni-btn--danger:disabled, .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    .uni-env--light .uni-btn--danger:disabled, .uni-env--light .uni-btn--danger.uni-btn--is-disabled, .uni-env--light .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--danger:disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] .uni-env--light .uni-btn--danger:disabled, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled {
      color: #232a31; }
    .uni-btn--danger:disabled svg, .uni-btn--danger.uni-btn--is-disabled svg, .uni-btn--danger.uni-btn--disabled svg {
      fill: currentColor; }
    .uni-btn--danger:disabled:hover, .uni-btn--danger.uni-btn--is-disabled:hover, .uni-btn--danger.uni-btn--disabled:hover {
      background-color: #a5acb4;
      color: #232a31;
      opacity: 0.2; }
      [class*='t-de'] .uni-btn--danger:disabled:hover, [class*='t-de'] .uni-btn--danger.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--danger:disabled:hover, [class*='t-le'] .uni-btn--danger.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--danger:disabled:hover, .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--danger:disabled:hover, .uni-env--light .uni-btn--danger.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--danger:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--danger:disabled:hover, [class*='t-de'] .uni-btn--danger.uni-btn--is-disabled:hover, [class*='t-de'] .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='t-le'] .uni-btn--danger:disabled:hover, [class*='t-le'] .uni-btn--danger.uni-btn--is-disabled:hover, [class*='t-le'] .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--dark .uni-btn--danger:disabled:hover, .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      .uni-env--light .uni-btn--danger:disabled:hover, .uni-env--light .uni-btn--danger.uni-btn--is-disabled:hover, .uni-env--light .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] .uni-env--light .uni-btn--danger:disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:hover {
        color: #232a31; }
    .uni-btn--danger:disabled:focus, .uni-btn--danger.uni-btn--is-disabled:focus, .uni-btn--danger.uni-btn--disabled:focus {
      background-color: #a5acb4; }
      [class*='t-de'] .uni-btn--danger:disabled:focus, [class*='t-de'] .uni-btn--danger.uni-btn--is-disabled:focus, [class*='t-de'] .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='t-le'] .uni-btn--danger:disabled:focus, [class*='t-le'] .uni-btn--danger.uni-btn--is-disabled:focus, [class*='t-le'] .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--dark .uni-btn--danger:disabled:focus, .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:focus, .uni-env--dark .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      .uni-env--light .uni-btn--danger:disabled:focus, .uni-env--light .uni-btn--danger.uni-btn--is-disabled:focus, .uni-env--light .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] .uni-env--light .uni-btn--danger:disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-disabled:focus, [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-disabled:focus, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--disabled:focus {
        background-color: #a5acb4; }
  [class*='t-de'] .uni-btn--danger {
    background-color: #e81c00; }
  [class*='t-le'] .uni-btn--danger {
    background-color: #e81c00; }
  .uni-env--dark .uni-btn--danger {
    background-color: #e81c00; }
  .uni-env--light .uni-btn--danger {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--danger {
    background-color: #e81c00; }
  [class*='uni-env--'] .uni-env--light .uni-btn--danger {
    background-color: #e81c00; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger {
    background-color: #e81c00; }
  [class*='t-de'] .uni-btn--danger {
    color: white; }
  [class*='t-le'] .uni-btn--danger {
    color: white; }
  .uni-env--dark .uni-btn--danger {
    color: white; }
  .uni-env--light .uni-btn--danger {
    color: white; }
  [class*='uni-env--'] .uni-env--dark .uni-btn--danger {
    color: white; }
  [class*='uni-env--'] .uni-env--light .uni-btn--danger {
    color: white; }
  [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger {
    color: white; }
  .uni-btn--danger:hover, .uni-btn--danger:focus {
    background-color: #ff4a33; }
    [class*='t-de'] .uni-btn--danger:hover, [class*='t-de'] .uni-btn--danger:focus {
      background-color: #ff4a33; }
    [class*='t-le'] .uni-btn--danger:hover, [class*='t-le'] .uni-btn--danger:focus {
      background-color: #ff4a33; }
    .uni-env--dark .uni-btn--danger:hover, .uni-env--dark .uni-btn--danger:focus {
      background-color: #ff4a33; }
    .uni-env--light .uni-btn--danger:hover, .uni-env--light .uni-btn--danger:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--danger:hover, [class*='uni-env--'] .uni-env--dark .uni-btn--danger:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] .uni-env--light .uni-btn--danger:hover, [class*='uni-env--'] .uni-env--light .uni-btn--danger:focus {
      background-color: #ff4a33; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:hover, [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:focus {
      background-color: #ff4a33; }
  .uni-btn--danger:active {
    background-color: #d31c03; }
    [class*='t-de'] .uni-btn--danger:active {
      background-color: #d31c03; }
    [class*='t-le'] .uni-btn--danger:active {
      background-color: #d31c03; }
    .uni-env--dark .uni-btn--danger:active {
      background-color: #d31c03; }
    .uni-env--light .uni-btn--danger:active {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--danger:active {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--danger:active {
      background-color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger:active {
      background-color: #d31c03; }
  .uni-btn--danger.uni-btn--is-spinning {
    background-color: #d31c03; }
    .uni-btn--danger.uni-btn--is-spinning .uni-btn__content-container {
      position: relative; }
      .uni-btn--danger.uni-btn--is-spinning .uni-btn__content-container::before {
        position: absolute;
        content: '';
        top: 50%;
        display: inline-block;
        clear: both;
        margin: 0 auto;
        border-radius: 100%;
        animation: spinner-rotation 0.7s infinite linear;
        border-top: 0.225em solid white;
        border-right: 0.225em solid rgba(255, 255, 255, 0.3);
        border-bottom: 0.225em solid rgba(255, 255, 255, 0.3);
        border-left: 0.225em solid rgba(255, 255, 255, 0.3); }

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
        .no-cssanimations .uni-btn--danger.uni-btn--is-spinning .uni-btn__content-container::before {
          display: none; }
    .uni-btn--danger.uni-btn--is-spinning .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--danger.uni-btn--is-spinning .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--danger.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container {
      margin-left: 1.14286em; }
      .uni-btn--danger.uni-btn--is-spinning.uni-btn--small .uni-btn__content-container::before {
        width: 1rem;
        height: 1rem;
        left: -1rem;
        margin-top: -0.5rem;
        margin-left: -0.25rem; }
    .uni-btn--danger.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container {
      margin-left: 1.438em; }
      .uni-btn--danger.uni-btn--is-spinning.uni-btn--medium .uni-btn__content-container::before {
        width: 1.5rem;
        height: 1.5rem;
        left: -1.5rem;
        margin-top: -0.75rem;
        margin-left: -0.375rem; }
    .uni-btn--danger.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container {
      margin-left: 1.445em; }
      .uni-btn--danger.uni-btn--is-spinning.uni-btn--large .uni-btn__content-container::before {
        width: 2rem;
        height: 2rem;
        left: -2rem;
        margin-top: -1rem;
        margin-left: -0.5rem; }
    .uni-btn--danger.uni-btn--is-spinning.uni-btn--icon-only .uni-btn__content-container {
      left: 29%; }
    [class*='t-de'] .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='t-le'] .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-env--dark .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-env--light .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-spinning {
      background-color: #d31c03; }
    .uni-btn--danger.uni-btn--is-spinning:hover {
      background-color: #d31c03; }
      [class*='t-de'] .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='t-le'] .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      .uni-env--dark .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      .uni-env--light .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] .uni-env--light .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
      [class*='uni-env--'] [class^='uni-env--'] .uni-env--dark .uni-btn--danger.uni-btn--is-spinning:hover {
        background-color: #d31c03; }
  .uni-btn--danger > .uni-btn__content-container {
    transition: margin 0.2s linear; }

.uni-text--xsmall {
  font-size: 0.75rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h1 {
  font-weight: bold;
  font-size: 3rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h2 {
  font-weight: bold;
  font-size: 2.5rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h3 {
  font-weight: bold;
  font-size: 2rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h4 {
  font-weight: bold;
  font-size: 1.5rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h5 {
  font-weight: bold;
  font-size: 1rem;
  font-family: helvetica, arial, sans-serif; }

.uni-display--h6 {
  font-weight: bold;
  font-size: 0.75rem;
  font-family: helvetica, arial, sans-serif; }

.uni-spacer--xxsmall {
  margin: 0.25rem; }

.uni-spacer--xsmall {
  margin: 0.25rem; }

.uni-spacer--small {
  margin: 0.75rem; }

.uni-spacer--medium {
  margin: 1rem; }

.uni-spacer--large {
  margin: 1.5rem; }

.uni-spacer--xlarge {
  margin: 2rem; }

.uni-spacer--xxlarge {
  margin: 3rem; }

.uni-spacer--xxsmall--vert {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.uni-spacer--xsmall--vert {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.uni-spacer--small--vert {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.uni-spacer--medium--vert {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.uni-spacer--large--vert {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.uni-spacer--xlarge--vert {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.uni-spacer--xxlarge--vert {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.uni-spacer--xxsmall--btm {
  margin-bottom: 0.25rem; }

.uni-spacer--xsmall--btm {
  margin-bottom: 0.25rem; }

.uni-spacer--small--btm {
  margin-bottom: 0.75rem; }

.uni-spacer--medium--btm {
  margin-bottom: 1rem; }

.uni-spacer--large--btm {
  margin-bottom: 1.5rem; }

.uni-spacer--xlarge--btm {
  margin-bottom: 2rem; }

.uni-spacer--xxlarge--btm {
  margin-bottom: 3rem; }

.uni-spacer--xxsmall--top {
  margin-top: 0.25rem; }

.uni-spacer--xsmall--top {
  margin-top: 0.25rem; }

.uni-spacer--small--top {
  margin-top: 0.75rem; }

.uni-spacer--medium--top {
  margin-top: 1rem; }

.uni-spacer--large--top {
  margin-top: 1.5rem; }

.uni-spacer--xlarge--top {
  margin-top: 2rem; }

.uni-spacer--xxlarge--top {
  margin-top: 3rem; }

.uni-spacer--xxsmall--horiz {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }

.uni-spacer--xsmall--horiz {
  margin-right: 0.25rem;
  margin-left: 0.25rem; }

.uni-spacer--small--horiz {
  margin-right: 0.75rem;
  margin-left: 0.75rem; }

.uni-spacer--medium--horiz {
  margin-right: 1rem;
  margin-left: 1rem; }

.uni-spacer--large--horiz {
  margin-right: 1.5rem;
  margin-left: 1.5rem; }

.uni-spacer--xlarge--horiz {
  margin-right: 2rem;
  margin-left: 2rem; }

.uni-spacer--xxlarge--horiz {
  margin-right: 3rem;
  margin-left: 3rem; }

.uni-spacer--xxsmall--right {
  margin-right: 0.25rem; }

.uni-spacer--xsmall--right {
  margin-right: 0.25rem; }

.uni-spacer--small--right {
  margin-right: 0.75rem; }

.uni-spacer--medium--right {
  margin-right: 1rem; }

.uni-spacer--large--right {
  margin-right: 1.5rem; }

.uni-spacer--xlarge--right {
  margin-right: 2rem; }

.uni-spacer--xxlarge--right {
  margin-right: 3rem; }

.uni-spacer--xxsmall--left {
  margin-left: 0.25rem; }

.uni-spacer--xsmall--left {
  margin-left: 0.25rem; }

.uni-spacer--small--left {
  margin-left: 0.75rem; }

.uni-spacer--medium--left {
  margin-left: 1rem; }

.uni-spacer--large--left {
  margin-left: 1.5rem; }

.uni-spacer--xlarge--left {
  margin-left: 2rem; }

.uni-spacer--xxlarge--left {
  margin-left: 3rem; }

.uni-pad--xxsmall {
  padding: 0.25rem; }

.uni-pad--xsmall {
  padding: 0.25rem; }

.uni-pad--small {
  padding: 0.75rem; }

.uni-pad--medium {
  padding: 1rem; }

.uni-pad--large {
  padding: 1.5rem; }

.uni-pad--xlarge {
  padding: 2rem; }

.uni-pad--xxlarge {
  padding: 3rem; }

.uni-pad--xxsmall--vert {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.uni-pad--xsmall--vert {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.uni-pad--small--vert {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.uni-pad--medium--vert {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.uni-pad--large--vert {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.uni-pad--xlarge--vert {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.uni-pad--xxlarge--vert {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.uni-pad--xxsmall--btm {
  padding-bottom: 0.25rem; }

.uni-pad--xsmall--btm {
  padding-bottom: 0.25rem; }

.uni-pad--small--btm {
  padding-bottom: 0.75rem; }

.uni-pad--medium--btm {
  padding-bottom: 1rem; }

.uni-pad--large--btm {
  padding-bottom: 1.5rem; }

.uni-pad--xlarge--btm {
  padding-bottom: 2rem; }

.uni-pad--xxlarge--btm {
  padding-bottom: 3rem; }

.uni-pad--xxsmall--top {
  padding-top: 0.25rem; }

.uni-pad--xsmall--top {
  padding-top: 0.25rem; }

.uni-pad--small--top {
  padding-top: 0.75rem; }

.uni-pad--medium--top {
  padding-top: 1rem; }

.uni-pad--large--top {
  padding-top: 1.5rem; }

.uni-pad--xlarge--top {
  padding-top: 2rem; }

.uni-pad--xxlarge--top {
  padding-top: 3rem; }

.uni-pad--xxsmall--horiz {
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

.uni-pad--xsmall--horiz {
  padding-right: 0.25rem;
  padding-left: 0.25rem; }

.uni-pad--small--horiz {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.uni-pad--medium--horiz {
  padding-right: 1rem;
  padding-left: 1rem; }

.uni-pad--large--horiz {
  padding-right: 1.5rem;
  padding-left: 1.5rem; }

.uni-pad--xlarge--horiz {
  padding-right: 2rem;
  padding-left: 2rem; }

.uni-pad--xxlarge--horiz {
  padding-right: 3rem;
  padding-left: 3rem; }

.uni-pad--xxsmall--right {
  padding-right: 0.25rem; }

.uni-pad--xsmall--right {
  padding-right: 0.25rem; }

.uni-pad--small--right {
  padding-right: 0.75rem; }

.uni-pad--medium--right {
  padding-right: 1rem; }

.uni-pad--large--right {
  padding-right: 1.5rem; }

.uni-pad--xlarge--right {
  padding-right: 2rem; }

.uni-pad--xxlarge--right {
  padding-right: 3rem; }

.uni-pad--xxsmall--left {
  padding-left: 0.25rem; }

.uni-pad--xsmall--left {
  padding-left: 0.25rem; }

.uni-pad--small--left {
  padding-left: 0.75rem; }

.uni-pad--medium--left {
  padding-left: 1rem; }

.uni-pad--large--left {
  padding-left: 1.5rem; }

.uni-pad--xlarge--left {
  padding-left: 2rem; }

.uni-pad--xxlarge--left {
  padding-left: 3rem; }
