// --------------------------------------------
// --------------------------------------------
//
// Function :: Color
//
// --------------------------------------------
// --------------------------------------------


// Shade
// --------------------------------------------
// Source: Bourbon
// https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/functions/_shade.scss

// Utilized: btn mixin
@function shade($color, $percent) {
  @return mix(#000, $color, $percent);
}



// Contrast
// --------------------------------------------
// The background color is taken as an argument
// And the color is set based on that

// TO DO: Need better return colors
@function contrast-color($color) {
  @if (lightness($color) > 55) {
    // $return-color: darken($color, 80%);
    // @return #000000; // Lighter backgorund, return dark color
    // $return-color: $btn-contrast-default;
    $return-color: black;
    @return #{$return-color};
  } @else {
    // $return-color: lighten($color, 95%);
    // @return #ffffff; // Darker background, return light color
    $return-color: $btn-contrast;
    @return #{$return-color};
  }
}

// Used in Button mixin when the button is disabled
@function disabled-contrast($color) {
  @if (lightness($color) > 55) {
    $return-color: darken($color, 20%);
    @return #{$return-color};
  } @else {
    $return-color: saturate(lighten($color, 30%), 75%);
    // $return-color: shade(lighten($color, 35%), 25%);
    @return #{$return-color};
  }
}



// Used in when spinners on on a background-color
@function contrast-spinner($color) {
  @if (lightness($color) > 55) {
    $tweaked-color: darken($color, 80%);
    $return-color: rgba($tweaked-color, 0.3);
    // @return #000000; // Lighter backgorund, return dark color
    @return #{$return-color};
  } @else {
    $tweaked-color: lighten($color, 70%);
    $return-color: rgba($tweaked-color, 0.3);
    // @return #ffffff; // Darker background, return light color
    @return #{$return-color};
  }
}
