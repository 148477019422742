// --------------------------------------------
// --------------------------------------------
//
// Elements :: Backgrounds
//
// --------------------------------------------
// --------------------------------------------




// New Level Classes
// --------------------------------------------
// The level classes automatically choose the correct background color based on the parent environment class


// Level 0
.#{$css-prefix}bg--level0 {
  @include theme-bg($color-le-bg-level0, $color-de-bg-level0, false);
}

.#{$css-prefix}bg--level0--accent {
  @include theme-bg($color-le-bg-level0-accent, $color-de-bg-level0-accent, false);
}


// Level 1
.#{$css-prefix}bg--level1 {
  @include theme-bg($color-le-bg-level1, $color-de-bg-level1, false);
}

.#{$css-prefix}bg--level1--accent {
  @include theme-bg($color-le-bg-level1-accent, $color-de-bg-level1-accent, false);
}


// Level 2
.#{$css-prefix}bg--level2 {
  @include theme-bg($color-le-bg-level2, $color-de-bg-level2, false);
}

.#{$css-prefix}bg--level2--accent {
  @include theme-bg($color-le-bg-level2-accent, $color-de-bg-level2-accent, false);
}

// Level 3
.#{$css-prefix}bg--level3 {
  @include theme-bg($color-le-bg-level3, $color-de-bg-level3, false);
}

.#{$css-prefix}bg--level3--accent {
  @include theme-bg(color-le-bg-level3-accent, $color-de-bg-level3-accent, false);
}
