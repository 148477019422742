// --------------------------------------------
// --------------------------------------------
//
// Variables :: Spinners
//
// --------------------------------------------
// --------------------------------------------



// $spinner-size:            2rem;
$spinner-size:            2em;
// $spinner_thickness:       ($spinner-size * 0.2);
$spinner-timing:          0.7s;

$spinner-color-default:  $color-brand-orange;

// $spiner_color_main:   rgba(46, 197, 217, .15);
// $spinner_color_accent: rgba(46, 197, 217, .8);

// $spinner_bdr:           $spinner-thickness solid $spiner_color_main;
// $spinner_bdr_accent:   $spinner-thickness solid $spinner-color--accent;
