// --------------------------------------------
// --------------------------------------------
//
// Mixins :: Backgrounder
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */

// This is used on elements where you want a slight gradient between the background color and a darker shade.
@mixin backgrounder( $color ) {
  $dark: darken($color, 20%);
  background-color: $color;
  background-image: linear-gradient( $color 35%, rgba($dark, 0.1));
}

/* stylelint-enable */