// --------------------------------------------
// --------------------------------------------
//
// Mixins :: Media Query
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */


// FOR: Write less verbose Media Queries with variables
// or using straight figures (eg 30rem)
// WHY: Faster to write. More flexible. Lets you write
// media query variables on the fly.

// USE: @include mq(30rem, 40rem) { }
// for a hard-coded min and max width query

// USE: @include mq($bp-small, $bp-large) { }
// for a variable based min and max width query

// USE: @include mq(max, $bp-medium) { }
// for a variable based max-width query

// USE: @include mq(bp-large) { }
// for a variable based min-width query


@mixin mq($bp-1, $bp-2: null) {
  @if $bp-1 == "max" {
    @media (max-width: $bp-2 - $bp-onepix) { @content; }
  }
  @elseif $bp-2 == "max" {
    @media (max-width: $bp-1 - $bp-onepix) { @content; }
  }
  @elseif $bp-1 == "min" {
    @media (min-width: $bp-2) { @content; }
    @warn 'You do not need "min" in your min-width media queries. Use @include mq($my-breakpoint), rather than @include(min, $my-breakpoint)';
  }
  @elseif $bp-2 == "min" {
    @media (min-width: $bp-1) { @content; }
    @warn 'You do not need "min" in your min-width media queries. Use @include mq($my-breakpoint), rather than @include($my-breakpoint, min)';
  }
  @elseif $bp-2 == null {
    //single breakpoint
    @media (min-width: $bp-1) { @content; }
  }
  @else {
    //bracketed breakpoint
    @media (min-width: $bp-1) and (max-width: $bp-2 - $bp-onepix) { @content; }
  }
}

/* stylelint-enable */