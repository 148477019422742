// --------------------------------------------
// --------------------------------------------
//
// Variables :: Color
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */


// Brand
// --------------------------------------------
$color-brand-orange:                          rgba(255, 99, 0, 1.0);
$color-brand-electric:                        rgba(0, 156, 227, 1.0);
$color-brand-ink:                             rgba(35, 42, 49, 1.0);
$color-brand-evening:                         rgba(56, 67, 79, 1.0);
$color-brand-slate:                           rgba(78, 93, 108, 1.0);

// Identity
// --------------------------------------------
$color-identity-maroon:                       rgba(121, 6, 34, 1.0);
$color-identity-scarlet:                      rgba(208, 0, 0, 1.0);
$color-identity-apple:                        rgba(255, 30, 30, 1.0);
$color-identity-orange:                       rgba(255, 140, 0, 1.0);
$color-identity-gold:                         rgba(237, 180, 7, 1.0);
$color-identity-yellow:                       rgba(255, 221, 0, 1.0);
$color-identity-forest:                       rgba(36, 89, 0, 1.0);
$color-identity-kelly:                        rgba(62, 150, 0, 1.0);
$color-identity-sky:                          rgba(83, 188, 239, 1.0);
$color-identity-royal:                        rgba(8, 77, 163, 1.0);
$color-identity-navy:                         rgba(12, 57, 112, 1.0);
$color-identity-purple:                       rgba(98, 18, 150, 1.0);
$color-identity-white:                        rgba(250, 250, 250, 1.0);
$color-identity-silver:                       rgba(215, 217, 221, 1.0);
$color-identity-grey:                         rgba(100, 101, 102, 1.0);
$color-identity-black:                        rgba(34, 34, 34, 1.0);
$color-identity-default:                      rgba(56, 67, 79, 1.0);

// Tagging
// --------------------------------------------
$color-tagging-white:                         rgba(252, 251, 252, 1.0);
$color-tagging-white-shade:                   rgba(197, 197, 197, 1.0);
$color-tagging-grey:                          rgba(114, 116, 118, 1.0);
$color-tagging-grey-shade:                    rgba(89, 90, 92, 1.0);
$color-tagging-black:                         rgba(60, 60, 60, 1.0);
$color-tagging-black-shade:                   rgba(41, 41, 41, 1.0);
$color-tagging-yellow:                        rgba(255, 221, 0, 1.0);
$color-tagging-yellow-shade:                  rgba(227, 192, 0, 1.0);
$color-tagging-orange:                        rgba(255, 144, 0, 1.0);
$color-tagging-orange-shade:                  rgba(219, 123, 0, 1.0);
$color-tagging-red:                           rgba(201, 26, 26, 1.0);
$color-tagging-red-shade:                     rgba(160, 20, 20, 1.0);
$color-tagging-green:                         rgba(85, 167, 52, 1.0);
$color-tagging-green-shade:                   rgba(59, 131, 29, 1.0);
$color-tagging-blue:                          rgba(45, 131, 201, 1.0);
$color-tagging-blue-shade:                    rgba(36, 104, 160, 1.0);
$color-tagging-purple:                        rgba(98, 18, 150, 1.0);
$color-tagging-purple-shade:                  rgba(72, 11, 111, 1.0);

// Base
// --------------------------------------------
$color-base-white:                            rgba(255, 255, 255, 1.0);
$color-base-black:                            rgba(0, 0, 0, 1.0);

// Background
// --------------------------------------------
$color-le-bg-level0:                          rgba(255, 255, 255, 1.0);
$color-le-bg-level0-accent:                   rgba(237, 240, 242, 1.0);
$color-le-bg-level1:                          rgba(249, 250, 251, 1.0);
$color-le-bg-level1-accent:                   rgba(228, 231, 235, 1.0);
$color-le-bg-level2:                          rgba(242, 245, 247, 1.0);
$color-le-bg-level2-accent:                   rgba(220, 225, 230, 1.0);
$color-le-bg-level3:                          rgba(235, 239, 242, 1.0);
$color-le-bg-level3-accent:                   rgba(211, 216, 222, 1.0);
$color-de-bg-level0:                          rgba(16, 20, 23, 1.0);
$color-de-bg-level0-accent:                   rgba(35, 42, 49, 1.0);
$color-de-bg-level1:                          rgba(25, 31, 36, 1.0);
$color-de-bg-level1-accent:                   rgba(44, 53, 62, 1.0);
$color-de-bg-level2:                          rgba(35, 42, 49, 1.0);
$color-de-bg-level2-accent:                   rgba(49, 59, 69, 1.0);
$color-de-bg-level3:                          rgba(53, 63, 74, 1.0);
$color-de-bg-level3-accent:                   rgba(69, 82, 94, 1.0);

// Text
// --------------------------------------------
$color-le-text-contrast:                      rgba(19, 41, 63, 1.0);
$color-le-text-default:                       rgba(19, 41, 63, 0.80);
$color-le-text-subtle:                        rgba(19, 41, 63, 0.65);
$color-le-text-nonessential:                  rgba(19, 41, 63, 0.4);
$color-de-text-contrast:                      rgba(230, 242, 255, 1.0);
$color-de-text-default:                       rgba(230, 242, 255, 0.80);
$color-de-text-subtle:                        rgba(230, 242, 255, 0.65);
$color-de-text-nonessential:                  rgba(230, 242, 255, 0.40);

// Icon
// --------------------------------------------
$color-le-icon-contrast:                      rgba(19, 41, 63, 1.0);
$color-le-icon-default:                       rgba(19, 41, 63, 0.80);
$color-le-icon-subtle:                        rgba(19, 41, 63, 0.65);
$color-le-icon-nonessential:                  rgba(19, 41, 63, 0.4);
$color-de-icon-contrast:                      rgba(230, 242, 255, 1.0);
$color-de-icon-default:                       rgba(230, 242, 255, 0.80);
$color-de-icon-subtle:                        rgba(230, 242, 255, 0.65);
$color-de-icon-nonessential:                  rgba(230, 242, 255, 0.40);

// Divider
// --------------------------------------------
$color-le-divider:                            rgba(165, 172, 180, 0.60);
$color-de-divider:                            rgba(104, 117, 129, 0.60);

// Utility
// --------------------------------------------
$color-utility-action:                        rgba(0, 156, 227, 1.0);
$color-utility-action-tint:                   rgba(77, 185, 235, 1.0);
$color-utility-action-shade:                  rgba(0, 124, 181, 1.0);
$color-utility-success:                       rgba(120, 161, 0, 1.0);
$color-utility-success-tint:                  rgba(169, 201, 77, 1.0);
$color-utility-success-shade:                 rgba(92, 124, 0, 1.0);
$color-utility-warning:                       rgba(242, 182, 0, 1.0);
$color-utility-warning-tint:                  rgba(237, 197, 77, 1.0);
$color-utility-warning-shade:                 rgba(166, 124, 4, 1.0);
$color-utility-critical:                      rgba(232, 28, 0, 1.0);
$color-utility-critical-tint:                 rgba(255, 75, 51, 1.0);
$color-utility-critical-shade:                rgba(185, 24, 4, 1.0);

// Utility-Accent
// --------------------------------------------
$color-le-utility-accent-action:              rgba(0, 156, 227, 1.0);
$color-le-utility-accent-success:             rgba(120, 161, 0, 1.0);
$color-le-utility-accent-warning:             rgba(242, 182, 0, 1.0);
$color-le-utility-accent-critical:            rgba(232, 28, 0, 1.0);
$color-de-utility-accent-action:              rgba(0, 156, 227, 1.0);
$color-de-utility-accent-success:             rgba(120, 161, 0, 1.0);
$color-de-utility-accent-warning:             rgba(242, 182, 0, 1.0);
$color-de-utility-accent-critical:            rgba(232, 28, 0, 1.0);





// --------------------------------------------
// THE BIG DEPRECATION !!!!!!!
// --------------------------------------------
// Deprecated v0.11.0
// These variables are all deprecated as part of the massive shift in naming convention
// that occurred with the v0.11.0 release. They will be removed in the next Major release, the mythical v1 of uniCSS.

// Brand
// --------------------------------------------
$uni-orange:                                  rgba(255, 101, 0, 1.0);
$uni-electric:                                rgba(0, 156, 227, 1.0);
$uni-ink:                                     rgba(35, 42, 49, 1.0);
$uni-evening-tint:                            rgba(80, 89, 100, 1.0);
$uni-evening:                                 rgba(56, 67, 79, 1.0);
$uni-evening-shade:                           rgba(44, 52, 62, 1.0);
$uni-slate-tint:                              rgba(99, 112, 125, 1.0);
$uni-slate:                                   rgba(78, 93, 108, 1.0);
$uni-slate-shade:                             rgba(59, 70, 82, 1.0);


// Utility
// --------------------------------------------
$electric-tint:                               rgba(77, 185, 235, 1.0);
$electric:                                    rgba(0, 156, 227, 1.0);
$electric-shade:                              rgba(0, 124, 181, 1.0);
$success-tint:                                rgba(169, 201, 77, 1.0);
$success:                                     rgba(132, 178, 0, 1.0);
$success-shade:                               rgba(92, 124, 0, 1.0);
$warning-tint:                                rgba(237, 197, 77, 1.0);
$warning:                                     rgba(230, 172, 0, 1.0);
$warning-shade:                               rgba(145, 108, 0, 1.0);
$critical-tint:                               rgba(255, 82, 61, 1.0);
$critical:                                    rgba(232, 30, 5, 1.0);
$critical-shade:                              rgba(185, 24, 4, 1.0);


// Light-Environment
// --------------------------------------------
$le-bg-white:                                 rgba(255, 255, 255, 1.0);
$le-bg-white-shade:                           rgba(237, 239, 240, 1.0);
$le-bg-clean-slate-tint:                      rgba(255, 255, 255, 1.0);
$le-bg-clean-slate:                           rgba(249, 249, 250, 1.0);
$le-bg-clean-slate-shade:                     rgba(228, 230, 233, 1.0);
$le-bg-bone-slate-tint:                       rgba(251, 252, 252, 1.0);
$le-bg-bone-slate:                            rgba(239, 240, 242, 1.0);
$le-bg-bone-slate-shade:                      rgba(219, 222, 225, 1.0);
$le-type-ink:                                 rgba(35, 42, 49, 1.0);
$le-type-typewriter:                          rgba(78, 93, 108, 1.0);
$le-type-light-type:                          rgba(104, 117, 129, 1.0);
$le-type-nonessential:                        rgba(165, 172, 180, 1.0);
$le-divider:                                  rgba(175, 181, 188, 1.0);
$le-border:                                   rgba(175, 181, 188, 1.0);


// Dark-Environment
// --------------------------------------------
$de-bg-pitch-black-tint:                      rgba(38, 46, 53, 1.0);
$de-bg-pitch-black:                           rgba(17, 20, 24, 1.0);
$de-bg-pitch-black-shade:                     rgba(0, 0, 0, 1.0);
$de-bg-midnight-tint:                         rgba(46, 55, 64, 1.0);
$de-bg-midnight:                              rgba(25, 31, 36, 1.0);
$de-bg-midnight-shade:                        rgba(17, 20, 24, 1.0);
$de-bg-ink-tint:                              rgba(54, 64, 75, 1.0);
$de-bg-ink:                                   rgba(35, 42, 49, 1.0);
$de-bg-ink-shade:                             rgba(25, 31, 36, 1.0);
$de-type-white:                               rgba(255, 255, 255, 1.0);
$de-type-bright-type:                         rgba(197, 202, 207, 1.0);
$de-type-subtle-type:                         rgba(139, 149, 159, 1.0);
$de-type-nonessential-dark:                   rgba(104, 117, 129, 1.0);
$de-divider:                                  rgba(74, 88, 102, 1.0);
$de-border:                                   rgba(74, 88, 102, 1.0);


// Identity Colors
// --------------------------------------------
$identity-maroon:             $color-identity-maroon;
$identity-scarlet:            $color-identity-scarlet;
$identity-apple:              $color-identity-apple;
$identity-orange:             $color-identity-orange;
$identity-gold:               $color-identity-gold;
$identity-yellow:             $color-identity-yellow;
$identity-forest:             $color-identity-forest;
$identity-kelly:              $color-identity-kelly;
$identity-sky:                $color-identity-sky;
$identity-royal:              $color-identity-royal;
$identity-navy:               $color-identity-navy;
$identity-purple:             $color-identity-purple;
$identity-white:              $color-identity-white;
$identity-silver:             $color-identity-silver;
$identity-grey:               $color-identity-grey;
$identity-black:              $color-identity-black;
$identity-default:            $color-identity-default;


// Tagging Colors
// --------------------------------------------
$tagging-white:               $color-tagging-white;
$tagging-white-shade:         $color-tagging-white-shade;
$tagging-grey:                $color-tagging-grey;
$tagging-grey-shade:          $color-tagging-grey-shade;
$tagging-black:               $color-tagging-black;
$tagging-black-shade:         $color-tagging-black-shade;
$tagging-yellow:              $color-tagging-yellow;
$tagging-yellow-shade:        $color-tagging-yellow-shade;
$tagging-orange:              $color-tagging-orange;
$tagging-orange-shade:        $color-tagging-orange-shade;
$tagging-red:                 $color-tagging-red;
$tagging-red-shade:           $color-tagging-red-shade;
$tagging-green:               $color-tagging-green;
$tagging-green-shade:         $color-tagging-green-shade;
$tagging-blue:                $color-tagging-blue;
$tagging-blue-shade:          $color-tagging-blue-shade;
$tagging-purple:              $color-tagging-purple;
$tagging-purple-shade:        $color-tagging-purple-shade;


// --------------------------------------------
// END -------- THE BIG DEPRECATION !!!!!!!
// --------------------------------------------



/* stylelint-enable */
