// --------------------------------------------
// --------------------------------------------
//
// Elements :: Micro-Layout
//
// --------------------------------------------
// --------------------------------------------

$bp-ml-med: 43.75rem;

.l-grid--5,
%l-grid--5 {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small, $bp-ml-med) { @include micro-layout(2); }
  @include mq($bp-ml-med, $bp-medium) { @include micro-layout(3); }
  @include mq($bp-medium) { @include micro-layout(5); }
}

.l-grid--3,
%l-grid--3 {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small, $bp-ml-med) { @include micro-layout(2); }
  @include mq($bp-ml-med) { @include micro-layout(3); }
}

.l-grid--2,
%l-grid--2 {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small) { @include micro-layout(2); }
}

.l-grid--2--space-lg,
%l-grid--2--space-lg {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small) { @include micro-layout(2, $space-three); }
}






// Forms Grids
// --------------------------------------------

// Remove normal margin from form items and let the grid handle it.
[class*='l-form-grid'] {
  > .#{$css-prefix}form__item { margin-top: 0; }
}

.l-form-grid--4 {
  @include mq($bp-small, max) { @include micro-layout(1, $space-two); }
  @include mq($bp-small, $bp-large) { @include micro-layout(2, $space-two); }
  @include mq($bp-large) { @include micro-layout(4, $space-two); }
}

.l-form-grid--3 {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small, $bp-ml-med) { @include micro-layout(2, $space-two); }
  @include mq($bp-ml-med) { @include micro-layout(3, $space-two); }
  // > .l-grid__item { margin-top: 0; }
}

.l-form-grid--2 {
  @include mq($bp-small, max) { @include micro-layout(1); }
  @include mq($bp-small) { @include micro-layout(2, $space-two); }
  // > .l-grid__item { margin-top: 0; }
}





// Pairs
// --------------------------------------------


// Parent
.l-pair {
  @include clearfix;
  display: flex;
  align-items: flex-start;
}

.l-pair--centered { align-items: center; }

.l-pair--reversed { flex-direction: row-reverse; }




// Figure
.l-pair__figure {
  margin-right: $space-half;

  .l-pair--reversed & {
    margin-right: 0;
    margin-left: $space-one;
  }

  .l-pair--score & {
    margin-right: 0;
    text-align: right;

    .no-flexbox & { float: right; }
  }

  .no-flexbox & { float: left; }
}




// Body
.l-pair__body {

  // Margin for nested pairs
  > .l-pair { margin-top: $space-one; }

  // For content lists with score
  .l-pair--score & {
    margin-right: auto;
  }

  .no-flexbox & { float: left; }
}
