// --------------------------------------------
// --------------------------------------------
//
// Mixin :: Clearfix
//
// --------------------------------------------
// --------------------------------------------



// Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
/* stylelint-disable */
@mixin clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
/* stylelint-enable */
