// --------------------------------------------
// --------------------------------------------
//
// Mixins :: Theme
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */

// Theme Switcher
// --------------------------------------------
// Used in individual theme mixins to consolidate theme environment switching
@mixin theme-switcher($light, $dark, $property) {

  // Legacy theming
  // Referencing light or dark theme parents to set color, accordingly
  [class*='t-de'] & { #{$property}: $dark; }
  [class*='t-le'] & { #{$property}: $light; }


  // New Environment switches from color refactor in v0.11.0
  // Current official support 2 nested environments (light allows 3)
  #{$env-dark} & { #{$property}: $dark; }
  #{$env-light} & { #{$property}: $light; }

  [class*='uni-env--'] #{$env-dark} & { #{$property}: $dark; }
  [class*='uni-env--'] #{$env-light} & { #{$property}: $light; }

  [class*='uni-env--'] [class^='uni-env--'] #{$env-dark} & { #{$property}: $dark; }

}





// Theme Color
// --------------------------------------------
// Used for switching between color when switching between light and dark environments

@mixin theme-color(
  $light: $color-le-text-default,
  $dark: null,
  $auto-switch: true ) {

  // Automatically set dark environment type colors based on its corresponding light environment colors
  @if $auto-switch == true {
    @if $light == $color-le-text-default { $dark: $color-de-text-default; }
    @else if $light == $color-le-text-contrast { $dark: $color-de-text-contrast; }
    @else if $light == $color-le-text-subtle { $dark: $color-de-text-subtle; }
    @else if $light == $color-le-text-nonessential { $dark: $color-de-text-nonessential; }
  }

  // Ensures normal, light environment color is equal to $light argument
  color: $light;

  // Check to ensure a users has not enabled custom color switching but forget to add a $dark environment companion color
  @if $auto-switch == false and $dark == null {
    @warn 'Please add a value for $dark parameter to ensure proper theme switching';
  }

  // Using theme switcher mixin
  @include theme-switcher($light, $dark, color);

  // Referencing light or dark theme parents to set color, accordingly
  // [class*='t-de'] & { color: $dark; }
  // [class*='t-le'] & { color: $light; }
}




// Theme Background
// --------------------------------------------
// Used for switching background colors between light and dark environments
@mixin theme-bg(
  $light: null,
  $dark: null,
  $auto-switch: true) {

  @if $auto-switch == true { }

  // Reminds users to add a $dark argument when auto-switch is set to true
  @if $auto-switch == true and $dark == null {
    @warn 'Currently, theme-bg does not have predetermined values outlined to enable automatic switching between environments. Please define a value for $dark';
  }

  background-color: $light;

  @if $auto-switch == false and $dark == null {
    @warn 'Please add a value for $dark parameter to ensure proper theme switching';
  }

  // Referencing light or dark theme parents to set color, accordingly
  // [class*='t-de'] & { background-color: $dark; }
  // [class*='t-le'] & { background-color: $light; }

  @include theme-switcher($light, $dark, background-color);

}



// Theme Border
// --------------------------------------------
// Used for switching border color when switching between light and dark environments

// Because le-border is deprecated, both check currently default to le-divider. This may or may not change depending on how theming with Levels is solidified
@mixin theme-border(
  $light: $color-le-divider,
  $dark: null,
  $auto-switch: true ) {

  // Automatically set dark environment border colors based on its corresponding light environment colors
  @if $auto-switch == true {
    @if $light == $color-le-divider { $dark: $color-de-divider; }
    @else if $light == $color-le-divider { $dark: $color-de-divider; }
  }

  // Ensures normal, light environment color is equal to $light argument
  border-color: $light;

  @if $auto-switch == false and $dark == null {
    @warn 'Please add a value for $dark parameter to ensure proper theme switching';
  }

  // Referencing light or dark theme parents to set color, accordingly
  // [class*='t-de'] & { border-color: $dark; }
  // [class*='t-le'] & { border-color: $light; }

  @include theme-switcher($light, $dark, border-color);

}




// Theme SVG
// --------------------------------------------
// Used for svg background images
@mixin theme-svg($light, $dark) {

  background-image: #{$light};

  // Legacy theming
  // Referencing light or dark theme parents to set color, accordingly
  [class*='t-de'] & { background-image: #{$dark}; }
  [class*='t-le'] & { background-image: #{$light}; }


  // New Environment switches from color refactor in v0.11.0
  // Current official support 2 nested environments (light allows 3)
  #{$env-dark} & { background-image: #{$dark}; }
  #{$env-light} & { background-image: #{$light};; }

  [class*='uni-env--'] #{$env-dark} & { background-image: #{$dark}; }
  [class*='uni-env--'] #{$env-light} & { background-image: #{$light};; }

  [class*='uni-env--'] [class^='uni-env--'] #{$env-dark} & { background-image: #{$dark}; }

}

/* stylelint-enable */
