// --------------------------------------------
// --------------------------------------------
//
// Reset :: Viewport
//
// --------------------------------------------
// --------------------------------------------



// This is a future friendly method to specify the width browsers should
// render our page. We've traditionally used a <meta> tag in the head of our
//  html to establish that width. One caveat, the following CSS is a
//  developing standard and support will vary. For the time being we should
//  continue to keep our trusty meta tag in the head of our html.

// <meta name="viewport" content="width=device-width; initial-scale=1">



@-webkit-viewport{width:device-width}
@-moz-viewport{width:device-width}
@-ms-viewport{width:device-width}
@-o-viewport{width:device-width}
@viewport{width:device-width}
