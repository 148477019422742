// --------------------------------------------
// --------------------------------------------
//
// Reset :: Box Sizing
//
// --------------------------------------------
// --------------------------------------------



// This version of box sizing differs from the age old method you're probably
// more accustomed to seeing:  *{ box-sizing: border-box; }.

// Essentially what you gain applying box sizing as written below is the
// opportunity to override components within your design with a different
// box-sizing behavior should you need it.

// http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/



html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
