// --------------------------------------------
// --------------------------------------------
//
// Variables :: Avatar
//
// --------------------------------------------
// --------------------------------------------

$avatar-small:    2rem;
$avatar-medium:   3rem;
$avatar-large:    4.5rem;


$avatar-bg-blank:   $color-le-bg-level1-accent;
$avatar-bg-text:    $color-de-bg-level2-accent;

