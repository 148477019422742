// --------------------------------------------
// --------------------------------------------
//
// Variables :: Z-index
//
// --------------------------------------------
// --------------------------------------------



$zindex-1:      100;
$zindex-2:      200;
$zindex-3:      300;
$zindex-4:      400;
$zindex-5:      500;
$zindex-6:      600;
$zindex-7:      700;
$zindex-8:      800;
$zindex-9:      900;



// Applying z-index
// --------------------------------------------
$zindex-modal:    $zindex-2;
$zindex-overlay:  $zindex-1;
