// --------------------------------------------
// --------------------------------------------
//
// Variables :: Grid
//
// --------------------------------------------
// --------------------------------------------



// Layout Variables
// --------------------------------------------
// Using our base-16 rem sizing.

$grid-width:            100%;
$grid-gutterwidth:      1.25rem; // 20px

// This should match up with our analytics.
// At the moment (2016) our most reasonable desktop max-width
// to target, based on traffic is 1366px (1366x768).
$grid-maxwidth:         85.375rem; // 1366px

// Grid column variables
$grid-col--onewhole:          calc((#{$grid-width} / 1));             // ~100%
$grid-col--onehalf:           calc((#{$grid-width} / 2));             // ~50%
$grid-col--onequarter:        calc((#{$grid-width} / 4));             // ~25%
$grid-col--threequarters:     calc(((#{$grid-width} / 4) * 3));       // ~75%

$grid-col--onethird:          calc((#{$grid-width} / 3));             // ~33%
$grid-col--twothirds:         calc(((#{$grid-width} / 3) * 2));       // ~66%
