// --------------------------------------------
// --------------------------------------------
//
// Element :: Modal
//
// --------------------------------------------
// --------------------------------------------


// Config
// --------------------------------------------
$modal-pad-large:      1rem;
$modal-pad-medium:     0.75rem;
$modal-pad-msmall:      0.55rem;

$modal-pad-side:    $modal-pad-large;

$modal-color-dark:  $color-le-text-contrast;
$modal-color-medium:   $color-le-text-default;

$modal-color-text:  $color-le-text-default;

$modal-color-overlay:   $color-de-bg-level2;






// Styles
// --------------------------------------------
.#{$css-prefix}modal__overlay {
  position: absolute;
  z-index: $zindex-overlay;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  visibility: hidden;
  background: $modal-color-overlay;
  opacity: 0;
  text-align: center;
  transition: visibility $trans-timing-medium linear, opacity $trans-timing-medium cubic-bezier(0.4, 0, 0.2, 1);

  // Modal is visible
  .#{$css-prefix}modal--is-visible & {
    visibility: visible;
    opacity: 0.98;
  }
}

.#{$css-prefix}modal__container {
  position: fixed;
  z-index: $zindex-overlay;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  visibility: hidden;
  background-color: transparent;
  text-align: center;
  opacity: 0;
  transition: opacity $trans-timing-fast ease;

  // overlay
  .#{$css-prefix}modal--is-visible & {
    overflow: scroll;
    visibility: visible;
    opacity: 1;
  }
}


.#{$css-prefix}modal {
  position: relative;
  z-index: $zindex-modal;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 1px;
  background-color: $color-base-white;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
  text-align: left;
  transform: translateY(50%);
  opacity: 1;
  transition: all $trans-timing-medium ease;

  // This class is added to the <body>
  .#{$css-prefix}modal--is-visible & { transform: translateY(0); }
}


.#{$css-prefix}modal__title-bar {
  @include backgrounder($color-de-bg-level2-accent);
  position: relative;
  padding: $modal-pad-medium $modal-pad-side;
}


.#{$css-prefix}modal__title {
  margin: 0;
  font-size: $font-size-base;
  // color: $ui-white;
  color: rgba($color-base-white, 0.9);
  text-transform: uppercase;
}


.#{$css-prefix}modal__meta {
  padding: $modal-pad-large $modal-pad-side 0;
}


.#{$css-prefix}modal__content {
  padding: 0 $modal-pad-side;
}


.#{$css-prefix}modal__action {
  @include clearfix;
  padding: $modal-pad-medium $modal-pad-side;
  background-color: $color-le-bg-level1;
}


.#{$css-prefix}modal__h {
  margin: 0;
  color: $modal-color-dark;
  font-size: 2rem;
}


.#{$css-prefix}modal__h--sub {
  margin: 0;
  color: $modal-color-text;
  font-size: 1rem;
}


.#{$css-prefix}modal__text {
  margin: 1rem 0;
  color: $modal-color-text;
  font-size: 1rem;
}
