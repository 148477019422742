// --------------------------------------------
// --------------------------------------------
//
// Mixins :: Micro-Layout
//
// --------------------------------------------
// --------------------------------------------

/* stylelint-disable */

@mixin micro-layout($num: 2, $margin: $ml-margin, $target: $ml-target) {
  @include clearfix;

  overflow: hidden;
  $margin_total: ($margin * ($num - 1) );
  $nth: #{$num}n;
  $nth-plus: $num + 1;

  > #{$target} {
    float: left;
    width: calc( (100% - #{$margin_total}) / #{$num} );
    margin-right: $margin;
    margin-bottom: $margin;
    // transition: $ml-trans;

    // removes margin-right from the last item in each row
    &:nth-of-type(#{$nth} ) { margin-right: 0; }

    // clears float for first item in a row
    &:nth-of-type(#{$nth} + #{$nth-plus}) { clear: left; }

    // set a top margin for items after the first row
    /* &:nth-of-type(1n + #{$nth-plus}) { margin-top: $margin; } */

    // remove bottom margin from the last x number of items
    &:nth-last-child(-1n + #{$num}) { margin-bottom: 0; }
  }

} //media-grid

/* stylelint-enable */
