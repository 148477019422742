// --------------------------------------------
// --------------------------------------------
//
// Mixin :: Progress Bars
//
// --------------------------------------------
// --------------------------------------------

@mixin progress-container {
  @include clearfix;
  overflow: hidden;
  width: 100%;
  border: 1px solid darken($color-le-bg-level1, 3%);
  border-radius: 0.25rem;
  background-color: $color-le-bg-level1;
  box-shadow: inset 0 0.05rem 0.05rem rgba(0, 0, 0, 0.1);
}


@mixin progress-meter {
  display: block;
  float: left;
  height: 100%;
  background-color: $color-brand-orange;
  transition: $trans-width;
}
