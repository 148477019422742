// --------------------------------------------
// --------------------------------------------
//
// Variables :: Icons
//
// --------------------------------------------
// --------------------------------------------



// $icon-color-default:     $le-type-medium;
$icon-color-default:     currentColor;
$icon-color-reverse:     $color-base-white;


$icon-size-small:           1rem;
$icon-size-medium:          1.5rem;
$icon-size-large:           2rem;
